import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import authHelpers from '../utils/authHelpers';

export class UnauthRoute extends React.Component {
  render() {
    let Component = this.props.component;
    let rest = { ...this.props, component: undefined };

    return (
      <Route
        {...rest}
        render={props =>
          !authHelpers.isLoggedIn(rest.auth) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

export default withRouter(
  compose(
    connect(({ firebase: { auth } }) => ({
      auth
    }))
  )(UnauthRoute)
);
