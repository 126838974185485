import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import ReelList from './ReelList';
import { showToast } from '../../actions/ToastActions';

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, type) => {
      dispatch(showToast(message, type));
    }
  };
};

export default compose(
  firestoreConnect(),
  connect(
    null,
    mapDispatchToProps
  )
)(ReelList);
