const productFinder = {
  //These are the four attributes of the circuit that determine a SKU
  getProduct: (catalog, metal, insulation, size, color) => {
    const products =
      catalog &&
      catalog.products.items.filter(product => {
        return (
          product.metal === metal &&
          product.product_type === insulation &&
          product.size === size &&
          product.color === color
        );
      });

    if (products.length) {
      return products[0];
    }
    return undefined;
  },

  getMVProduct: (catalog, metal, size, voltage, thickness, jacket) => {
    const products =
      catalog &&
      catalog.products.items.filter(product => {
        return (
          product.metal === metal &&
          product.size === size &&
          product.voltage === voltage &&
          product.thickness === thickness &&
          product.jacket === jacket
        );
      });

    if (products.length) {
      return products[0];
    }
    return undefined;
  },

  //Per *wire* (conductor), not circuit, uses field from catalog api * length
  getConductorWeight: (product, lengthInFt) => {
    var lbs = undefined;
    if (product && product.lbs_per_thousand_feet && lengthInFt) {
      lbs = (product.lbs_per_thousand_feet * lengthInFt) / 1000;
    }
    return lbs;
  },

  //wire is a cylinder, so volum is pi*r*r*length...
  //equations from Southwire note that using d*d
  //instead of pi*r*r is "good enough" and what they use in other places
  getConductorVolume: (product, lengthInFt) => {
    var volume = undefined;
    if (product && product.diameter && lengthInFt) {
      let diameterInInches = product.diameter / 1000;
      volume = 12 * lengthInFt * diameterInInches * diameterInInches;
    }
    return volume;
  },

  normalizeReelProduct: (reelProduct, simId) => ({
    id: reelProduct.id || simId,
    name: reelProduct.name || simId,
    diameter: reelProduct.diameter || reelProduct.flange_in,
    reel_weight: reelProduct.reel_weight || reelProduct.weight_lb,
    total_weight_capacity: reelProduct.weight_capacity || reelProduct.max_lb,
    width: reelProduct.width || reelProduct.outside_in
  })
};

export default productFinder;
