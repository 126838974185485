import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';

import { showToast } from '../../actions/ToastActions';

export class DownloadBOM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false
    };
  }
  render() {
    const processingClass = this.state.processing ? 'processing' : '';

    return (
      <span
        className={`download-bom ${processingClass}`}
        onClick={this.downloadBOM}
      >
        Download BOM
      </span>
    );
  }

  downloadBOM = () => {
    const { job, jobId } = this.props;
    this.setState({ processing: true });

    const query = `jobId=${jobId}`;
    const DEFAULT_API = `https://us-central1-southwire-configurator-dev.cloudfunctions.net/generateMVBOM?${query}`;
    const rewriteUrl = `/generateBOM?${query}`;

    const url =
      window.location.hostname === 'localhost' ? DEFAULT_API : rewriteUrl;

    fetch(url, { mode: 'cors' })
      .then(response => {
        if (!response.ok) {
          throw new Error('/generateBOM fetch has failed');
        }

        // convert response to blob for FileSaver
        return response.blob();
      })
      .then(blob => {
        saveAs(blob, `${job.name}_BOM.pdf`);
        this.setState({ processing: false });
      })
      .catch(error => {
        this.props.showToast('Unable to generate BOM.', 'error');
        this.setState({ processing: false });
      });
  };
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    }
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(DownloadBOM);
