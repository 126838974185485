const authHelpers = {
  /**
   * A user is considered logged in when:
   *
   * - we've been provided an auth object
   * - it has been loaded
   * - we have a uid
   * - their email has been verified
   *
   * @param  {Object} auth
   * @return {Boolean}
   */
  isLoggedIn: auth => {
    return !!auth && auth.isLoaded && !!auth.uid && auth.emailVerified;
  }
};

export default authHelpers;
