import React from 'react';
import ConductorSwatch from './ConductorSwatch';

// The maximum difference in size between a conductor the primary before
// we display a warning indicating that we don't recommend this setup.
const MAX_SIZE_WARNING_DIFF = 6;

const Notch = ({ isSelected }) => (
  <div
    className="notch"
    style={{ visibility: isSelected ? 'visible' : 'hidden' }}
  />
);

/**
 * Return an array that contains the index positions of the conductors
 * that should display the size range warning.
 *
 * @param  {Array} conductors
 * @param  {Array} availableSizes
 * @return {Array}
 */
export const getConductorsWithSizeWarning = (conductors, availableSizes) => {
  const primarySize = conductors[0].size;
  if (!primarySize) {
    return [];
  }

  const primaryIndex = availableSizes.findIndex(s => s.id === primarySize);
  if (primaryIndex === -1) {
    return [];
  }

  return conductors.reduce((accumulator, conductor, index) => {
    // Ignore the first conductor, and any conductors that don't have a size.
    if (index === 0 || !conductor.size) {
      return accumulator;
    }

    const currentIndex = availableSizes.findIndex(s => s.id === conductor.size);

    // Is the difference between the current conductor and the primary conductor more
    // than our maximum allowed difference?
    if (Math.abs(currentIndex - primaryIndex) > MAX_SIZE_WARNING_DIFF) {
      accumulator.push(index);
    }

    return accumulator;
  }, []);
};

const SelectedConductors = props => {
  const { onChange, conductors, selectedIndex, availableSizes } = props;

  const conductorsWithWarning = getConductorsWithSizeWarning(
    conductors,
    availableSizes
  );

  return (
    <div className="conductor-builder__selected">
      <p className="label">Conductors</p>

      {conductorsWithWarning.length > 0 && (
        <p className="size-warning">
          We don't recommend sizes that vary this greatly.
        </p>
      )}

      <ul>
        {conductors.map((conductor, index) => (
          <li key={index}>
            <ConductorSwatch
              size={conductor.size}
              color={conductor.color}
              id={`selected-conductor-${index}`}
              isSelected={index === selectedIndex}
              onChange={() => onChange(index)}
              showSizeWarning={conductorsWithWarning.indexOf(index) !== -1}
            />
            <Notch isSelected={index === selectedIndex} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedConductors;
