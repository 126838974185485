import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../patterns/Dropdown';
import Toggle from '../../patterns/Toggle';

const SizePicker = props => {
  const {
    applyToAll,
    conductor,
    selectedIndex,
    availableSizes,
    onChange,
    onChangeApplyToAll,
    applyToAllDisabled = false
  } = props;

  const label = applyToAll
    ? 'Conductors Size'
    : `Conductor ${selectedIndex + 1} Size`;

  const options = availableSizes.map(size => ({
    value: size.id,
    label: size.name
  }));

  return (
    <div className="size-picker">
      <Dropdown
        required
        label={label}
        options={options}
        id="conductor-size"
        onSelect={onChange}
        value={conductor.size}
        placeholder="Select Size"
      />
      <Toggle
        name="Apply to All"
        id="apply-to-all"
        checked={applyToAll}
        disabled={applyToAllDisabled}
        onChange={onChangeApplyToAll}
      />
      {applyToAllDisabled && (
        <p className="size-warning">
          Current circuit length requires all conductors to be the same size.
        </p>
      )}
    </div>
  );
};

SizePicker.propTypes = {
  conductor: PropTypes.object,
  applyToAll: PropTypes.bool,
  applyToAllDisabled: PropTypes.bool,
  selectedIndex: PropTypes.number,
  availableSizes: PropTypes.array,
  onChange: PropTypes.func,
  onChangeApplyToAll: PropTypes.func
};

export default SizePicker;
