const reelColors = [
  '#384d9a',
  '#800080',
  '#9389dd',
  '#b3b42e',
  '#358337',
  '#972c2e',
  '#c57720',
  '#ffd641',
  '#ff6540',
  '#1a1818'
];

export default reelColors;
