import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Button,
  Menu,
  MenuItem,
  closeMenu
} from 'react-aria-menubutton';
import NestedMenuItem from './NestedMenuItem';

const MAX_DEPTH = 1;

/**
 *  Example of options array:
 *  [
 *    {
 *      value: '01',
 *      label: 'Test Option 01',
 *      options: [ ... ] <--- Optional
 *    },
 *    { ... }
 *  ]
 */
const optionsShape = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};
optionsShape.options = PropTypes.arrayOf(PropTypes.shape(optionsShape));

/**
 * The main dropdown component. This will render a styled dropdown that supports
 * both normal options as well as nested options one level deep.
 *
 * Has full keyboard accessibility support.
 */
export default class Dropdown extends Component {
  static propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    placeholder: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(PropTypes.shape(optionsShape)).isRequired
  };

  static defaultProps = {
    required: false,
    disabled: false,
    className: ''
  };

  /**
   * Determine if the given option has defined any sub options.
   *
   * @param  {Object} option
   * @return {Boolean}
   */
  hasSubOptions = option => option.options && option.options.length > 0;

  /**
   * Callback for when an option has been selected.
   *
   * @param {String} value
   * @param {Object} event
   */
  onSelect = (value, event) => {
    event.stopPropagation();

    this.props.onSelect(value, event);

    // Make sure the parent wrapper dropdown closes.
    closeMenu(this.props.id, { focusButton: true });
  };

  /**
   * Render the menu option.
   *
   * If nested options have been defined, recursively
   * build out those nested dropdown/menu options.
   *
   * @param  {Object} option
   * @param  {Number} index
   * @param  {Number} depth - how deep are we going with our menu options?
   * @return {JSX}
   */
  renderMenuOption = (option, index, depth) => {
    const selected = this.props.value === option.value ? 'selected' : '';

    if (this.hasSubOptions(option) && depth <= MAX_DEPTH) {
      return (
        <NestedMenuItem
          tag="li"
          key={index}
          value={option.value}
          className={`dropdown__menu-item nested ${selected}`}
        >
          <Wrapper
            id={option.value}
            onSelection={this.onSelect}
            className="form-field dropdown nested"
          >
            <Button tabIndex="-1" className="dropdown__button nested">
              {option.label}
            </Button>
            <Menu className="dropdown__menu nested">
              <ul>
                {option.options.map((option, index) =>
                  this.renderMenuOption(option, index, depth++)
                )}
              </ul>
            </Menu>
          </Wrapper>
        </NestedMenuItem>
      );
    }

    return (
      <MenuItem
        tag="li"
        key={index}
        value={option.value}
        className={`dropdown__menu-item ${selected}`}
      >
        {option.label}
      </MenuItem>
    );
  };

  /**
   * Find the selected option to display in the dropdown.
   * Handles nested options as well.
   *
   * @param  {Array} options
   * @return {Object}
   */
  getSelectedOption = options => {
    const { value } = this.props;

    if (!value) {
      return null;
    }

    // small function to recursively flatten out
    // all available options, including anything nested.
    const getOptions = opt => {
      if (this.hasSubOptions(opt)) {
        return [opt, ...opt.options.map(getOptions)];
      }
      return opt;
    };

    // filter down that list of available options
    // to find the one that has been selected.
    return options
      .reduce((acc, option) => acc.concat(getOptions(option)), [])
      .find(option => option.value === value);
  };

  render() {
    const {
      label,
      placeholder,
      options,
      id,
      disabled,
      required,
      className
    } = this.props;
    const selected = this.getSelectedOption(options);

    return (
      <Wrapper
        id={id}
        onSelection={this.onSelect}
        className={`form-field dropdown ${className}`}
      >
        {label && <label className={required ? 'required' : ''}>{label}</label>}
        <Button
          disabled={disabled}
          className={'dropdown__button' + (disabled ? ' disabled' : '')}
        >
          {selected ? selected.label : placeholder}
        </Button>
        <div className="dropdown__menu-wrapper">
          <Menu className="dropdown__menu">
            <ul>
              {options.map((option, index) =>
                this.renderMenuOption(option, index, 1)
              )}
            </ul>
          </Menu>
        </div>
      </Wrapper>
    );
  }
}
