import React from 'react';

const EmptyFeederSummary = () => (
  <div className="empty-container">
    <img src="/assets/img/no-feeder.svg" width="715" height="348" alt=" " />
    <p className="important-message">
      You haven’t configured a feeder schedule for this job yet.
    </p>
  </div>
);

export default EmptyFeederSummary;
