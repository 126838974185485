import React from 'react';
import reelColors from '../utils/reelColors';

const DIAMETER = 100;
const RADIUS = DIAMETER / 2;
const INNER_RADIUS = 7.5;
const RADIUS_AMOUNT = RADIUS - INNER_RADIUS;

const getCircle = (currentNum, numCircuits, maxPercent, circuit, colorKey) => {
  const extraRadius = (maxPercent * RADIUS_AMOUNT * currentNum) / numCircuits;
  return (
    <circle
      key={'reel-circle-' + currentNum}
      cx={RADIUS}
      cy={RADIUS}
      r={INNER_RADIUS + extraRadius}
      fill={reelColors[colorKey % reelColors.length]}
      stroke="white"
      strokeWidth="0.5"
    />
  );
};

class ReelVisual extends React.Component {
  render() {
    return (
      <svg className="reel-bullseye" viewBox={`0 0 ${DIAMETER} ${DIAMETER}`}>
        <g stroke="none">
          <circle cx={RADIUS} cy={RADIUS} r={RADIUS} fill="#e6e7e8" />
          {this.renderCircuits()}
          <circle cx={RADIUS} cy={RADIUS} r={INNER_RADIUS} fill="white" />
        </g>
      </svg>
    );
  }

  renderCircuits() {
    const reel = this.props.activeReel;
    if (reel && reel.circuits && reel.circuits.length) {
      const circuitWeight = reel.circuitWeight;
      const reelProduct = reel.reelProduct;
      const reelCapacity = reelProduct && reelProduct.total_weight_capacity;

      if (circuitWeight && reelCapacity) {
        const percent = circuitWeight / reelCapacity;
        const numCircuits = reel.circuits.length;

        let circles = reel.circuits.map((info, i) => {
          let circuit = this.props.circuits[info.id];

          return getCircle(i + 1, numCircuits, percent, circuit, info.colorKey);
        });

        return circles.reverse();
      }
    }

    return null;
  }
}

export default ReelVisual;
