import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';
import FeederSummaryItem from './FeederSummaryItem';
import JobSummary from './JobSummary';
import IconButton from '../patterns/IconButton';
import Checkbox from '../patterns/Checkbox';
import withNotFoundRedirect from '../common/withNotFoundRedirect';
import { showDeleteCircuitModal } from '../actions/ModalActions';
import { showEditCircuitsModal } from '../actions/ModalActions';
import ExpandCollapse from '../patterns/ExpandCollapse';
import Table from '../patterns/Table';
import ReelAutoBuilder from '../utils/ReelAutoBuilder';
import Button from '../patterns/Button';

import infoIcon from '../assets/information-alt.svg';

const AutoBuildReelsNotification = ({ circuits, reels, onClickUndo }) => {
  const circuitsText =
    circuits.length + (circuits.length > 1 ? ' Circuits' : ' Circuit');
  const reelsText = reels.length + (reels.length > 1 ? ' Reels' : ' Reel');

  return (
    <div className="auto-build-notification">
      <img alt="information-icon" src={infoIcon} />
      <span>
        You auto-built {circuitsText} onto {reelsText}.
      </span>
      <Button onClick={onClickUndo}>UNDO</Button>
    </div>
  );
};

export class FeederSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCircuits: [],
      lastDuplicatedCircuits: [],
      tableCheckboxIsSelected: false,
      expandedCircuits: [],
      autoBuild: {
        showNotification: false,
        circuits: [],
        reels: []
      }
    };
  }

  isEmpty = obj => Object.keys(obj).length < 1;

  toggleTableCheckbox = event => {
    const { job } = this.props;

    let selectedCircuits = Object.keys(job.circuits);
    if (!event.target.checked) {
      selectedCircuits = [];
    }

    this.setState({
      tableCheckboxIsSelected: !this.state.tableCheckboxIsSelected,
      selectedCircuits
    });
  };

  /**
   * When you select a circuit row using multi-select.
   */
  selectCircuit = (e, circuitId) => {
    const selectedCircuits = this.state.selectedCircuits;
    if (e.target.checked) {
      this.setState({
        selectedCircuits: [...selectedCircuits, circuitId],
        tableCheckboxIsSelected: true
      });
      return;
    }

    const updatedCircuits = selectedCircuits.filter(cId => cId !== circuitId);

    this.setState({
      selectedCircuits: updatedCircuits,
      ...(updatedCircuits.length === 0 && { tableCheckboxIsSelected: false })
    });
  };

  /**
   * When you expand one of the circuit rows to see additional info
   *
   * @param {Number}  circuitId
   * @param {Boolean} expanded
   */
  toggleExpandCircuit = (circuitId, expanded) => {
    const expandedCircuits = [...this.state.expandedCircuits];

    if (expanded) {
      expandedCircuits.push(circuitId);
    } else {
      expandedCircuits.splice(expandedCircuits.indexOf(circuitId), 1);
    }

    this.setState({ expandedCircuits });
  };

  /**
   * When you toggle the expand/collapse button in the table header.
   */
  toggleExpandAll = () => {
    const { job } = this.props;
    let expandedCircuits = Object.keys(job.circuits);

    if (this.state.expandedCircuits.length) {
      expandedCircuits = [];
    }

    this.setState({ expandedCircuits });
  };

  deleteCircuits = () => {
    const selectedCircuits = this.state.selectedCircuits;
    const { projectId, jobId } = this.props.match.params;

    this.props.showDeleteCircuitModal({
      projectId: projectId,
      jobId: jobId,
      circuitIds: selectedCircuits,
      onSuccess: () => {
        this.setState({
          selectedCircuits: [],
          tableCheckboxIsSelected: false,
          autoBuild: {
            showNotification: false,
            circuits: [],
            reels: []
          }
        });
      }
    });
  };

  /**
   * Assign the selected circuits to reels automatically.
   */
  autoBuild = () => {
    const { selectedCircuits } = this.state;
    const { jobId } = this.props.match.params;
    const { firestore, job } = this.props;

    // get a list of circuit IDs that are already on a reel
    const circuitsOnReels = job.reels.reduce((acc, reel) => {
      const ids = (reel.circuits && reel.circuits.map(c => c.id)) || [];
      return [...acc, ...ids];
    }, []);

    // filter out those circuits from the selectedCircuits list
    const circuits = selectedCircuits.filter(
      id => circuitsOnReels.indexOf(id) === -1
    );

    // If all selected circuits are already on a reel, don't auto-build.
    // Including this additional check just in case the user ends up here somehow.
    if (circuits.length === 0) {
      return;
    }

    // Separate the main circuits from the ground
    // circuits and build reels for them separately.
    const mainCircuits = circuits.filter(
      id => job.circuits[id].isGroundCircuit !== true
    );

    const groundCircuits = circuits.filter(
      id => job.circuits[id].isGroundCircuit === true
    );

    // an array of objects of grounds separated by unique size, insulation, and metal values
    const groupedGrounds = [
      ...groundCircuits
        .reduce((acc, circuitId) => {
          const circuit = job.circuits[circuitId];
          const key =
            circuit.size + '-' + circuit.metal + '-' + circuit.insulation;

          const value = acc.get(key);

          if (value) {
            acc.set(key, [...value, circuitId]);
          } else {
            acc.set(key, [circuitId]);
          }

          return acc;
        }, new Map())
        .values()
    ];

    const mainCircuitReels =
      this.getAutoBuiltReels(mainCircuits) &&
      this.getAutoBuiltReels(mainCircuits).reels;

    let groundReelCount = 0;
    let groundCircuitReels = [];

    // for each ground group, increase groundReelCount and push reels created to groundCircuitReels
    for (let i = 0; i < groupedGrounds.length; i++) {
      const groundGroup = groupedGrounds[i];
      groundReelCount++;

      const result = this.getAutoBuiltReels(groundGroup, true, groundReelCount);

      // if result is invalid return false to trigger error toast and break loop
      if (!result) {
        groundCircuitReels = false;
        break;
      }

      // set groundReelCount to current reelCount returned from result
      groundReelCount = result.reelCount;

      groundCircuitReels.push(...result.reels);
    }

    if (mainCircuitReels === false || groundCircuitReels === false) {
      this.props.showToast(
        "Your reels couldn't be auto-assigned due to your circuit configuration or job restrictions.",
        'error'
      );
      return;
    }

    const reels = [...job.reels, ...mainCircuitReels, ...groundCircuitReels];

    firestore
      .update(`jobs/${jobId}`, { reels })
      .then(() => {
        const autoBuild = {
          showNotification: true,
          circuits,
          reels: [
            ...mainCircuitReels.map(reel => reel.dateCreated),
            ...groundCircuitReels.map(reel => reel.dateCreated)
          ]
        };

        this.setState({ autoBuild }, () => {
          // Scrolling up so the user can see the auto-build notification
          const header = document.querySelector('.section-toggle');
          if (header) {
            header.scrollIntoView({ behavior: 'smooth' });
          }
        });
      })
      .catch(() => {
        this.props.showToast(
          "Your reels couldn't be auto-assigned at this time. Please try again later. ",
          'error'
        );
      });
  };

  /**
   * Instantiate an instance of ReelAutoBuilder and return
   * the generated reels.
   *
   * @param  {Array}   circuits - array of circuit IDs
   * @param  {Boolean} isGround - are we working with ground circuits?
   * @return {Array}
   */
  getAutoBuiltReels(circuits, isGround = false, count = 1) {
    const { job, catalog, simreels } = this.props;

    if (!circuits.length) {
      return { reels: [] };
    }

    const autoBuilder = new ReelAutoBuilder(
      circuits,
      job,
      {
        ...catalog,
        simreels
      },
      isGround,
      count
    );

    return autoBuilder.run();
  }

  /**
   * Event handler for when the user clicks on "Undo" in the auto-build notification.
   * This should remove any auto-build reels from our job and persist that back to firebase.
   */
  onClickAutoBuildUndo = () => {
    const { job, firestore } = this.props;
    const { jobId } = this.props.match.params;
    const { autoBuild } = this.state;

    // Filter down our reels so that we're left with reels that DO NOT
    // exist in the autoBuild.reels array (this is based on dateCreated).
    const reels = job.reels.filter(reel => {
      return autoBuild.reels.indexOf(reel.dateCreated) === -1;
    });

    firestore
      .update(`jobs/${jobId}`, { reels })
      .then(() => {
        this.setState({
          autoBuild: {
            showNotification: false,
            circuits: [],
            reels: []
          }
        });
      })
      .catch(() => {
        this.props.showToast(
          'Your auto-assigned reels could not be undone at this time. Please try again later.',
          'error'
        );
      });
  };

  // duplicated circuits need props including 'dateCreated' and 'dateModified' updated
  updateCircuitProps(acc, circuitId, index) {
    const selectedCircuits = this.state.selectedCircuits;
    const circuits = this.props.job.circuits;
    const now = Date.now();
    const dateCreated = now + index;
    const id = parseInt(circuitId, 10);
    const circuit = Object.assign({}, circuits[id]);
    const isGroundCircuit = circuit.isGroundCircuit;
    const isMainCircuit = circuit.groundCircuitId;

    let associationProps = {};

    /**
     * circuits with grounds off parallel need their props
     * updated depending on whether or not both the main and
     * ground have been selected
     */
    if (isGroundCircuit) {
      const mainId = circuit.mainCircuitId && circuit.mainCircuitId.toString();
      const isMainSelected = selectedCircuits.indexOf(mainId) !== -1;

      // if it is a ground circuit and its main is selected
      // add 'mainCircuitId' and 'isGroundCircuit' prop
      if (isMainSelected) {
        associationProps = {
          isGroundCircuit: true,
          mainCircuitId: dateCreated + 1
        };
      } else {
        // else remove 'isGroundCircuit' and 'mainCircuitId' props
        delete circuit.isGroundCircuit;
        delete circuit.mainCircuitId;
      }
    } else if (isMainCircuit) {
      const groundId =
        circuit.groundCircuitId && circuit.groundCircuitId.toString();
      const isGroundSelected = selectedCircuits.indexOf(groundId) !== -1;

      // if it is a main circuit and its ground is selected
      // add 'groundCircuitId' prop
      if (isGroundSelected) {
        associationProps = {
          groundCircuitId: dateCreated - 1
        };
      } else {
        // else remove 'groundCircuitId' prop
        delete circuit.groundCircuitId;
      }
    }

    acc[dateCreated] = {
      ...circuit,
      ...associationProps,
      dateCreated,
      dateModified: dateCreated
    };

    return acc;
  }

  buildDuplicatedCircuits = () => {
    const selectedCircuits = this.state.selectedCircuits.slice(0);

    const duplicateCircuits = selectedCircuits
      .sort()
      .reduce((acc, circuitId, index) => {
        acc = this.updateCircuitProps(acc, circuitId, index);
        return acc;
      }, {});

    return duplicateCircuits;
  };

  duplicateCircuits = () => {
    const { job } = this.props;
    const circuits = job.circuits;
    const selectedCircuits = this.state.selectedCircuits;
    const duplicateCircuits = this.buildDuplicatedCircuits();

    this.props.firestore
      .update(`jobs/${this.props.match.params.jobId}`, {
        circuits: { ...circuits, ...duplicateCircuits }
      })
      .then(() => {
        const duplicateCircuitsIds = Object.keys(duplicateCircuits);
        this.setState({
          selectedCircuits: [...selectedCircuits, ...duplicateCircuitsIds],
          lastDuplicatedCircuits: duplicateCircuitsIds,
          autoBuild: {
            showNotification: false,
            circuits: [],
            reels: []
          }
        });
      })
      .catch(error => {
        this.props.showToast('Circuits could not be created', 'error');
      });
  };

  onEditCircuits = () => {
    this.props.showEditCircuitsModal({
      circuitIds: this.state.selectedCircuits,
      onSuccess: () => {
        this.setState({
          autoBuild: {
            showNotification: false,
            circuits: [],
            reels: []
          }
        });
      }
    });
  };

  /**
   * Update the job in firebase to persist the newly selected
   * sort options. This means however the user sorts their feeder summary,
   * it will stay that way across page refreshes.
   *
   * @param {Object} feederSummarySortPreference
   */
  onChangeSort = feederSummarySortPreference => {
    this.props.firestore
      .update(`jobs/${this.props.match.params.jobId}`, {
        feederSummarySortPreference
      })
      .catch(error => {
        this.props.showToast(
          'There was a problem saving your sort preferences',
          'error'
        );
      });
  };

  /**
   * Determine if we should disable the auto-build multiselect option.
   * Only do this if every circuit the user has selected already belongs to a reel.
   *
   * @return {Boolean}
   */
  shouldDisableAutoBuild() {
    const { selectedCircuits } = this.state;
    const { job } = this.props;

    if (!selectedCircuits.length) {
      return false;
    }

    const circuitsOnReels = job.reels.reduce((acc, reel) => {
      const ids = (reel.circuits && reel.circuits.map(c => c.id)) || [];
      return [...acc, ...ids];
    }, []);

    return selectedCircuits.every(id => circuitsOnReels.indexOf(id) !== -1);
  }

  render() {
    const { autoBuild } = this.state;
    const { jobs, catalog } = this.props;
    if (!jobs || !catalog) {
      return null;
    }

    return (
      <div>
        <JobSummary />
        {autoBuild.showNotification && (
          <AutoBuildReelsNotification
            reels={autoBuild.reels}
            circuits={autoBuild.circuits}
            onClickUndo={this.onClickAutoBuildUndo}
          />
        )}
        {this.renderSummaryTable()}
      </div>
    );
  }

  renderSummaryTable() {
    const { job } = this.props;
    const showFooter = this.state.selectedCircuits.length > 0;

    if (this.isEmpty(job.circuits)) {
      return (
        <div className="empty-container">
          <img
            src="/assets/img/no-feeder.svg"
            width="715"
            height="348"
            alt=" "
          />
          <p className="important-message">
            You haven’t configured a feeder schedule for this job yet.
          </p>
        </div>
      );
    }

    const columns = this.getTableColumns();
    const data = this.getTableData();

    return (
      <Table
        sortable
        data={data}
        columns={columns}
        id="feeder-summary-table"
        onChangeSort={this.onChangeSort}
        sortPreference={job.feederSummarySortPreference || {}}
      >
        {showFooter && this.renderTableFooter()}
      </Table>
    );
  }

  getTableColumns = () => {
    const { job } = this.props;

    const columns = [
      { field: 'from', label: 'From', sortable: true },
      { field: 'to', label: 'To', sortable: true },
      { field: 'size', label: 'Size', sortable: true },
      { field: 'length', label: 'Length', sortable: true },
      { field: 'reel', label: 'Reel', sortable: true },
      {
        field: 'expand-menu',
        sortable: false,
        label: (
          <ExpandCollapse
            expanded={!!this.state.expandedCircuits.length}
            onClick={this.toggleExpandAll}
          />
        )
      }
    ];

    if (!job.quoteRequested) {
      columns.unshift({
        field: 'checkbox',
        label: (
          <Checkbox
            id="header-checkbox"
            onChange={this.toggleTableCheckbox}
            checked={this.state.tableCheckboxIsSelected}
            deselectMode={
              Object.keys(job.circuits).length !==
              this.state.selectedCircuits.length
            }
          />
        ),
        sortable: false
      });
    }

    return columns;
  };

  getTableData = () => {
    const { job, catalog } = this.props;
    const { selectedCircuits, lastDuplicatedCircuits } = this.state;

    let reelNames = {};
    job.reels.forEach((reel, i) => {
      if (reel.circuits && reel.circuits.length) {
        reel.circuits.forEach(circuit => (reelNames[circuit.id] = reel.name));
      }
    });

    return Object.keys(job.circuits).map(id => {
      const circuit = { ...job.circuits[id], id };
      const isSelected = selectedCircuits.indexOf(circuit.id) > -1;
      const isDuplicated = lastDuplicatedCircuits.indexOf(circuit.id) > -1;

      return {
        fields: {
          from: circuit.from,
          to: circuit.to,
          size: circuit.diameter,
          length: circuit.length,
          reel: reelNames[circuit.id] || '',
          dateCreated: circuit.dateCreated
        },
        row: (
          <FeederSummaryItem
            key={circuit.id}
            circuit={circuit}
            catalog={catalog}
            metric={job.metric}
            isSelected={isSelected}
            isDuplicate={isDuplicated}
            reel={reelNames[circuit.id]}
            quoteRequested={job.quoteRequested}
            selectCircuit={this.selectCircuit}
            toggleExpandCircuit={this.toggleExpandCircuit}
            expanded={this.state.expandedCircuits.indexOf(id) !== -1}
          />
        )
      };
    });
  };

  renderTableFooter() {
    const { job } = this.props;
    const numCircuits = this.state.selectedCircuits.length;
    const checked = numCircuits > 0;
    const circuitForm = numCircuits === 1 ? 'Circuit' : 'Circuits';

    return (
      <tfoot>
        <tr>
          <td>
            <Checkbox
              id="footer-checkbox"
              checked={checked}
              onChange={this.toggleTableCheckbox}
              deselectMode={Object.keys(job.circuits).length !== numCircuits}
            />
          </td>
          <td>
            <div className="circuit-count">{numCircuits}</div>
            {circuitForm} Selected
          </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td className="footer-icons">
            <IconButton
              onClick={this.deleteCircuits}
              type="delete"
              tooltipText="Delete Circuits"
            />
            <IconButton
              disabled={this.shouldDisableAutoBuild()}
              onClick={this.autoBuild}
              type="auto-build"
              tooltipText="Auto Assign to Reels"
            />
            <IconButton
              onClick={this.duplicateCircuits}
              type="duplicate"
              tooltipText="Duplicate Circuits"
            />
            <IconButton
              onClick={this.onEditCircuits}
              type="mass-edit"
              tooltipText="Mass Edit Circuits"
            />
          </td>
        </tr>
      </tfoot>
    );
  }
}

const mapStateToProps = ({ firestore: { data } }, props) => {
  const { projects, catalogs } = data;
  const jobs = data[`jobs-${props.match.params.projectId}`];
  const job = (jobs && jobs[props.match.params.jobId]) || {};
  const catalog = job && catalogs ? catalogs[job.country || 'us'] : {};
  const simreels = catalogs && catalogs.simreels;

  return {
    job,
    jobs, // jobs needs to be here for the 404 HOC to work properly
    catalog,
    projects,
    simreels
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showDeleteCircuitModal: deleteCircuitObj => {
    dispatch(showDeleteCircuitModal(deleteCircuitObj));
  },
  showEditCircuitsModal: payload => {
    const { jobId, projectId } = ownProps.match.params;
    dispatch(showEditCircuitsModal({ ...payload, jobId, projectId }));
  },
  showToast: (message, messageType) => {
    dispatch(showToast(message, messageType));
  }
});

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(FeederSummary);
