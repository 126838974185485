import React from 'react';

/**
 * Success toast message for resetting password.
 */
export const SuccessToast = ({ resending = false, onResend, onCancel }) => {
  const prefix = resending
    ? 'Password reset email has been re-sent. '
    : 'Password reset sent. ';
  const message =
    'Please check your email and follow the link to reset your password.';

  return (
    <div>
      {prefix + message}&nbsp;
      <span key="resend" className="resend-link" onClick={onResend}>
        Resend password reset email.
      </span>
      <div key="exit" className="exit-icon" onClick={onCancel} />
    </div>
  );
};

/**
 * Error toast message for resetting password.
 */
export const ErrorToast = ({ onCancel }) => {
  return (
    <div>
      Password reset email could not be sent. Contact Southwire.
      <div key="exit" className="exit-icon" onClick={onCancel} />
    </div>
  );
};
