import React from 'react';

const PATH_LENGTH = 299;
const getDashOffset = percent => {
  let px = (PATH_LENGTH * (100 - percent)) / 100;
  return px + 'px';
};

class ReelCapacityGraph extends React.Component {
  render() {
    const percent = Math.min(100, Math.round(this.props.value * 100));
    return (
      <div className="reel-graph-container">
        <div className="reel-graph">
          <div className="display">{percent + '%'}</div>
          <svg viewBox="0 0 100 100">
            <circle
              className="track"
              cx="50"
              cy="50"
              strokeWidth="4"
              r="47.5"
              stroke="#e6e7e8"
              fill="none"
            />
            <circle
              className="progress"
              cx="50"
              cy="50"
              strokeWidth="4"
              r="47.5"
              stroke="#12b149"
              fill="none"
              style={{ strokeDashoffset: getDashOffset(percent) }}
            />
          </svg>
        </div>
        <p className="label">{this.props.label}</p>
      </div>
    );
  }
}

export default ReelCapacityGraph;
