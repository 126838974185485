import React from 'react';
import classNames from 'classnames';
import Checkbox from '../patterns/Checkbox';
import Expand from '../patterns/Expand';
import HoverableText from '../patterns/HoverableText';
import SimPullHead from '../patterns/SimPullHead';
import ConductorDetail from '../patterns/ConductorDetail';
import NoColumnValue from '../patterns/NoColumnValue';

export default class FeederSummaryItem extends React.Component {
  render() {
    const {
      circuit,
      reel,
      isSelected,
      quoteRequested,
      selectCircuit,
      isDuplicate,
      toggleExpandCircuit
    } = this.props;
    const expanded = this.props.expanded ? 'true' : null;
    const groundConductor = circuit.conductors.find(c => c.isGroundConductor);
    const isFromHoverable = circuit.from && circuit.from.length > 10;
    const isToHoverable = circuit.to && circuit.to.length > 10;
    const isReelHoverable = reel && reel.length > 20;

    const className = classNames('summary-circuit-item', {
      duplicate: isDuplicate
    });

    const rows = [
      <tr className={className} key={`circuit-${circuit.id}`}>
        {!quoteRequested && (
          <td className="checkbox-cell">
            <Checkbox
              id={circuit.id}
              value={circuit.id}
              onChange={e => selectCircuit(e, circuit.id)}
              checked={isSelected}
            />
          </td>
        )}
        <td>
          {circuit.from ? (
            <HoverableText text={circuit.from} isHoverable={isFromHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>
          {circuit.to ? (
            <HoverableText text={circuit.to} isHoverable={isToHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>{circuit.size}</td>
        <td>{`${circuit.length}${this.props.metric ? 'm' : "'"}`}</td>
        <td>
          {reel ? (
            <HoverableText text={reel} isHoverable={isReelHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td className="expand-cell">
          <Expand
            onClick={() => {
              toggleExpandCircuit(circuit.id, !this.props.expanded);
            }}
            expanded={expanded}
          />
        </td>
      </tr>
    ];

    if (expanded) {
      rows.push(
        <tr
          className="summary-circuit-item-details"
          key={`expanded-circuit-${circuit.id}`}
        >
          <td className="expanded-item" colSpan="7">
            <div className="expanded-header">
              <span>
                Metal / Insulation: {circuit.metal} / {circuit.insulation}
              </span>
              {!!groundConductor && (
                <span>
                  Ground: {groundConductor.groundMetal} / {circuit.insulation}
                </span>
              )}
              {circuit && circuit.simpull && <SimPullHead />}
            </div>
            <div className="expanded-colors">{this.displayColors(circuit)}</div>
          </td>
        </tr>
      );
    }
    return rows;
  }

  displayColors(circuit) {
    const conductors = circuit.conductors;
    if (conductors && conductors.length) {
      //new updated model 8/2018
      const colorCollection = conductors.map((conductor, id) => {
        return (
          <ConductorDetail
            color={conductor.color}
            size={conductor.size}
            key={id + '1'}
            catalog={this.props.catalog}
          />
        );
      });
      return colorCollection;
    }
    //original data model
    const colors = circuit.colors;
    const colorCollection = colors.map((color, id) => {
      return (
        <ConductorDetail
          color={color}
          size={circuit.size}
          key={id + '1'}
          catalog={this.props.catalog}
        />
      );
    });
    return colorCollection;
  }
}
