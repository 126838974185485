import React, { Component } from 'react';
import Dropdown from '../patterns/Dropdown';
import colorSets from '../constants/products';

/**
 * Component that handles the "Number of Conductors" dropdown element
 * on the Create Circuit page. This includes nested color preset options as well.
 */
export default class ConductorCountDropdown extends Component {
  /**
   * Event handler for when a user selects an option from the dropdown.
   *
   * Separate out the number and the color presets and send both along to
   * the calling component, when applicable.
   *
   * @param {String|Number} value
   */
  onSelect = value => {
    if (Number.isInteger(value)) {
      this.props.onSelect(value);
      return;
    }

    const colors = value.split('|');
    this.props.onSelect(colors.length, colors);
  };

  /**
   * Return the color value used to style the color preset swatches.
   * If an RGB value is available, use that. Otherwise default to the color name.
   *
   * @param  {String} color
   * @return {String}
   */
  getColor(color) {
    const { colors } = this.props;
    const selectedColor = colors[color];

    if (!selectedColor) {
      return color;
    }

    return selectedColor.rgb ? `rgb(${selectedColor.rgb})` : selectedColor.name;
  }

  /**
   * Build the options array used in the dropdown.
   * This includes the nested color preset options.
   *
   * @return {Array}
   */
  getOptions() {
    const { country } = this.props;
    const colorSet = colorSets[country];

    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      {
        value: 3,
        label: '3',
        options: this.getNestedOptions(colorSet.three)
      },
      {
        value: 4,
        label: '4',
        options: this.getNestedOptions(colorSet.four)
      },
      { value: 5, label: '5' },
      { value: 6, label: '6' }
    ];
  }

  /**
   * Build the options objects for the given nested color preset.
   *
   * @param  {Array} colorSet
   * @return {Array}
   */
  getNestedOptions(colorSet) {
    const { sameSizes, primaryConductor, availableColors } = this.props;

    // You can only select color presets when all conductors are the same size
    // and we have already selected a primary conductor size.
    if (!sameSizes || !primaryConductor.hasOwnProperty('size')) {
      return [];
    }

    return colorSet
      .filter(colors => {
        return colors.every(c => {
          return (
            availableColors[primaryConductor.size] &&
            availableColors[primaryConductor.size].indexOf(c) !== -1
          );
        });
      })
      .map(colors => ({
        value: colors.join('|'),
        label: this.renderSwatch(colors)
      }));
  }

  /**
   * Renders an individual color swatch.
   *
   * @param  {Array} colors
   * @return {JSX}
   */
  renderSwatch(colors) {
    return (
      <div className="swatch__container">
        {colors.map((color, index) => {
          const className = ['swatch'];
          if (color.toLowerCase() === 'white') {
            className.push('outline');
          }

          return (
            <div key={index} className={className.join(' ')} aria-label={color}>
              <div
                className="swatch-color"
                style={{ backgroundColor: this.getColor(color) }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { value } = this.props;

    return (
      <Dropdown
        required
        value={value}
        placeholder="Select"
        onSelect={this.onSelect}
        id="conductor-count-dropdown"
        className="conductors-number"
        label="Number of Conductors"
        options={this.getOptions()}
      />
    );
  }
}
