import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReelSummaryItem from './ReelSummaryItem';
import { firestoreConnect } from 'react-redux-firebase';
import JobSummary from './JobSummary';
import withNotFoundRedirect from '../common/withNotFoundRedirect';
import Table from '../patterns/Table';

export class ReelSummary extends React.Component {
  render() {
    const { jobs, catalogs } = this.props;
    if (!jobs || !catalogs) {
      return null;
    }

    return (
      <div>
        <JobSummary />
        {this.renderSummaryTable()}
      </div>
    );
  }

  renderSummaryTable() {
    const jobs = this.props.jobs || {};
    const jobId = this.props.match.params.jobId;
    const job = jobs[jobId] || {};
    const reels = job.reels || [];
    const circuits = job.circuits || {};
    const catalog = this.props.catalogs[job.country || 'us'];

    if (!reels.length) {
      return (
        <div className="empty-container">
          <img
            src="/assets/img/no-reels.svg"
            width="715"
            height="265"
            alt=" "
          />
          <p className="important-message">
            You haven’t configured reels for this job yet.
          </p>
        </div>
      );
    }

    const columns = [
      { field: 'name', label: 'Name' },
      { field: 'package', label: 'Package' },
      { field: 'size', label: 'Size' },
      { field: 'width', label: 'Width' },
      { field: 'weight', label: 'Weight' },
      { field: 'expand-menu' }
    ];

    const data = reels.map((reel, index) => ({
      row: (
        <ReelSummaryItem
          reel={reel}
          circuits={circuits}
          metric={job.metric}
          key={`reel-${index}`}
          catalog={catalog}
        />
      )
    }));

    return (
      <Table
        data={data}
        columns={columns}
        id="reel-summary-table"
        className="reel-list-summary"
      />
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(({ firestore: { data } }, props) => ({
    jobs: data[`jobs-${props.match.params.projectId}`],
    catalogs: data.catalogs
  })),
  withNotFoundRedirect('jobId', 'jobs')
)(ReelSummary);
