import React from 'react';
import classNames from 'classnames';
import Input from '../patterns/Input';
import Dropdown from '../patterns/Dropdown';
import Toggle from '../patterns/Toggle';
import Button from '../patterns/Button';
import productFinder from '../utils/productFinder';
import convertUnits from '../utils/convertUnits';
import SimPullHead from '../patterns/SimPullHead';
import ReelConfigTable from './ReelConfigTable';
import assignReels from '../utils/MVReelAssignment';
import { getUpdatedReelProduct } from '../utils/reelUtil';
import cloneDeep from 'lodash/cloneDeep';

const MAX_QUANTITY = 99;
const rex = /^\d*$/;

export const DEFAULT_METAL = 'CU';
export const DEFAULT_VOLTAGE = 15;
export const DEFAULT_THICKNESS = 133;
export const DEFAULT_CONDUCTORS_SIZE = '750';
export const DEFAULT_JACKET = 'PVC';
export const DEFAULT_SIMPULL = true;
export const DEFAULT_NUM_CONDUCTORS = 3;

export const DEFAULT_INSULATION = 'NL-EPR';
export const DEFAULT_SHIELD = 'Copper Tape';
export const DEFAULT_CONDUCTOR_TYPE = 'Single';

class MVCircuitBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      quantity: 1,
      from: '',
      to: '',
      length: '',
      metal: DEFAULT_METAL,
      voltage: DEFAULT_VOLTAGE,
      thickness: DEFAULT_THICKNESS,
      size: DEFAULT_CONDUCTORS_SIZE,
      jacket: DEFAULT_JACKET,
      simpullHead: DEFAULT_SIMPULL,
      isAFrame: props.job.isAFrame || false,

      groundMetal: 'none',
      groundInsulation: '',
      groundSize: '',

      reelName: '',
      reelType: '',
      reelConfig: '1/C'
    };
  }

  componentDidMount() {
    if (this.props.circuit) {
      let circuit = this.props.circuit;
      const { name, type, config } = circuit.reel;
      const updatedState = {
        from: circuit.from,
        to: circuit.to,
        metal: circuit.metal,
        length: circuit.length + '',
        voltage: circuit.voltage,
        thickness: circuit.thickness,
        size: circuit.size,
        jacket: circuit.jacket,
        simpullHead: circuit.simpull,
        isAFrame: circuit.isAFrame,
        reelName: name,
        reelType: type,
        reelConfig: config
      };

      // update ground states if circuit has a ground
      if (circuit.ground) {
        const { metal, insulation, size } = circuit.ground;
        updatedState.groundMetal = metal;
        updatedState.groundInsulation = insulation;
        updatedState.groundSize = size;
      }

      this.setState({ ...updatedState });
    }
  }

  submitCircuit = event => {
    event.preventDefault();
    const circuits = this.buildUpdatedCircuits();
    if (!circuits) {
      return;
    }

    this.setState({ saving: true });
    this.props.firestore
      .update(`jobs/${this.props.jobId}`, { circuits })
      .then(yay => {
        this.setState({ saving: false });
        this.props.routeOnCancel();
      })
      .catch(error => {
        this.setState({ saving: false });
        this.props.showToast(
          "Your circuit can't be saved at this time. Please try again later.",
          'error'
        );
      });
  };

  buildUpdatedCircuits = () => {
    let circuits = cloneDeep(this.props.job.circuits);
    const {
      from,
      to,
      length,
      metal,
      voltage,
      thickness,
      size,
      jacket,
      isAFrame,
      simpullHead,
      reelName,
      reelType,
      reelConfig
    } = this.state;
    const now = Date.now();
    const product = this.getProduct();

    const diameter = product.diameter;

    let updatedCircuit = {
      from: from.trim(),
      to: to.trim(),
      length: length,
      metal: metal,
      voltage: voltage,
      thickness: thickness,
      size: size,
      jacket: jacket,
      simpull: simpullHead,
      isAFrame: isAFrame,
      dateModified: now,
      diameter: diameter,
      sku: product.stock_number,
      weight: this.getWeight(product),
      volume: this.getVolume(product),
      numConductors: DEFAULT_NUM_CONDUCTORS,
      shield: DEFAULT_SHIELD,
      conductorType: DEFAULT_CONDUCTOR_TYPE,
      insulation: DEFAULT_INSULATION,
      reel: {
        name: reelName.trim(),
        type: reelType,
        config: reelConfig
      }
    };

    const reelProducts = assignReels(
      this.props.catalog.mediumVoltage,
      updatedCircuit,
      this.props.job.restrictions
    );

    if (reelProducts === false) {
      this.props.showToast(
        "Circuit exceeds the reel's maximum capacity",
        'error'
      );
      return null;
    }
    updatedCircuit.reel.products = reelProducts;

    if (this.state.groundMetal !== 'none') {
      const ground = this.buildGround();
      if (!ground) {
        this.props.showToast(
          'Our product catalog doesn’t support your current ground settings.',
          'error'
        );
        return null;
      }

      updatedCircuit.ground = ground;
    }

    if (this.props.circuit) {
      let index = this.props.circuitId;
      if (!circuits[index]) {
        this.props.showToast(
          "Your circuit can't be saved at this time. Please try again later.",
          'error'
        );
        return null;
      }
      const groundMetal =
        this.props.circuit.ground && this.props.circuit.ground.metal;

      /**
       * if a ground has been removed during editing a circuit
       * remove the ground object from the circuit
       */
      if (groundMetal !== 'none' && this.state.groundMetal === 'none') {
        delete circuits[index].ground;
      }

      Object.assign(circuits[index], updatedCircuit);
    } else {
      let quantity = parseInt(this.state.quantity, 10) || 1;

      for (let i = 0; i < quantity; i++) {
        const dateCreated = now - i;

        // We only update the reel name to include the count if we're creating more than 1 circuit.
        circuits[dateCreated] = {
          dateCreated,
          ...updatedCircuit,
          reel: {
            ...updatedCircuit.reel,
            ...(quantity > 1 && {
              name: `${updatedCircuit.reel.name} - ${i + 1}`
            })
          }
        };
      }
    }

    return circuits;
  };

  buildGround() {
    const { job } = this.props;
    const { groundMetal, groundInsulation, groundSize } = this.state;
    const catalog = this.props.catalog[job.country || 'us'];

    // 1.) Find the circuit product for this ground. Same as 600V,
    //     we should first try with "Green" then fallback to "Black"
    //     if the product doesn't exist in the catalog.

    let product = productFinder.getProduct(
      catalog,
      groundMetal,
      groundInsulation,
      groundSize,
      'Green'
    );

    if (!product) {
      product = productFinder.getProduct(
        catalog,
        groundMetal,
        groundInsulation,
        groundSize,
        'Black'
      );

      if (!product) {
        // Can't find a valid product in the catalog for either Green or Black.
        return null;
      }
    }

    // 2.) Determine the most appropriate reel for this ground circuit.

    const circuits = {
      1: {
        weight: this.getWeight(product, 1),
        volume: this.getVolume(product, 1)
      }
    };

    const startingReel = {
      circuits: [{ id: 1 }],
      restrictions: {
        height: (job.restrictions && job.restrictions.height) || '',
        width: (job.restrictions && job.restrictions.width) || '',
        weight: (job.restrictions && job.restrictions.weight) || ''
      }
    };

    const reel = getUpdatedReelProduct(startingReel, circuits, catalog);
    if (!reel) {
      // Couldn't find a reel that fit the given configuration.
      return null;
    }

    // We don't need this circuits reference here since we're
    // bundling the reel next to the ground circuit info.
    delete reel.circuits;

    return {
      metal: groundMetal,
      insulation: groundInsulation,
      size: groundSize,
      sku: product.stock_number,
      reel
    };
  }

  render() {
    const className = classNames('build-circuit', {
      'edit-circuit': this.props.circuit,
      mv: this.props.job.jobType === 'MV'
    });

    return (
      <form className={className} onSubmit={this.submitCircuit}>
        {!this.props.circuit && (
          <Input
            type="text"
            label="Quantity"
            onChange={this.updateQuantity}
            value={this.state.quantity}
            maxLength="2"
          />
        )}

        <Input
          type="text"
          label="From"
          onChange={this.updateField('from')}
          value={this.state.from}
          maxLength="15"
        />

        <Input
          type="text"
          label="To"
          onChange={this.updateField('to')}
          value={this.state.to}
          maxLength="15"
        />

        <Input
          type="text"
          label="Length"
          placeholder={this.props.job.metric ? 'Meters' : 'Feet'}
          onChange={this.updateLength}
          value={this.state.length}
          required
          maxLength="6"
        />

        <Dropdown
          required
          id="metal"
          className="metal"
          label="Metal"
          placeholder="Please Select"
          value={this.state.metal}
          onSelect={this.updateMetal}
          options={this.getMetalOptions()}
        />

        <Dropdown
          required
          id="voltage-thickness"
          className="voltage-thickness"
          label="Voltage / Thickness"
          placeholder="Please Select"
          value={`${this.state.voltage}|${this.state.thickness}`}
          onSelect={this.updateVoltageAndThickness}
          options={this.getVoltageOptions()}
        />

        <Dropdown
          required
          id="conductors-size"
          className="conductors-size"
          label="Conductors Size"
          placeholder="Please Select"
          value={this.state.size}
          onSelect={this.updateSize}
          options={this.getSizeOptions()}
          disabled={!this.state.voltage}
        />

        <Dropdown
          required
          id="jacket"
          className="jacket"
          label="Jacket"
          placeholder="Select Type"
          value={this.state.jacket}
          onSelect={this.updateJacket}
          options={this.getJacketOptions()}
          disabled={!this.state.size}
        />

        <Toggle
          name={<SimPullHead />}
          checked={this.state.simpullHead}
          onChange={this.updateCheckbox('simpullHead')}
          containerName={'simpull-toggle'}
        />

        <h3>Ground Wire</h3>

        <Dropdown
          id="ground-metal-insulation"
          className="ground-metal-insulation"
          label="Ground Metal / Insulation"
          placeholder="Select Type"
          value={
            this.state.groundMetal === 'none'
              ? this.state.groundMetal
              : `${this.state.groundMetal}|${this.state.groundInsulation}`
          }
          onSelect={this.updateGroundMetalInsulation}
          options={this.getGroundMetalInsulationOptions()}
        />

        <Dropdown
          id="ground-size"
          className="ground-size"
          label="Ground Size"
          placeholder="Please Select"
          value={this.state.groundSize}
          onSelect={this.updateGroundSize}
          options={this.getGroundSizeOptions()}
          disabled={this.state.groundMetal === 'none'}
        />

        <h3>Reels</h3>

        <Input
          required
          type="text"
          maxLength="30"
          label="Reel Name"
          value={this.state.reelName}
          containerClassName="reel-name"
          onChange={this.updateField('reelName')}
        />

        <Dropdown
          required
          id="reel-type"
          label="Reel Type"
          placeholder="Please Select"
          value={this.state.reelType}
          onSelect={this.updateReelType}
          options={[
            { value: 'wood', label: 'Wood' },
            { value: 'steel', label: 'Steel' }
          ]}
        />

        <Dropdown
          id="reel-config"
          label="Reel Configuration"
          placeholder="Please Select"
          value={this.state.reelConfig}
          onSelect={this.updateReelConfig}
          options={[
            { value: '1/C', label: '1/C' },
            { value: '2/C', label: '2/C' },
            { value: '3/C', label: '3/C' }
          ]}
        />

        <ReelConfigTable type={this.state.reelConfig} />

        <Toggle
          name="Use A-Frames"
          containerName="isAFrame-toggle"
          checked={this.state.isAFrame}
          onChange={this.updateCheckbox('isAFrame')}
        />

        <div className="action-buttons">
          <div>
            <Button className="secondary" onClick={this.props.routeOnCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!this.isFormValid() || this.state.saving}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }

  isFormValid() {
    // Ground info is valid when either ground metal is none or
    // ground metal is defined AND ground size is defined.
    const groundValid =
      this.state.groundMetal === 'none' ||
      (this.state.groundMetal !== 'none' && this.state.groundSize !== '');

    return (
      this.state.length !== '' &&
      !isNaN(this.state.length) &&
      this.state.metal.trim().length &&
      !isNaN(this.state.voltage) &&
      !isNaN(this.state.thickness) &&
      this.state.size.trim().length &&
      this.state.jacket.trim().length &&
      this.state.reelName.trim().length &&
      this.state.reelType.trim().length &&
      groundValid
    );
  }

  getMetalOptions() {
    const metalOptions = {
      CU: 'Copper',
      AL: 'Aluminum'
    };
    const products = this.props.catalog.mediumVoltage.products.items;

    const metals = [...new Set(products.map(product => product.metal))];

    return metals.map(metal => ({
      value: metal,
      label: metalOptions[metal]
    }));
  }

  getVoltageOptions() {
    const products = this.props.catalog.mediumVoltage.products.items;
    const filteredProducts = products.filter(
      product => product.metal === this.state.metal
    );

    const voltages = [
      ...new Set(filteredProducts.map(product => product.voltage))
    ];
    const voltageOptions = [];
    voltages.forEach(voltage => {
      // all products with this voltage
      const voltageProducts = filteredProducts.filter(
        product => product.voltage === voltage
      );

      // unique thickness options for this voltage
      const thicknessOptions = [
        ...new Set(voltageProducts.map(product => product.thickness))
      ];

      // for each thickness, return a voltage/thickness option obj
      thicknessOptions.forEach(thickness => {
        const voltageLabel = thickness
          ? `${voltage}kv / ${thickness}%`
          : `${voltage}kv`;

        voltageOptions.push({
          value: `${voltage}|${thickness}`,
          label: voltageLabel
        });
      });
    });

    return voltageOptions;
  }

  getSizeOptions() {
    const products = this.props.catalog.mediumVoltage.products.items;
    const filteredProducts = products.filter(
      product =>
        product.metal === this.state.metal &&
        product.voltage === this.state.voltage &&
        product.thickness === this.state.thickness
    );

    const sizes = [...new Set(filteredProducts.map(product => product.size))];

    return sizes.map(size => {
      return {
        value: size,
        label: size
      };
    });
  }

  getJacketOptions() {
    const products = this.props.catalog.mediumVoltage.products.items;
    const filteredProducts = products.filter(
      product =>
        product.metal === this.state.metal &&
        product.voltage === this.state.voltage &&
        product.thickness === this.state.thickness &&
        product.size === this.state.size
    );

    const jackets = [
      ...new Set(filteredProducts.map(product => product.jacket))
    ];

    return jackets.map(jacket => {
      return {
        value: jacket,
        label: jacket
      };
    });
  }

  /**
   * Return the array of options used for the Ground Metal/Insulation field.
   * We only support Copper as the ground metal.
   *
   * US and CA have different available options.
   *
   * @return {Array}
   */
  getGroundMetalInsulationOptions() {
    const { job } = this.props;

    if (job.country === 'us') {
      return [
        { value: 'none', label: 'None' },
        { value: 'CU|THHN', label: 'Copper THHN' },
        { value: 'CU|XHHW', label: 'Copper XHHW' }
      ];
    }

    // Canadian ground metal/insulation options
    const caInsulation = this.props.catalog.ca.insulations.items;
    const options = caInsulation.map(insulation => {
      return {
        value: `CU|${insulation.id}`,
        label: `Copper ${insulation.name}`
      };
    });

    options.unshift({
      value: 'none',
      label: 'None'
    });

    return options;
  }

  getGroundSizeOptions() {
    const { groundMetal, groundInsulation } = this.state;
    const { job } = this.props;

    if (groundMetal === 'none') {
      return [];
    }

    const catalog = this.props.catalog[job.country || 'us'];

    return catalog.products.items
      .filter(product => {
        return (
          product.metal === groundMetal &&
          product.product_type === groundInsulation
        );
      })
      .sort((a, b) => a.diameter - b.diameter)
      .map(product => product.size)
      .filter((size, index, self) => {
        return self.indexOf(size) === index;
      })
      .map(size => ({
        value: size,
        label: size
      }));
  }

  updateGroundMetalInsulation = value => {
    const [groundMetal, groundInsulation] = value.split('|');
    const updatedState = {
      groundMetal,
      groundInsulation,
      // reset ground size when ground metal/insulation updates like for 600V
      groundSize: ''
    };

    if (groundMetal === 'none') {
      updatedState.groundInsulation = '';
    }

    this.setState(updatedState);
  };

  updateGroundSize = groundSize => {
    this.setState({ groundSize });
  };

  updateMetal = metal => {
    if (metal !== this.state.metal) {
      // update metal state and clear out subsequent fields
      this.setState({
        metal,
        voltage: '',
        thickness: '',
        size: '',
        jacket: ''
      });
    }
  };

  updateVoltageAndThickness = value => {
    let [voltage, thickness] = value.split('|');
    voltage = parseFloat(voltage);
    thickness = thickness && parseFloat(thickness);

    if (voltage !== this.state.voltage || thickness !== this.state.thickness) {
      // update voltage and thickness and clear out subsequent fields
      this.setState({
        voltage: voltage,
        thickness: thickness,
        size: '',
        jacket: ''
      });
    }
  };

  updateSize = size => {
    if (size !== this.state.size) {
      this.setState({
        size,
        jacket: ''
      });
    }
  };

  updateJacket = jacket => {
    if (jacket !== this.state.jacket) {
      this.setState({ jacket });
    }
  };

  updateQuantity = event => {
    let value = event.target.value;
    if (rex.test(value) && value <= MAX_QUANTITY) {
      this.setState({ quantity: value });
    }
  };

  updateLength = event => {
    let value = event.target.value;
    if (rex.test(value)) {
      this.setState({
        length: parseFloat(value) || ''
      });
    }
  };

  updateField = name => {
    return event => {
      this.setState({ [name]: event.target.value });
    };
  };

  updateCheckbox = name => {
    return event => {
      const isChecked = event.target.checked;
      const updatedState = { [name]: isChecked };
      this.setState(updatedState);
    };
  };

  updateReelType = reelType => {
    this.setState({ reelType });
  };

  updateReelConfig = reelConfig => {
    this.setState({ reelConfig });
  };

  getProduct() {
    return productFinder.getMVProduct(
      this.props.catalog.mediumVoltage,
      this.state.metal,
      this.state.size,
      this.state.voltage,
      this.state.thickness,
      this.state.jacket
    );
  }

  getWeight(product, numConductors = DEFAULT_NUM_CONDUCTORS) {
    const length = this.state.length;
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    // Conductors are identical so multiply conductor weight by number of conductors
    return (
      productFinder.getConductorWeight(product, lengthInFt) * numConductors
    );
  }

  getVolume(product, numConductors = DEFAULT_NUM_CONDUCTORS) {
    const length = this.state.length;
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return (
      productFinder.getConductorVolume(product, lengthInFt) * numConductors
    );
  }
}

export default MVCircuitBuilder;
