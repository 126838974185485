import MVFeederSummary from './MVFeederSummary';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../../common/withNotFoundRedirect';
import { showToast } from '../../actions/ToastActions';
import {
  showDeleteJobModal,
  showDeleteMVCircuitsModal,
  showEditMVCircuitsModal
} from '../../actions/ModalActions';

const mapStateToProps = (
  { firebase: { profile }, firestore: { data } },
  props
) => {
  const { projects, catalogs } = data;
  const { projectId, jobId } = props.match.params;

  const jobs = data[`jobs-${projectId}`];
  const job = (jobs && jobs[jobId]) || {};

  const project = projects && projectId ? projects[projectId] : {};

  const catalog = job && catalogs ? catalogs[job.country || 'us'] : {};
  const simreels = catalogs && catalogs.simreels;

  return {
    job,
    jobs, // jobs needs to be here for the 404 HOC to work properly
    jobId,
    catalog,
    project,
    projectId,
    simreels,
    profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    },
    showDeleteJobModal: deleteJobObj => {
      dispatch(showDeleteJobModal(deleteJobObj));
    },
    showDeleteCircuitsModal: payload => {
      dispatch(showDeleteMVCircuitsModal(payload));
    },
    showEditCircuitsModal: payload => {
      dispatch(showEditMVCircuitsModal(payload));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(MVFeederSummary);
