import React from 'react';

/**
 * Link to download the Southwire template.
 * If your profile says you're in the US, you get the US template.
 * Otherwise you get the Canadian template.
 */
export default ({ country }) => {
  const link =
    country === 'us'
      ? '/templates/Config Upload File V21 US.xlsm'
      : '/templates/Config Upload File V9 Canadian.xlsm';

  const download =
    country === 'us'
      ? 'Config Upload File V21 US.xlsm'
      : 'Config Upload File V9 Canadian.xlsm';

  return (
    <a className="download" href={link} download={download}>
      Download Template
    </a>
  );
};
