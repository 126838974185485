import React from 'react';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

export class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      message: '',
      disabled: true,
      error: false,
      saving: false
    };
  }

  handleProjectName(e) {
    let projectName = e.target.value;
    this.setState({ projectName: projectName });
    // using event target value instead of state bc calculates length immediately, state lags
    if (projectName.length <= 30) {
      this.setState({ message: '', disabled: false, error: false });
    } else {
      // disable submit button if length exceeds 30 chars
      this.setState({
        message: 'Project Name cannot exceed 30 characters.',
        disabled: true,
        error: true
      });
    }
    // set disabled back to true if user types and deletes all characters
    if (projectName.length === 0) {
      this.setState({ disabled: true, error: false });
    }
  }

  handleCancel() {
    this.props.history.push('/projects', null);
  }

  handleSubmit(e) {
    e.preventDefault();
    let projectName = this.state.projectName.trim();
    let userId = this.props.firebase.auth().currentUser.uid;
    let projects = this.props.projects || {};
    // see if project name already exists
    let match = Object.values(projects).some(function(project) {
      if (project.name === projectName) {
        return true;
      }
      return false;
    });
    if (match) {
      this.setState({ message: 'Project name already exists.', error: true });
    } else if (projectName.length === 0) {
      this.setState({ message: 'Project name is invalid.', error: true });
    } else {
      this.setState({ saving: true });
      // send object with project name and user id to firestore
      this.props.firestore
        .add(
          { collection: 'projects' },
          { name: projectName, userId: userId, dateCreated: Date.now() }
        )
        .then(() => {
          this.setState({ saving: false });
          this.props.history.push('/projects', null);
        })
        .catch(error => {
          // if error, display error message
          this.setState({ saving: false });
          this.setState({ message: error.message });
        });
    }
  }

  render() {
    return (
      <div className="project">
        <h2 className="page-title">Create Project</h2>
        <form
          id="create-edit-project-form"
          onSubmit={e => {
            this.handleSubmit(e);
          }}
        >
          <Input
            required
            label="Project Name"
            type="text"
            placeholder="Name"
            onChange={e => {
              this.handleProjectName(e);
            }}
            value={this.state.projectName}
            autoComplete="name"
            errorMessage={this.state.message}
            isError={this.state.error}
          />
          <div className="lineBreak" />
          <div className="submitButtons">
            <Button
              onClick={e => {
                this.handleCancel();
              }}
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={this.state.disabled || this.state.saving}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      }
    ];
  }),
  connect(({ firebase: { auth, profile }, firestore: { data } }) => ({
    projects: data.projects,
    auth,
    profile
  }))
)(CreateProject);
