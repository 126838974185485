import React from 'react';
import EditReelDisplay from './EditReelDisplay';
import { showToast } from '../actions/ToastActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../common/withNotFoundRedirect';

export class EditReelContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  // don't let user go to this page if job.quoteRequested
  componentDidMount() {
    if (this.isQuoteRequested()) {
      this.goBackToConfigure();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jobs) {
      if (this.isQuoteRequested()) {
        this.goBackToConfigure();
      }
    }
  }

  isQuoteRequested() {
    const jobId = this.props.match.params.jobId;
    if (
      this.props.jobs &&
      this.props.jobs[jobId] &&
      this.props.jobs[jobId].quoteRequested
    ) {
      return true;
    }
    return false;
  }

  goBackToConfigure() {
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}/jobs/${this.props.match.params.jobId}/configure`
    );
  }

  onSubmitEdit(name) {
    const now = Date.now();
    const jobId = this.props.match.params.jobId;
    const job = this.props.jobs[jobId];
    const reels = job.reels;
    const reelId = this.props.match.params.reelId;
    // clone array and set name and dateModified of specified reel
    const newReels = JSON.parse(JSON.stringify(reels));
    var updatedReel = newReels[reelId];
    updatedReel.name = name;
    updatedReel.dateModified = now;
    // save
    this.setState({ saving: true });
    this.props.firestore
      .update(`jobs/${jobId}`, { reels: newReels })
      .then(() => {
        // redirect
        this.setState({ saving: false });
        this.goBackToConfigure();
        this.props.showToast('Reel updated successfully.', 'success');
      })
      .catch(err => {
        this.setState({ saving: false });
        this.props.showToast('Error updating reel.', 'error');
      });
  }

  render() {
    const jobId = this.props.match.params.jobId;
    const job = this.props.jobs && this.props.jobs[jobId];
    const reels = job && job.reels;
    const reelId = this.props.match.params.reelId;
    const reel = reels && reels[reelId];
    const metric = job && job.metric;
    return (
      <div>
        <EditReelDisplay
          metric={metric}
          reel={reel}
          reels={reels}
          onSubmit={e => this.onSubmitEdit(e)}
          history={this.props.history}
          saving={this.state.saving}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(
    ({ firestore: { data } }, props) => ({
      jobs: data[`jobs-${props.match.params.projectId}`]
    }),
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(EditReelContainer);
