import React from 'react';
import sortArrow from '../../assets/sort-arrow.svg';
import sortUnselected from '../../assets/sort-unselected.svg';

const isEmpty = obj => Object.keys(obj).length < 1;
const render = (icon, alt) => (
  <img className={'sort-icon ' + alt} alt={alt} src={icon} />
);

const TableSortIcon = ({ field, sortPreference }) => {
  if (isEmpty(sortPreference) || field !== sortPreference.field) {
    return render(sortUnselected, 'sort-icon-unselected');
  }

  let icon, alt;
  switch (sortPreference.direction) {
    case 'asc':
      icon = sortArrow;
      alt = 'sort-icon-asc';
      break;
    case 'desc':
      icon = sortArrow;
      alt = 'sort-icon-desc';
      break;
    default:
      icon = sortUnselected;
      alt = 'sort-icon-unselected';
      break;
  }

  return render(icon, alt);
};

export default TableSortIcon;
