import React from 'react';
import Input from '../patterns/Input';
import SimPull from '../patterns/SimPull';
import Button from '../patterns/Button';

export class EditReelDisplay extends React.Component {
  reelMap;
  constructor(props) {
    super(props);
    // if reel already loaded, construct with simpull value to prevent a toggle animation
    // simpull is held in state to prevent input control errors. it is readonly.
    let simpull = false;
    if (props && props.reel) {
      simpull = props.reel.simpull;
    }
    this.state = {
      name: '',
      errorNameMessage: '',
      errorName: false,
      simpull: simpull
    };
  }
  componentDidMount() {
    if (this.props.reel) {
      this.setState({
        name: this.props.reel.name,
        simpull: this.props.reel.simpull
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.reel) {
      this.setState({
        name: nextProps.reel.name,
        simpull: nextProps.reel.simpull
      });
    }
  }
  getReelMap() {
    if (!this.reelMap) {
      this.reelMap = {};
      (this.props.reels || []).forEach(reel => {
        this.reelMap[reel.name] = true;
      });
    }
    return this.reelMap;
  }
  isUnusedReelName(name) {
    const reels = this.getReelMap();
    const existingName = this.props.reel && this.props.reel.name;

    return !reels[name] || name === existingName;
  }
  onChangeName(e) {
    let name = e.target.value;
    if (name.length > 30) {
      this.setState({
        name,
        errorName: true,
        errorNameMessage: 'Reel name cannot exceed 30 characters.'
      });
    } else if (!this.isUnusedReelName(name.trim())) {
      this.setState({
        name,
        errorName: true,
        errorNameMessage: 'Reel name already exists.'
      });
    } else {
      this.setState({
        name,
        errorName: false,
        errorNameMessage: ''
      });
    }
  }
  isFormValid() {
    if (
      this.state.name &&
      this.state.name.trim().length &&
      this.state.name.trim().length <= 30 &&
      this.isUnusedReelName(this.state.name.trim())
    ) {
      return true;
    }
    return false;
  }
  submitForm(e) {
    e.preventDefault();
    if (this.isFormValid()) {
      this.props.onSubmit(this.state.name.trim());
    }
  }
  render() {
    const reel = this.props.reel;
    const lengthUnit =
      this.props.metric !== undefined && (this.props.metric ? 'cm' : '"');
    const weightUnit =
      this.props.metric !== undefined && (this.props.metric ? 'kg' : 'lb');
    return (
      <div className="edit-reel-container">
        <h2 className="page-title">Edit Reel</h2>
        <form className="edit-reel" onSubmit={e => this.submitForm(e)}>
          <Input
            type="text"
            label="Reel Name"
            onChange={e => this.onChangeName(e)}
            value={this.state.name}
            required
            errorMessage={this.state.errorNameMessage}
            isError={this.state.errorName}
            containerClassName=""
            autoFocus
          />
          <div className="restrictions">
            <p className="title">Reel Restrictions</p>
            <div className="column-container">
              <div className="column">
                <div className="heading">Height</div>
                {reel && (
                  <div className="text">
                    {reel && reel.restrictions && reel.restrictions.height
                      ? `${reel.restrictions.height}${lengthUnit}`
                      : 'N/A'}
                  </div>
                )}
              </div>
              <div className="column">
                <div className="heading">Width</div>
                {reel && (
                  <div className="text">
                    {reel && reel.restrictions && reel.restrictions.width
                      ? `${reel.restrictions.width}${lengthUnit}`
                      : 'N/A'}
                  </div>
                )}
              </div>
              <div className="column">
                <div className="heading">Weight</div>
                {reel && (
                  <div className="text">
                    {reel && reel.restrictions && reel.restrictions.weight
                      ? `${reel.restrictions.weight}${weightUnit}`
                      : 'N/A'}
                  </div>
                )}
              </div>
            </div>
            {reel && reel.simpull && (
              <span className="simpull-text">
                <SimPull key="simpull" /> Reel
              </span>
            )}
          </div>
          <div className="edit-btns">
            <Button
              className="secondary"
              onClick={() => this.props.history.goBack()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="submit"
              disabled={!this.isFormValid() || this.props.saving}
              saving={this.props.saving}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default EditReelDisplay;
