import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from '../utils/dateFormat';
import NoColumnValue from '../patterns/NoColumnValue';
import Overflow from '../patterns/Overflow';

const JobSummaryHeader = props => {
  const { projectId, jobId, job, jobMetaActions, onDeleteJob } = props;
  const configurable = job && !job.quoteRequested;
  const baseUrl = `/projects/${projectId}/jobs/${jobId}`;

  return (
    <div className="job-summary-header page-title secondary-title">
      <div className="header-row">
        <h2>{job.name}</h2>
        <div className="btn-container">{jobMetaActions}</div>
      </div>

      <div className="job-details">
        <dl>
          <dt>Created</dt>
          <dd>{job.dateCreated && dateFormat.mmddyy(job.dateCreated)}</dd>
          <dt>Last Modified</dt>
          <dd>
            {job.dateModified ? (
              dateFormat.mmddyy(job.dateModified)
            ) : (
              <NoColumnValue />
            )}
          </dd>
          <dt>Job Type</dt>
          <dd>{job.jobType || '600V'}</dd>
        </dl>

        <Overflow>
          {configurable && (
            <li>
              <Link to={`${baseUrl}/settings`}>Edit Settings</Link>
            </li>
          )}

          <li>
            <Link
              to={{
                pathname: `${baseUrl}/duplicate`,
                state: { job }
              }}
            >
              Duplicate Job
            </Link>
          </li>

          {configurable && (
            <li className="actionable" onClick={onDeleteJob}>
              Delete Job
            </li>
          )}
        </Overflow>
      </div>
    </div>
  );
};

export default JobSummaryHeader;
