import React from 'react';

class Toggle extends React.Component {
  render() {
    const { containerName, name, disclaimer, info, ...rest } = this.props;

    return (
      <div className="checkbox-toggle" id={containerName}>
        <input type="checkbox" {...rest} />
        <label />
        <div className="name">{name}</div>
        {disclaimer ? (
          <div className="disclaimer">
            {info ? <div className="info" /> : null}
            {disclaimer}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Toggle;
