import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import dateFormat from '../utils/dateFormat';
import Breadcrumbs from '../patterns/Breadcrumbs';
import Overflow from '../patterns/Overflow';
import NoColumnValue from '../patterns/NoColumnValue';
import { showDeleteJobModal } from '../actions/ModalActions';
import withNotFoundRedirect from '../common/withNotFoundRedirect';
import Table from '../patterns/Table';
import _ from 'lodash';

export class ProjectSummary extends React.Component {
  render() {
    const projectId = this.props.match.params.projectId || false;
    const project =
      this.props.projects && projectId ? this.props.projects[projectId] : {};

    return (
      <div className="project">
        <div className="action-heading">
          <Breadcrumbs type="projects" />
          <Link className="action" to={`/projects/${projectId}/jobs/create`}>
            Create Job
          </Link>
        </div>

        <div className="page-title lb">
          <div className="job-details">
            <h2>{project.name}</h2>

            <Overflow>
              <li>
                <Link to={`/projects/${projectId}/settings`}>
                  Edit Settings
                </Link>
              </li>
            </Overflow>
          </div>

          <dl>
            <dt>Created</dt>
            <dd>
              {project.dateCreated && dateFormat.mmddyy(project.dateCreated)}
            </dd>
          </dl>
        </div>

        {this.renderJobs()}
      </div>
    );
  }

  renderJobs() {
    const { jobs } = this.props;

    if (jobs === undefined) {
      return null;
    }

    if (!this.hasJobs()) {
      return (
        <div className="empty-container">
          <img src="/assets/img/no-jobs.svg" width="715" height="414" alt=" " />
          <p className="important-message">You haven’t created any jobs yet.</p>
        </div>
      );
    }

    const columns = [
      { field: 'job-name', label: 'Job Name' },
      { field: 'created', label: 'Created' },
      { field: 'modified', label: 'Modified' },
      { field: 'job-type', label: 'Job Type' },
      { field: 'circuits', label: 'Circuits' },
      { field: 'reels', label: 'Reels' },
      { field: 'quote-sent', label: 'Quote Sent' },
      { field: 'expand-menu' }
    ];

    const data = Object.keys(jobs)
      .map(id => ({ ...jobs[id], id }))
      .filter(job => job.visible || !job.hasOwnProperty('visible'))
      .sort((a, b) => (b.dateCreated || 0) - (a.dateCreated || 0))
      .map(this.renderRow);

    return (
      <Table
        data={data}
        columns={columns}
        id="reel-list-table"
        className="many-columns"
      />
    );
  }

  renderRow = job => {
    const configurable = !job.quoteRequested;
    const match = this.props.match;

    return {
      row: (
        <tr
          key={job.id}
          className="link"
          onClick={e => this.goToJob(e, job.id)}
        >
          <td>
            <Link to={`${match.url}/jobs/${job.id}/summary/feeder`}>
              {job.name}
            </Link>
          </td>
          <td>
            {job.dateCreated ? (
              dateFormat.mmddyyyy(job.dateCreated)
            ) : (
              <NoColumnValue />
            )}
          </td>
          <td>
            {job.dateModified ? (
              dateFormat.mmddyyyy(job.dateModified)
            ) : (
              <NoColumnValue />
            )}
          </td>
          <td>{job.jobType === 'MV' ? 'MV' : '600V'}</td>
          <td>{job.circuits ? Object.keys(job.circuits).length : 0}</td>
          <td>{this.getReelCount(job)}</td>
          <td>
            {job.quoteRequested ? (
              <span className="quote-submitted" />
            ) : (
              <NoColumnValue />
            )}
          </td>
          <td className="overflow-cell">
            {
              <Overflow>
                {configurable && (
                  <li>
                    <Link to={`${match.url}/jobs/${job.id}/settings`}>
                      Edit Settings
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to={{
                      pathname: `${match.url}/jobs/${job.id}/duplicate`,
                      state: { job }
                    }}
                  >
                    Duplicate Job
                  </Link>
                </li>
                {configurable && (
                  <li
                    className="actionable"
                    onClick={e => this.deleteJob(e, job.id)}
                  >
                    Delete Job
                  </li>
                )}
              </Overflow>
            }
          </td>
        </tr>
      )
    };
  };

  /**
   * Get an accurate reel count for each job, depending on the job type.
   *
   * Since older jobs won't have the jobType property, we should first check
   * if the jobType === 'MV' and if check fails (or if no jobType is defined)
   * we can fallback to 600V.
   *
   * @param  {Object} job
   * @return {Number} count
   */
  getReelCount(job) {
    if (job.jobType === 'MV') {
      let count = 0;
      _.each(job.circuits, circuit => {
        if (circuit.ground) {
          count++;
        }

        if (circuit.reel && circuit.reel.products) {
          count += circuit.reel.products.length;
        }
      });

      return count;
    }

    return job.reels ? job.reels.length : 0;
  }

  deleteJob(e, id) {
    this.props.showDeleteJobModal({
      jobId: id,
      projectId: this.props.match.params.projectId
    });
  }

  goToJob(e, id) {
    if (
      e.target.tagName === 'A' ||
      e.target.classList.contains('overflow') ||
      e.target.classList.contains('actionable')
    ) {
      return;
    }

    const job = this.props.jobs[id];
    this.props.history.push(
      `/projects/${job.projectId}/jobs/${id}/summary/feeder`,
      null
    );
  }

  hasJobs() {
    const { jobs } = this.props;

    if (jobs === null) {
      return false;
    }

    return Object.keys(jobs).reduce((accumulator, current) => {
      return accumulator || jobs[current].visible;
    }, false);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showDeleteJobModal: deleteJobObj => {
      dispatch(showDeleteJobModal(deleteJobObj));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(
    ({ firebase: { auth }, firestore: { data } }, props) => ({
      projects: data.projects,
      jobs: data[`jobs-${props.match.params.projectId}`]
    }),
    mapDispatchToProps
  ),
  withNotFoundRedirect('projectId', 'projects')
)(ProjectSummary);
