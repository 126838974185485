import ActionTypes from '../constants/ActionTypes.js';

export const changeSelectedReel = index => {
  return {
    type: ActionTypes.CHANGE_SELECTED_REEL,
    index: index
  };
};

export const resetOverflowCloseFunction = () => {
  return {
    type: ActionTypes.RESET_OVERFLOW_CLOSE_FUNCTION
  };
};

export const setOverflowCloseFunction = overflowCloseFunction => {
  return {
    type: ActionTypes.SET_OVERFLOW_CLOSE_FUNCTION,
    overflowCloseFunction: overflowCloseFunction
  };
};
