import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';
import cloneDeep from 'lodash/cloneDeep';
import Modal from './Modal';

export class RemoveCircuitFromReel extends Component {
  state = { saving: false };

  onClickRemove = () => {
    this.setState({ saving: true });
    let removeCircuitObj = this.props.payload;
    let reels =
      this.props.jobs[removeCircuitObj.jobId] &&
      cloneDeep(this.props.jobs[removeCircuitObj.jobId].reels);

    reels[removeCircuitObj.reelId] = removeCircuitObj.reel;

    this.props.firestore
      .update(`jobs/${removeCircuitObj.jobId}`, {
        reels
      })
      .then(() => {
        this.setState({ saving: false });
        this.props.onCancel();
      })
      .catch(() => {
        this.setState({ saving: false });
        this.props.onCancel();
        this.props.showToast(
          'Circuit could not be removed. Please try again later.',
          'error'
        );
      });
  };

  render() {
    return (
      <Modal
        loading={this.state.saving}
        message="Are you sure you want to remove this circuit from the reel?"
        onCancel={this.props.onCancel}
        onConfirm={this.onClickRemove}
        confirmText="Remove"
      />
    );
  }
}

const mapStateToProps = ({ firestore: { data } }, props) => ({
  jobs: data[`jobs-${props.payload.projectId}`]
});

const mapDispatchToProps = dispatch => ({
  showToast: (message, type) => {
    dispatch(showToast(message, type));
  }
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RemoveCircuitFromReel);
