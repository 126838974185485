import React from 'react';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import Logo from '../common/Logo';
import Dropdown from '../patterns/Dropdown';

import * as regions from '../utils/states';
import { showToast, hideToast } from '../actions/ToastActions';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

const SOUTHWIRE = 'southwire';
const SOUTHWIRE_EMAIL = '@southwire.com';
const SOUTHWIRE_EMAIL_ALT = '+sw@mutualmobile.com';
const METRIC = 'metric';

const PARDOT_SUBMIT_URL = 'http://go.pardot.com/l/151881/2018-03-29/knwcff';
const FIRST_NAME_INPUT = 'First Name';
const LAST_NAME_INPUT = 'Last Name';
const REGISTRATION_FORM_TARGET_ID = 'registration-form-pardot';

const splitName = name => {
  const words = (name || '').split(' ');
  const lastName = words[words.length - 1];
  words.splice(words.length - 1, 1);
  const firstName = words.join(' ');
  return { first: firstName, last: lastName };
};

const addInput = (form, name, value) => {
  if (!form || !(form instanceof HTMLFormElement)) {
    throw new Error('Must specify a form');
  }
  if (!name || typeof name !== 'string') {
    throw new Error('Must specify a name');
  }

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value || '';
  form.appendChild(input);
};

const initialState = {
  email: '',
  password: '',
  confirm: '',
  name: '',
  company: '',
  role: '',
  roleDisabled: false,
  city: '',
  state: '',
  zip: '',
  phone: '',
  units: 'standard',
  emailError: false,
  passwordError: false,
  saving: false
};

export class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    // if profile already loaded, will not receive new props
    const { auth, profile } = props;
    if (profile && !profile.isEmpty) {
      this.state = {
        email: '',
        password: '',
        confirm: '',
        name: profile.name,
        company: profile.company,
        role: profile.role,
        roleDisabled: this.isSouthwireEmployee(auth.email),
        city: profile.city,
        state: profile.state,
        zip: profile.zip,
        phone: profile.phoneNumber,
        units: profile.metric ? 'metric' : 'standard',
        emailError: false,
        passwordError: false,
        saving: false
      };
    }
  }
  componentDidMount() {
    // if user has just been created, and returns to /register, log them out
    const { auth } = this.props;
    let loggedIn = auth && auth.isLoaded && auth.uid;
    if (this.props.match && this.props.match.url === '/register' && loggedIn) {
      this.setState(initialState);
      this.props.firebase.logout().then(() => {
        this.props.dispatch({
          type: '@@reduxFirestore/CLEAR_DATA'
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.profile !== this.props.profile &&
      typeof nextProps.profile === 'object' &&
      !nextProps.profile.isEmpty
    ) {
      const { profile, auth } = nextProps;
      this.setState({
        email: '',
        password: '',
        confirm: '',
        name: profile.name,
        company: profile.company,
        role: profile.role,
        roleDisabled: this.isSouthwireEmployee(auth.email),
        city: profile.city,
        state: profile.state,
        zip: profile.zip,
        phone: profile.phoneNumber,
        units: profile.metric ? 'metric' : 'standard',
        emailError: false,
        passwordError: false
      });
    }
  }

  errorMessage() {
    if (this.state.message) {
      return (
        <div className="error-message fade-in">
          <div className="alert-icon" />
          {this.state.message}
        </div>
      );
    }
    return null;
  }

  getRegionOptions = () => {
    const states = Object.keys(regions.states).map(key => ({
      value: key,
      label: regions.states[key]
    }));

    const provinces = Object.keys(regions.provinces).map(key => ({
      value: key,
      label: regions.provinces[key]
    }));

    return [...states, ...provinces];
  };

  render() {
    let editing = this.props.edit;
    let creating = !editing;
    let submitFn = creating
      ? e => {
          this.createAccount(e);
        }
      : e => {
          this.updateAccount(e);
        };

    return (
      <div
        className={
          editing ? 'wrapper create-account edit' : 'wrapper create-account'
        }
      >
        {creating && <Logo />}
        <div className="home">
          {creating && this.errorMessage()}
          {creating && <h2>Account Creation</h2>}
          <p className="informational-heading">
            Fields marked with an asterisk are required.
          </p>
          <form id="account-form" onSubmit={submitFn}>
            {creating && (
              <Input
                type="text"
                label="Email"
                onChange={e => {
                  this.updateEmail(e);
                }}
                value={this.state.email}
                className={this.state.emailError ? 'error-border' : null}
                required
                maxLength="59"
                autoComplete="email"
              />
            )}

            {creating && (
              <Input
                type="password"
                label="Password"
                onChange={e => {
                  this.updatePassword(e, 'password');
                }}
                value={this.state.password}
                className={this.state.passwordError ? 'error-border' : null}
                required
                autoComplete="new-password"
              />
            )}

            {creating && (
              <Input
                type="password"
                label="Confirm Password"
                onChange={e => {
                  this.updatePassword(e, 'confirm');
                }}
                value={this.state.confirm}
                className={this.state.passwordError ? 'error-border' : null}
                required
                autoComplete="new-password"
              />
            )}

            <Input
              type="text"
              label="Name"
              onChange={e => {
                this.updateField(e, 'name');
              }}
              value={this.state.name}
              required
              maxLength="59"
              autoComplete="name"
            />

            <Input
              type="text"
              label="Company Name"
              onChange={e => {
                this.updateField(e, 'company');
              }}
              value={this.state.company}
              required
              maxLength="59"
              autoComplete="organization"
            />

            <Dropdown
              required
              id="role"
              label="Role"
              value={this.state.role}
              placeholder="Please Select"
              disabled={this.state.roleDisabled}
              onSelect={(value, event) => {
                this.updateField({ target: { value } }, 'role');
              }}
              options={[
                { value: 'southwire', label: 'Southwire Employee' },
                { value: 'distributor', label: 'Distributor' },
                { value: 'contractor', label: 'Contractor' },
                { value: 'sales', label: 'Sales/Agent' },
                { value: 'other', label: 'Other' }
              ]}
            />

            <Input
              type="text"
              label="City"
              onChange={e => {
                this.updateField(e, 'city');
              }}
              value={this.state.city}
              required
              maxLength="59"
              autoComplete="address-level2"
            />

            <Dropdown
              required
              id="state"
              label="State/Province"
              value={this.state.state}
              placeholder="Please Select"
              options={this.getRegionOptions()}
              onSelect={(value, event) => {
                this.updateField({ target: { value } }, 'state');
              }}
            />

            <Input
              type="text"
              label="Zip"
              onChange={e => {
                this.updateZip(e);
              }}
              value={this.state.zip}
              maxLength="7"
              autoComplete="postal-code"
            />
            <Input
              type="text"
              label="Phone Number"
              onChange={e => {
                this.updateField(e, 'phone');
              }}
              value={this.state.phone}
              maxLength="16"
              autoComplete="tel"
            />

            <Dropdown
              required
              id="units"
              value={this.state.units}
              label="Unit of Measure"
              placeholder="Please Select"
              onSelect={(value, event) => {
                this.updateField({ target: { value } }, 'units');
              }}
              options={[
                { value: 'standard', label: 'Standard' },
                { value: METRIC, label: 'Metric' }
              ]}
            />

            {creating && (
              <Button
                type="submit"
                className="submit"
                saving={this.state.saving}
                disabled={!this.isFormValid() || this.state.saving}
              >
                Submit
              </Button>
            )}
            {creating && (
              <Button
                className="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Cancel
              </Button>
            )}
            {editing && (
              <div className="edit-btns">
                <Button
                  className="secondary"
                  onClick={() => this.props.history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="submit"
                  saving={this.state.saving}
                  disabled={!this.isFormValid() || this.state.saving}
                >
                  Save
                </Button>
              </div>
            )}
          </form>
        </div>

        {creating && (
          <iframe
            id={REGISTRATION_FORM_TARGET_ID}
            name={REGISTRATION_FORM_TARGET_ID}
            title={REGISTRATION_FORM_TARGET_ID}
            style={{ display: 'none' }}
          />
        )}
      </div>
    );
  }

  isMatchingPassword() {
    return this.state.password && this.state.confirm === this.state.password;
  }
  isPasswordValid() {
    return this.state.password && this.state.password.length > 5;
  }

  isFormValid() {
    let editing = this.props.edit;
    return (
      (editing || this.state.email) &&
      (editing || this.state.email.trim().length) &&
      (editing || this.state.password) &&
      (editing || this.state.confirm) &&
      (editing || (this.state.password && this.state.confirm)) &&
      this.state.name &&
      this.state.name.trim().length &&
      this.state.company &&
      this.state.company.trim().length &&
      this.state.role &&
      this.state.role.trim().length &&
      this.state.city &&
      this.state.city.trim().length &&
      this.state.state &&
      this.state.state.trim().length &&
      this.isValidZip() &&
      this.isValidPhone() &&
      this.state.units &&
      this.state.units.trim().length
    );
  }
  updateAccount(e) {
    e.preventDefault();
    this.setState({ saving: true });
    const userKey = this.props.auth && this.props.auth.uid;
    const newObj = {
      name: this.state.name.trim(),
      phoneNumber: this.state.phone.replace(/\D/g, ''),
      company: this.state.company.trim(),
      role: this.state.role,
      city: this.state.city.trim(),
      state: this.state.state,
      zip: this.state.zip,
      metric: this.state.units === METRIC,
      country: regions.states[this.state.state] ? 'us' : 'ca'
    };
    if (userKey) {
      this.props.firebase
        .update(`/users/${userKey}`, newObj)
        .then(user => {
          this.setState({ saving: false });
          this.props.showToast('Account successfully updated.', 'success');
        })
        .catch(error => {
          this.setState({ saving: false });
          console.error(error.message);
          this.props.showToast(
            'Account could not be updated.',
            'error-persist'
          );
        });
    }
  }

  handleVerify() {
    // continue URL logs you in and routes you to /projects
    const actionCodeSettings = {
      url: window.location.origin,
      handleCodeInApp: false
    };
    this.props.firebase
      .auth()
      .currentUser.sendEmailVerification(actionCodeSettings)
      .then(() => {
        this.props.showToast(
          [
            'Email verification has been re-sent. Please check your email and follow the link to verify your account.',
            <span
              key="resend"
              className="resend-link"
              onClick={() => {
                this.handleVerify();
              }}
            >
              Resend verification email.
            </span>,
            <div
              key="exit"
              className="exit-icon"
              onClick={() => {
                this.hideToast();
              }}
            />
          ],
          'success-persist',
          true
        );
      });
  }

  hideToast() {
    this.props.hideToast();
  }

  createAccount(e) {
    e.preventDefault();
    if (!this.isPasswordValid()) {
      this.setState({
        message: 'Password must be 6 or more characters.',
        passwordError: true
      });
      return false;
    }
    if (!this.isMatchingPassword()) {
      this.setState({
        message: 'Your passwords don’t match.',
        passwordError: true
      });
      return false;
    }
    if (this.isFormValid()) {
      let email = this.state.email.trim();
      let password = this.state.password;
      const props = this.props;
      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: false
      };
      this.setState({ saving: true });
      props.firebase
        .createUser(
          {
            email: email,
            password: password
          },
          {
            name: this.state.name.trim(),
            phoneNumber: this.state.phone.replace(/\D/g, ''),
            company: this.state.company.trim(),
            role: this.state.role,
            city: this.state.city.trim(),
            state: this.state.state,
            zip: this.state.zip,
            metric: this.state.units === METRIC,
            country: regions.states[this.state.state] ? 'us' : 'ca'
          }
        )
        .then(user => {
          this.setState({ saving: false });
          this.props.firebase
            .auth()
            .currentUser.sendEmailVerification(actionCodeSettings)
            .catch(error => {
              console.error('email verification not sent', error);
            });
          this.handleLeads(email);
          this.props.showToast(
            [
              'Email verification sent. Please check your email and follow the link to verify your account.',
              <span
                key="resend"
                className="resend-link"
                onClick={() => {
                  this.handleVerify();
                }}
              >
                Resend verification email.
              </span>,
              <div
                key="exit"
                className="exit-icon"
                onClick={() => {
                  this.hideToast();
                }}
              />
            ],
            'success-persist',
            true
          );
          this.props.history.push('/login', { created: true });
        })
        .catch(error => {
          this.setState({ saving: false });
          if (error.code === 'auth/invalid-email') {
            this.setState({
              message: 'This email format is incorrect.',
              emailError: true
            });
          } else if (error.code === 'auth/email-already-in-use') {
            this.setState({
              message: 'This email address is already in use.',
              emailError: true
            });
          } else if (error.code === 'auth/network-request-failed') {
            this.setState({
              message: 'Please check your network connection.'
            });
          }
        });
    }
  }

  updateEmail(e) {
    let email = e.target.value;
    if (this.isSouthwireEmployee(email)) {
      this.setState({
        email,
        role: SOUTHWIRE,
        roleDisabled: true,
        emailError: false
      });
    } else if (this.state.roleDisabled) {
      let state = {
        roleDisabled: false,
        emailError: false,
        email
      };
      if (this.state.role === SOUTHWIRE) {
        state.role = '';
      }
      this.setState(state);
    } else {
      this.setState({
        email,
        emailError: false
      });
    }
  }

  updatePassword(e, name) {
    this.setState({
      [name]: e.target.value,
      passwordError: false
    });
  }
  updateField(e, name) {
    this.setState({ [name]: e.target.value });
  }
  updateZip(e) {
    let zip = e.target.value;
    const pat = new RegExp('^[a-zA-Z0-9 ]{0,7}$'); //7 chars alpha-numeric with space (US Zip code and CA postal code)
    let valid = pat.test(zip);
    if (valid) {
      this.setState({ zip: e.target.value });
    }
  }

  isValidPhone() {
    const phone = this.state.phone.replace(/\D/g, '');
    if (
      !phone ||
      phone.length === 0 ||
      (phone.length === 10 || (phone.length === 11 && phone[0] === '1'))
    ) {
      return true;
    }
    return false;
  }

  isValidZip() {
    const zip = this.state.zip.replace(' ', '');
    if (!zip || zip.length === 0 || zip.length === 5 || zip.length === 6) {
      return true;
    }
    return false;
  }

  isSouthwireEmployee(email) {
    return (
      email &&
      (email.indexOf(SOUTHWIRE_EMAIL) > -1 ||
        email.indexOf(SOUTHWIRE_EMAIL_ALT) > -1)
    );
  }

  handleLeads(email) {
    // Create a hidden copy of the form, using only the data relevant to Pardot
    const pardotForm = document.createElement('form');
    pardotForm.style.display = 'none';
    pardotForm.method = 'POST';
    // Aim the clone at the hidden IFrame and Pardot URL
    pardotForm.target = REGISTRATION_FORM_TARGET_ID;
    pardotForm.action = PARDOT_SUBMIT_URL;

    const names = splitName(this.state.name);
    addInput(pardotForm, FIRST_NAME_INPUT, names.first);
    addInput(pardotForm, LAST_NAME_INPUT, names.last);
    addInput(pardotForm, 'Email', email);
    addInput(pardotForm, 'Phone', this.state.phone.replace(/\D/g, ''));
    addInput(pardotForm, 'Company', this.state.company);
    addInput(pardotForm, 'Role', this.state.role);
    addInput(pardotForm, 'City', this.state.city);
    addInput(pardotForm, 'State', this.state.state);
    addInput(pardotForm, 'Zip', this.state.zip);

    function onLoadPardotFrame(e) {
      e.currentTarget.removeEventListener('load', onLoadPardotFrame);
      // Handle any cleanup tasks
      if (pardotForm && pardotForm.parentElement) {
        pardotForm.parentElement.removeChild(pardotForm);
      }
    }
    document
      .getElementById(REGISTRATION_FORM_TARGET_ID)
      .addEventListener('load', onLoadPardotFrame);
    document.body.appendChild(pardotForm);
    pardotForm.submit();
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType, passToNewRoute) => {
      dispatch(showToast(message, messageType, passToNewRoute));
    },
    hideToast: () => dispatch(hideToast()),
    dispatch: () => dispatch
  };
};

export default compose(
  firestoreConnect(),
  connect(
    state => ({
      profile: state.firebase.profile, // pass profile data as this.props.profile
      auth: state.firebase.auth // pass auth data as this.props.auth
    }),
    mapDispatchToProps
  )
)(CreateAccount);
