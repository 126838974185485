import React from 'react';
import { connect } from 'react-redux';
import {
  setOverflowCloseFunction,
  resetOverflowCloseFunction
} from '../actions/AppActions';

export class Overflow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: true
    };
  }

  toggleOverflow = () => {
    // run any overflow close methods
    if (this.props.overflowCloseFunction) {
      this.props.overflowCloseFunction();
      this.props.resetOverflowCloseFunction();
    }

    // open overflow menu
    if (this.state.hide) {
      this.setState({ hide: false });
      // load bound close function into app state
      this.props.setOverflowCloseFunction(() => this.setState({ hide: true }));
    }
  };

  render() {
    return (
      <div className="overflow" onClick={this.toggleOverflow}>
        <div className={this.state.hide ? 'options' : 'options fadeIn'}>
          <ul>{this.props.children}</ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    overflowCloseFunction:
      state.appState && state.appState.overflowCloseFunction
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setOverflowCloseFunction: overflowCloseFunction => {
      dispatch(setOverflowCloseFunction(overflowCloseFunction));
    },
    resetOverflowCloseFunction: () => {
      dispatch(resetOverflowCloseFunction());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overflow);
