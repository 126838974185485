import ActionTypes from '../constants/ActionTypes.js';

export const hideToast = () => {
  return {
    type: ActionTypes.HIDE_TOAST
  };
};
export const showToast = (message, messageType, passToNewRoute = false) => {
  return {
    type: ActionTypes.SHOW_TOAST,
    message,
    messageType,
    passToNewRoute
  };
};
export const resetToastPass = () => {
  return {
    type: ActionTypes.RESET_TOAST_PASS
  };
};
