import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';

import Modal from './Modal';

export class DeleteJob extends Component {
  state = { saving: false };

  onClickDelete = () => {
    this.setState({ saving: true });
    let deleteJobObj = this.props.payload;

    this.props.firestore
      .update(`jobs/${deleteJobObj.jobId}`, {
        visible: false
      })
      .then(() => {
        // success
        this.setState({ saving: false });
        this.props.onCancel();
        // routeAfterDelete is only passed down from JobSummary,
        // reroute only if routeAfterDetele exists so error is not thrown when deleting from project summary
        if (deleteJobObj.routeAfterDelete) {
          deleteJobObj.routeAfterDelete();
        }
      })
      .catch(error => {
        this.setState({ saving: false });
        // ? FIXME: Toast / messaging component. SRC-428
        this.props.showToast('Job could not be deleted.', 'error-persist');
      });
  };

  render() {
    return (
      <Modal
        loading={this.state.saving}
        message="Are you sure you want to delete this job?"
        onCancel={this.props.onCancel}
        onConfirm={this.onClickDelete}
        confirmText="Delete"
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    }
  };
};

export default compose(
  firestoreConnect(),
  connect(
    null,
    mapDispatchToProps
  )
)(DeleteJob);
