import React from 'react';
import Button from '../patterns/Button';
import { showToast } from '../actions/ToastActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

/**
 * The final confirmation page when requesting an MV quote.
 *
 * Provide a list of email addresses that will be notified once
 * Southwire has the quote info.
 */
export class MVSubmitRFQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailList: '',
      error: false,
      saving: false
    };
  }

  /**
   * Check if any of the provided emails are invalid (using a very simple regex expression).
   *
   * Regex is validating:
   *   - some characters before the @ sign
   *   - an @ sign is present
   *   - some characters after the @ sign
   *   - a period is present
   *   - some characters after the period
   *
   * @param  {String} emailList
   * @return {Boolean}
   */
  hasError = emailList => {
    const emails = emailList.split(',').filter(s => s);

    return emails.some(email => {
      return !/^.+@.+\..+$/.test(email);
    });
  };

  /**
   * onChange handler for the email list input field.
   *
   * @param {Object} event
   */
  onEmailListChange = event => {
    const emailList = event.target.value;
    const error = this.hasError(emailList);

    this.setState({
      error,
      emailList
    });
  };

  /**
   * Add a new record to the mvEmails collection.
   * This collection is being monitored by a cloud function
   * that will process and send an email when a new document is added.
   *
   * @return {Promise}
   */
  submitRFQ = () => {
    const { projectId, jobId } = this.props.match.params;
    const emailList = this.state.emailList.split(',').filter(s => s);
    const { email: userEmail } = this.props.auth;
    const { state } = this.props.profile;

    const emailDocument = {
      jobId,
      projectId,
      userEmail,
      state,
      viewers: emailList,
      emailSent: false,
      error: '',
      dateCreated: new Date().getTime()
    };

    return this.props.firestore.add({ collection: 'mvEmails' }, emailDocument);
  };

  /**
   * Mark the job as having successfully requested a quote.
   *
   * @return {Promise}
   */
  markJobAsQuoteRequested = () => {
    const { jobId } = this.props.match.params;
    const job = {
      quoteRequested: new Date().getTime()
    };

    return this.props.firestore.update(`jobs/${jobId}`, job);
  };

  /**
   * Format and submit the quote.
   */
  onSubmit = () => {
    const { jobId } = this.props.match.params;
    if (!jobId) {
      return;
    }

    this.setState({ saving: true });

    this.submitRFQ()
      .then(this.markJobAsQuoteRequested)
      .then(() => {
        this.setState({ saving: false });
        this.props.showToast(
          'Your quote was sent. You will be contacted when pricing is available.',
          'success',
          true
        );
        this.props.history.goBack();
      })
      .catch(err => {
        this.setState({ saving: false });
        this.props.showToast('Error sending quote', 'error');
      });
  };

  render() {
    const { emailList, error, saving } = this.state;

    return (
      <div className="viewers">
        <div className="lb">
          <div className="viewers-container">
            <h2 className="page-title">Request a Quote</h2>
            <div className="instructions">
              Once submitted, your assigned Regional Southwire Account
              Management Team will receive the requested quote. You will be
              contacted shortly.
              <br />
              <br />
              To notify others that the quote has been requested, enter valid
              email addresses below, separated by commas.
            </div>

            <div className="email-area">
              <textarea
                value={emailList}
                onChange={this.onEmailListChange}
                placeholder="Enter Email Addresses (optional)"
                className={error ? 'error-border' : ''}
              />

              <div className="field-messages">
                <div
                  className={`field-alert delay-alert ${
                    error ? 'alerted' : ''
                  }`}
                >
                  Invalid email address
                </div>
              </div>

              <p className="textarea-label">
                All recipients will be visible in the notification email.
              </p>
            </div>
          </div>
        </div>

        <div className="button-container">
          <Button className="secondary" onClick={this.props.history.goBack}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="submit"
            id="submit-rfq-button"
            onClick={this.onSubmit}
            saving={saving}
            disabled={error || saving}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { projectId } = props.match.params;
  const { firebase, firestore } = state;
  const { auth, profile } = firebase;
  const { data } = firestore;

  return {
    auth,
    profile,
    projects: data.projects,
    jobs: data[`jobs-${projectId}`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType, passToNewRoute) => {
      dispatch(showToast(message, messageType, passToNewRoute));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    const { projectId } = props.match.params;
    const userId = store.firebase.auth().currentUser.uid;

    return [
      {
        collection: 'projects',
        where: ['userId', '==', userId]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${projectId}`,
        where: [['projectId', '==', projectId], ['userId', '==', userId]]
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MVSubmitRFQ);
