import React from 'react';

class ConductorDetail extends React.Component {
  render() {
    const colorsMap = this.props.catalog && this.props.catalog.colorsMap;
    let color = this.props.color;
    let size = this.props.size;
    let colorCode = colorsMap[color]['rgb']
      ? `rgb(${colorsMap[color]['rgb']})`
      : color;

    return (
      <div className="conductor-detail" {...this.props}>
        <div className="dot" style={{ backgroundColor: colorCode }} />
        <div className="conductor-label">
          {color}
          <span>Size: {size}</span>
        </div>
      </div>
    );
  }
}

export default ConductorDetail;
