import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Modal from './Modal';
import Input from '../patterns/Input';
import Dropdown from '../patterns/Dropdown';
import SimPullHead from '../patterns/SimPullHead';
import productFinder from '../utils/productFinder';
import convertUnits from '../utils/convertUnits';
import assignReels from '../utils/MVReelAssignment';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Mass edit the selected MV circuits.
 */
export class EditMVCircuits extends Component {
  state = {
    saving: false,
    from: '',
    to: '',
    length: '',
    simpull: 'no-change',
    exceedsCapacityError: false,
    confirmError: false
  };

  /**
   * Change generic field event handler.
   *
   * @param  {String} field - the field you want to update in state
   * @return {Function}     - event handler function
   */
  onChangeField = field => {
    return event => {
      this.setState({
        [field]: event.target.value
      });
    };
  };

  /**
   * Change length event handler.
   *
   * @param {Object} event
   */
  onChangeLength = event => {
    const length = parseFloat(event.target.value) || '';

    this.setState({
      length,
      exceedsCapacityError: false
    });
  };

  /**
   * Change simpull dropdown event handler.
   *
   * @param {Boolean} simpull
   */
  onChangeSimpull = simpull => {
    this.setState({ simpull });
  };

  /**
   * When the user submits the mass edit circuits form.
   */
  onConfirm = () => {
    const { job, catalog } = this.props;
    const { jobId, circuitIds, onSuccess } = this.props.payload;
    const { from, to, length, simpull } = this.state;
    const now = Date.now();

    this.setState({ saving: true, confirmError: false });

    const updatedCircuits = {};
    for (let i = 0; i < circuitIds.length; i++) {
      const id = circuitIds[i];
      const circuit = cloneDeep(job.circuits[id]);
      if (!circuit) {
        continue;
      }

      const updatedCircuit = {
        ...circuit,
        ...(from && { from }),
        ...(to && { to }),
        ...(length && { length }),
        dateModified: now - i
      };

      if (simpull !== 'no-change') {
        updatedCircuit.simpull = simpull === 'yes';
      }

      // If the user updated the length, we need to re-calculate
      // the weight and volume of the circuit.
      if (length !== '') {
        updatedCircuit.weight = this.getWeight(updatedCircuit);
        updatedCircuit.volume = this.getVolume(updatedCircuit);
      }

      const reelProducts = assignReels(
        catalog,
        updatedCircuit,
        job.restrictions
      );

      if (reelProducts === false) {
        this.setState({ saving: false, exceedsCapacityError: true });
        return null;
      }
      updatedCircuit.reel.products = reelProducts;

      updatedCircuits[id] = updatedCircuit;
    }

    const circuits = {
      ...job.circuits,
      ...updatedCircuits
    };

    this.props.firestore
      .update(`jobs/${jobId}`, { circuits })
      .then(() => {
        this.setState({ saving: false });
        this.props.onCancel();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(err => {
        this.setState({ saving: false, confirmError: true });
      });
  };

  /**
   * Get the updated weight for the given circuit.
   *
   * @param  {Object} circuit
   * @return {Number}
   */
  getWeight(circuit) {
    const product = this.getProduct(circuit);
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(circuit.length)
      : circuit.length;

    return (
      productFinder.getConductorWeight(product, lengthInFt) *
      circuit.numConductors
    );
  }

  /**
   * Get the updated volume for the given circuit.
   *
   * @param  {Object} circuit
   * @return {Number}
   */
  getVolume(circuit) {
    const product = this.getProduct(circuit);
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(circuit.length)
      : circuit.length;

    return (
      productFinder.getConductorVolume(product, lengthInFt) *
      circuit.numConductors
    );
  }

  /**
   * Get the product record for the given circuit.
   *
   * @param  {Object} circuit
   * @return {Object}
   */
  getProduct(circuit) {
    return productFinder.getMVProduct(
      this.props.catalog,
      circuit.metal,
      circuit.size,
      circuit.voltage,
      circuit.thickness,
      circuit.jacket
    );
  }

  /**
   * Disable submit when we're saving OR
   * when the form hasn't changed.
   *
   * @return {Boolean}
   */
  shouldDisableSubmit() {
    return (
      this.state.saving ||
      (this.state.from === '' &&
        this.state.to === '' &&
        this.state.length === '' &&
        this.state.simpull === 'no-change')
    );
  }

  /**
   * Return an error message, if appropriate.
   * @return {JSX}
   */
  getModalMessage() {
    const { exceedsCapacityError, confirmError } = this.state;

    if (confirmError) {
      return (
        <p className="message error-warning">
          We could not update the circuits as requested.
        </p>
      );
    }

    if (exceedsCapacityError) {
      return (
        <p className="message error-warning">
          Circuit length exceeds reel capacity.
        </p>
      );
    }

    return (
      <p className="message info-message">
        Fields left empty will keep existing values.
      </p>
    );
  }

  render() {
    const { circuitIds } = this.props.payload;
    const { job } = this.props;

    const count = circuitIds.length;
    const title = `Editing ${count} ${count > 1 ? 'Circuits' : 'Circuit'}`;

    return (
      <Modal
        title={title}
        className="edit-circuits-modal"
        loading={this.shouldDisableSubmit()}
        onCancel={this.props.onCancel}
        onConfirm={this.onConfirm}
      >
        <Input
          type="text"
          label="From"
          value={this.state.from}
          onChange={this.onChangeField('from')}
          maxLength="15"
        />
        <Input
          type="text"
          label="To"
          value={this.state.to}
          onChange={this.onChangeField('to')}
          maxLength="15"
        />

        <Input
          type="text"
          label="Length"
          placeholder={job.metric ? 'Meters' : 'Feet'}
          value={this.state.length}
          onChange={this.onChangeLength}
          maxLength="6"
        />
        <div>
          <Dropdown
            id="simpull"
            label={<SimPullHead />}
            value={this.state.simpull}
            placeholder="Please Select"
            onSelect={this.onChangeSimpull}
            options={[
              { value: 'no-change', label: 'Do Not Change' },
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' }
            ]}
          />
        </div>
        {this.getModalMessage()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const jobs = state.firestore.data[`jobs-${props.payload.projectId}`];
  const job = jobs[props.payload.jobId];
  const catalog = state.firestore.data.catalogs.mediumVoltage;
  const simreels = state.firestore.data.catalogs.simreels;
  return {
    job,
    catalog,
    simreels
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.payload.projectId}`,
        where: [
          ['projectId', '==', props.payload.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      { collection: 'catalogs' }
    ];
  }),
  connect(mapStateToProps)
)(EditMVCircuits);
