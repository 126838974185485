import React from 'react';

const EmptySwatch = ({ id, onChange }) => {
  return (
    <div className="conductor-swatch">
      <span className="swatch empty">
        <input
          type="button"
          id={id}
          onClick={onChange}
          value=""
          className="swatch-color"
        />
      </span>
      <label htmlFor={id}>--</label>
    </div>
  );
};

export default EmptySwatch;
