import React from 'react';
import classNames from 'classnames';

const Tooltip = props => {
  const { text = null, caretPosition = 'right', isHovered = false } = props;

  const className = classNames('tooltip', caretPosition, {
    isHovered
  });

  return <div className={className}>{text}</div>;
};

export default Tooltip;
