import React from 'react';

const Radio = props => {
  const { label, checked, value, onChange, name, disabled = false } = props;

  return (
    <div className="radio-item">
      <input
        type="radio"
        checked={checked}
        id={`radio-item-${value}`}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`radio-item-${value}`}>{label}</label>
    </div>
  );
};
export default Radio;
