import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { showToast } from '../actions/ToastActions';
import HoverableText from '../patterns/HoverableText';
import Overflow from '../patterns/Overflow';
import Expand from '../patterns/Expand';
import SimPullHead from '../patterns/SimPullHead';
import ConductorDetail from '../patterns/ConductorDetail';
import EmptySmall from '../patterns/EmptySmall';
import IconButton from '../patterns/IconButton';
import NoColumnValue from '../patterns/NoColumnValue';
import reelColors from '../utils/reelColors';
import cloneDeep from 'lodash/cloneDeep';
import arrayMove from '../utils/arrayMove';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

import { showDeleteCircuitModal } from '../actions/ModalActions';

const TAB_ON_REEL = 'onReel';
const TAB_AVAILABLE = 'available';
const REEL_STANDARD_WIRE = 'standard';
const REEL_GROUND_WIRE = 'ground';

const DragHandle = SortableHandle(() => <td className="reorder-cell" />);

const SortableList = SortableContainer(
  ({
    ids,
    circuits,
    metric,
    expandedRows,
    removeFromReel,
    handleExpand,
    displayColors,
    reordering
  }) => {
    return (
      <tbody className={reordering ? 'currently-reordering' : ''}>
        {ids.map((info, index) => {
          let circuit = circuits[info.id];
          return (
            <SortableItem
              key={`sortable-${info.id}`}
              index={index}
              colorKey={info.colorKey}
              circuit={circuit}
              id={info.id}
              metric={metric}
              expandedRows={expandedRows}
              removeFromReel={removeFromReel}
              handleExpand={handleExpand}
              displayColors={displayColors}
              disableReorder={ids.length < 2}
            />
          );
        })}
      </tbody>
    );
  }
);

const SortableItem = SortableElement(
  ({
    id,
    circuit,
    metric,
    expandedRows,
    removeFromReel,
    handleExpand,
    displayColors,
    colorKey,
    disableReorder
  }) => {
    let expanded = expandedRows.indexOf(id) > -1 ? 'true' : null;
    const groundConductor = circuit.conductors.find(c => c.isGroundConductor);
    const isFromHoverable = circuit.from && circuit.from.length > 8;
    const isToHoverable = circuit.to && circuit.to.length > 8;

    const itemRows = [
      <tr key={id + TAB_ON_REEL}>
        <td
          className="from-cell"
          style={{
            backgroundImage: `linear-gradient(to right, ${
              reelColors[colorKey % reelColors.length]
            } 6px, white 6px)`
          }}
        >
          {circuit.from ? (
            <HoverableText text={circuit.from} isHoverable={isFromHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>
          {circuit.to ? (
            <HoverableText text={circuit.to} isHoverable={isToHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>{circuit.size}</td>
        <td>{`${circuit.length}${metric ? 'm' : "'"}`}</td>
        <td className="remove-cell">
          <IconButton
            onClick={function() {
              removeFromReel(id);
            }}
            type="remove-from-reel"
          />
        </td>
        <td className="expand-cell">
          <Expand
            onClick={function() {
              handleExpand(id);
            }}
            expanded={expanded}
          />
        </td>
        {disableReorder ? <td className="overflow-cell" /> : <DragHandle />}
      </tr>
    ];

    if (expanded) {
      itemRows.push(
        <tr key={id + 'i'}>
          <td key={'row-expanded-' + id} className="expanded-row">
            <div className="notch" />
            <div className="expanded-header">
              <span>
                Metal / Insulation: {circuit.metal} / {circuit.insulation}
              </span>
              {!!groundConductor && (
                <span>
                  Ground: {groundConductor.groundMetal} / {circuit.insulation}
                </span>
              )}
              {circuit && circuit.simpull && <SimPullHead />}
            </div>
            <div className="expanded-colors">{displayColors(circuit)}</div>
          </td>
        </tr>
      );
    }
    return itemRows;
  }
);

class FeederSchedule extends React.Component {
  constructor() {
    super();
    this.state = {
      expandedRows: [],
      activeTab: TAB_AVAILABLE,
      incompatible: {},
      reordering: false
    };
  }

  componentDidUpdate(prevProps) {
    //When active reel changes to different reel
    //or has all circuits removed, reset incompatible list
    if (
      (!this.props.activeReel ||
        this.isDifferentReel(prevProps) ||
        this.isClearedCircuitList(prevProps)) &&
      Object.keys(this.state.incompatible).length
    ) {
      this.setState({
        incompatible: {}
      });
    }

    // When there's no active reel but we're still looking at the reels tab,
    // reset back to the available tab.
    if (
      this.props.activeReelIndex === null &&
      this.state.activeTab === TAB_ON_REEL
    ) {
      this.setState({ activeTab: TAB_AVAILABLE });
    }
  }

  isDifferentReel(prevProps) {
    return this.props.activeReel.name !== (prevProps.activeReel || {}).name;
  }

  isClearedCircuitList(prevProps) {
    return (
      (this.props.activeReel.circuits || []).length === 0 &&
      prevProps.activeReel &&
      prevProps.activeReel.circuits &&
      prevProps.activeReel.circuits.length
    );
  }

  render() {
    return (
      <div className="feeder-schedule">
        <div className="section-toggle">
          <div className="toggle">
            <div
              onClick={() => this.toggleViews(TAB_ON_REEL)}
              className={
                this.state.activeTab === TAB_ON_REEL ? 'selected' : null
              }
            >
              Circuits on Reel
            </div>
            <div
              onClick={() => this.toggleViews(TAB_AVAILABLE)}
              className={
                this.state.activeTab === TAB_AVAILABLE ? 'selected' : null
              }
            >
              Available Circuits
            </div>
          </div>
        </div>
        {this.renderCircuitList()}
      </div>
    );
  }

  renderCircuitList() {
    if (this.state.activeTab === TAB_ON_REEL) {
      if (
        this.props.activeReel &&
        (this.props.activeReel.circuits || []).length
      ) {
        return this.displayCircuitsOnReel();
      }
      return this.displayEmptyOnReel();
    }
    return Object.keys(this.props.availableCircuits).length
      ? this.displayCircuitsAvailable()
      : this.displayEmptyAvailable();
  }

  toggleViews(name) {
    if (name !== TAB_ON_REEL || this.props.activeReel) {
      this.setState({ activeTab: name || TAB_AVAILABLE });
    }
  }

  handleExpand(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.indexOf(rowId) > -1;
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);
    this.setState({ expandedRows: newExpandedRows });
  }

  sort(attr) {
    // remove sort if double click on attribute, set attribute if click new one
    let newAttr = this.props.sortPreference !== attr ? attr : 'dateCreated';
    // update sort preference in firestore job obj
    this.props.firestore.update(`jobs/${this.props.jobId}`, {
      sortPreference: newAttr
    });
  }

  displayCircuitsOnReel() {
    return (
      <div className="table-wrapper">
        <table className="overview-list circuits-on-reel">
          <thead>
            <tr>
              <th>
                <div>From</div>
              </th>
              <th>
                <div>To</div>
              </th>
              <th>
                <div>Size</div>
              </th>
              <th>
                <div>Length</div>
              </th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <SortableList
            ids={this.props.activeReel.circuits}
            circuits={this.props.circuits}
            expandedRows={this.state.expandedRows}
            metric={this.props.metric}
            removeFromReel={id => this.removeFromReel(id)}
            handleExpand={id => this.handleExpand(id)}
            displayColors={circuit => this.displayColors(circuit)}
            reordering={this.state.reordering}
            //These following are the standard properties of the library
            helperClass="reordering-item"
            useDragHandle={true}
            onSortEnd={info => this.onReorderCircuits(info)}
            onSortStart={() => this.onStartReorderCircuits()}
            transitionDuration={200}
          />
        </table>
      </div>
    );
  }

  displayCircuitsAvailable() {
    const sortPreference = this.props.sortPreference;
    return (
      <div className="table-wrapper">
        <table className="overview-list">
          <thead>
            <tr>
              <th
                onClick={() => {
                  this.sort('from');
                }}
                className={sortPreference === 'from' ? 'selected-sort' : null}
              >
                <div>From</div>
              </th>
              <th
                onClick={() => {
                  this.sort('to');
                }}
                className={sortPreference === 'to' ? 'selected-sort' : null}
              >
                <div>To</div>
              </th>
              <th
                onClick={() => {
                  this.sort('size');
                }}
                className={sortPreference === 'size' ? 'selected-sort' : null}
              >
                <div>Size</div>
              </th>
              <th
                onClick={() => {
                  this.sort('length');
                }}
                className={sortPreference === 'length' ? 'selected-sort' : null}
              >
                <div>Length</div>
              </th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{this.displayCircuits()}</tbody>
        </table>
      </div>
    );
  }

  displayEmptyAvailable() {
    return <EmptySmall type="available-circuits" />;
  }

  displayEmptyOnReel() {
    return <EmptySmall type="circuits-on-reel" />;
  }

  displayCircuits() {
    const sortAttr = this.props.sortPreference;
    let circuitList = Object.keys(this.props.availableCircuits).map(id => {
      return { ...this.props.circuits[id], id };
    });
    circuitList.sort((a, b) => {
      // sort by descending date created
      if (sortAttr === 'dateCreated') {
        return (b[sortAttr] || 0) - (a[sortAttr] || 0);
        // sort by ascending numerical size and length
      } else if (sortAttr === 'length') {
        return a[sortAttr] === b[sortAttr]
          ? (b['dateCreated'] || 0) - (a['dateCreated'] || 0)
          : (a[sortAttr] || 0) - (b[sortAttr] || 0);
        // sort by string for to and from
      } else if (sortAttr === 'size') {
        return a[sortAttr] === b[sortAttr]
          ? (b['dateCreated'] || 0) - (a['dateCreated'] || 0)
          : (a['diameter'] || 0) - (b['diameter'] || 0);
      } else if (a[sortAttr] && b[sortAttr]) {
        if (a[sortAttr].toLowerCase() < b[sortAttr].toLowerCase()) {
          return -1;
        } else if (a[sortAttr].toLowerCase() > b[sortAttr].toLowerCase()) {
          return 1;
        } else if (a[sortAttr].toLowerCase() === b[sortAttr].toLowerCase()) {
          return (b['dateCreated'] || 0) - (a['dateCreated'] || 0);
        }
      }
      return 0;
    });
    return circuitList.map(circuit => {
      return this.displaySingleCircuit(circuit.id);
    });
  }

  displaySingleCircuit(id) {
    const circuit = this.props.circuits[id];
    let metric = this.props.metric;
    let expanded = this.state.expandedRows.indexOf(id) > -1 ? 'true' : null;
    let incompatible = !!this.state.incompatible[id];
    let exceeded = !!this.props.exceeded[id];
    const groundConductor = circuit.conductors.find(c => c.isGroundConductor);
    const isFromHoverable = circuit.from && circuit.from.length > 8;
    const isToHoverable = circuit.to && circuit.to.length > 8;

    const itemRows = [
      <tr
        key={id + TAB_AVAILABLE}
        className={
          incompatible
            ? 'circuit-warning incompatible'
            : exceeded
            ? 'circuit-warning exceeds'
            : null
        }
      >
        <td className="from-cell">
          {circuit.from ? (
            <HoverableText text={circuit.from} isHoverable={isFromHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>
          {circuit.to ? (
            <HoverableText text={circuit.to} isHoverable={isToHoverable} />
          ) : (
            <NoColumnValue />
          )}
        </td>
        <td>{circuit.size}</td>
        <td>{`${circuit.length}${metric ? 'm' : "'"}`}</td>
        <td className="add-cell">
          {this.props.activeReel && (
            <IconButton onClick={e => this.addToReel(id)} type="add-to-reel" />
          )}
        </td>
        <td className="expand-cell">
          <Expand onClick={() => this.handleExpand(id)} expanded={expanded} />
        </td>
        <td className="overflow-cell">
          <Overflow>
            <li>
              <Link
                to={`/projects/${this.props.projectId}/jobs/${this.props.jobId}/configure/circuit/${id}`}
              >
                Edit
              </Link>
            </li>
            <li
              className="actionable"
              onClick={() => this.duplicateCircuit(id)}
            >
              Duplicate
            </li>
            <li className="actionable" onClick={() => this.deleteCircuit(id)}>
              Delete
            </li>
          </Overflow>
        </td>
      </tr>
    ];

    if (expanded) {
      itemRows.push(
        <tr key={id + 'i'}>
          <td key={'row-expanded-' + id} className="expanded-row">
            <div className="notch" />
            <div className="expanded-header">
              <span>
                Metal / Insulation: {circuit.metal} / {circuit.insulation}
              </span>
              {!!groundConductor && (
                <span>
                  Ground: {groundConductor.groundMetal} / {circuit.insulation}
                </span>
              )}
              {circuit && circuit.simpull && <SimPullHead />}
            </div>
            <div className="expanded-colors">{this.displayColors(circuit)}</div>
          </td>
        </tr>
      );
    }
    return itemRows;
  }

  addToReel(id) {
    let circuit = this.props.circuits[id];
    let reel = {
      ...this.props.activeReel,
      circuits: (this.props.activeReel.circuits || []).slice(0)
    };
    let circuitType =
      circuit.conductors.length === 1 && circuit.conductors[0] === 'Green'
        ? REEL_GROUND_WIRE
        : REEL_STANDARD_WIRE;
    // new new logic: There are no longer restrictions between green and other colors.

    if (!reel.circuits || reel.circuits.length === 0) {
      // if empty reel, add circuit and assign the reel.circuitType
      reel.circuitType = circuitType;
      reel.circuits = [
        {
          id,
          colorKey: Math.floor(Math.random() * 10)
        }
      ];
    } else {
      reel.circuits.push({
        id,
        colorKey:
          1 +
          reel.circuits.reduce(
            (result, current) => Math.max(result, current.colorKey),
            0
          )
      });
    }
    this.props.onAddCircuitToReel(reel);
  }

  removeFromReel(id) {
    let reel = {
      ...this.props.activeReel,
      circuits: this.props.activeReel.circuits.slice(0)
    };

    if (reel.circuits && reel.circuits.length) {
      let index = reel.circuits.reduce(
        (result, current, i) => (current.id === id ? i : result),
        -1
      );
      if (index > -1) {
        reel.circuits.splice(index, 1);
      }
    }

    if (reel.circuits && reel.circuits.length === 0) {
      reel.circuitType = '';
    }
    this.props.onRemoveCircuitFromReel(reel);
  }

  deleteCircuit(id) {
    this.props.showDeleteCircuitModal({
      projectId: this.props.projectId,
      jobId: this.props.jobId,
      circuitIds: [id]
    });
  }

  duplicateCircuit(id) {
    let circuits = cloneDeep(this.props.circuits);

    const now = Date.now();

    // make copy of original circuit with updated dateCreated
    let newCircuit = Object.assign({}, circuits[id], { dateCreated: now });

    const isGroundCircuit = newCircuit.isGroundCircuit;
    const isMainCircuit = newCircuit.groundCircuitId;

    // remove 'isGroundCircuit' and 'mainCircuitId' props if ground circuit
    if (isGroundCircuit) {
      delete newCircuit.isGroundCircuit;
      delete newCircuit.mainCircuitId;
    }

    // remove 'groundCircuitId' prop if main circuit
    if (isMainCircuit) {
      delete newCircuit.groundCircuitId;
    }

    circuits[now] = newCircuit;
    this.props.firestore
      .update(`jobs/${this.props.jobId}`, {
        circuits
      })
      .catch(error => {
        console.error(error.message);
        this.props.showToast('Circuit could not be created', 'error');
      });
  }

  displayColors(circuit) {
    const conductors = circuit.conductors;
    if (conductors && conductors.length) {
      //new updated model 8/2018
      const colorCollection = conductors.map((conductor, id) => {
        return (
          <ConductorDetail
            color={conductor.color}
            size={conductor.size}
            key={id + '1'}
            catalog={this.props.catalog}
          />
        );
      });
      return colorCollection;
    }
    //original data model
    const colors = circuit.colors;
    const colorCollection = colors.map((color, id) => {
      return (
        <ConductorDetail
          color={color}
          size={circuit.size}
          key={id + '1'}
          catalog={this.props.catalog}
        />
      );
    });
    return colorCollection;
  }
  onReorderCircuits({ oldIndex, newIndex }) {
    let reel = cloneDeep(this.props.activeReel);
    let circuits = arrayMove(reel.circuits, oldIndex, newIndex);
    reel.circuits = circuits;

    this.props.onReorderCircuitOnReel(reel);
    this.setState({
      reordering: false
    });
  }
  onStartReorderCircuits() {
    this.setState({
      expandedRows: [],
      reordering: true
    });
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showDeleteCircuitModal: deleteCircuitObj => {
      dispatch(showDeleteCircuitModal(deleteCircuitObj));
    },
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    }
  };
};

export default compose(
  firestoreConnect(),
  connect(
    () => {
      return {};
    },
    mapDispatchToProps
  )
)(FeederSchedule);
