import React from 'react';

class Button extends React.Component {
  render() {
    const { saving, className, ...other } = this.props;
    const finalClassName = saving ? `${className} saving` : className;
    return (
      <button type="button" className={finalClassName} {...other}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;
