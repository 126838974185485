import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { hideToast, resetToastPass } from '../actions/ToastActions';
import { hideModal } from '../actions/ModalActions';
import { resetOverflowCloseFunction } from '../actions/AppActions';

export class ChangeLocation extends React.Component {
  componentDidUpdate(prevProps) {
    // hide toast
    if (this.props.location !== prevProps.location && !!this.props.toastType) {
      if (this.props.toastPass) {
        // keep showing the toast, but reset the pass flag so (persistent) toast goes away on next navigation
        this.props.resetToastPass();
      } else {
        this.props.hideToast();
      }
    }

    // hide any modals
    if (
      this.props.location !== prevProps.location &&
      (this.props.deleteCircuitObj ||
        this.props.deleteJobObj ||
        this.props.deleteReelObj ||
        this.props.removeCircuitObj)
    ) {
      this.props.hideModal();
    }

    // reset any open overflow close function
    if (
      this.props.location !== prevProps.location &&
      this.props.overflowCloseFunction
    ) {
      this.props.resetOverflowCloseFunction();
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(
  compose(
    connect(
      state => ({
        toastType: state.appState && state.appState.toastType,
        toastPass: state.appState && state.appState.toastPass,
        deleteCircuitObj: state.appState && state.appState.deleteCircuitObj,
        deleteJobObj: state.appState && state.appState.deleteJobObj,
        deleteReelObj: state.appState && state.appState.deleteReelObj,
        removeCircuitObj: state.appState && state.appState.removeCircuitObj,
        overflowCloseFunction:
          state.appState && state.appState.overflowCloseFunction
      }),
      dispatch => ({
        resetToastPass: () => dispatch(resetToastPass()),
        hideToast: () => dispatch(hideToast()),
        hideModal: () => dispatch(hideModal()),
        resetOverflowCloseFunction: () => dispatch(resetOverflowCloseFunction())
      })
    )
  )(ChangeLocation)
);
