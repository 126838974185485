import convertUnits from './convertUnits';
import productFinder from '../utils/productFinder';

/**
 * This takes a collection of available reels and finds the smallest
 * value for each field: width, height, weight, and weight_capacity
 *
 * NOTE: This will find the smallest values for each field *independently*
 * meaning this won't return the smallest available reel, but return the smallest
 * possible values for each field given a selection of reels.
 *
 * @param  {Array}   reels  - array of reel objects
 * @param  {Boolean} metric - should we convert the units into metric?
 * @return {Object}
 */
export const findSmallestReel = (reels = [], metric = false) => {
  if (!reels.length) {
    return false;
  }

  let width = null;
  let height = null;
  let weight = null;
  let weight_capacity = null;
  let reelProduct = null;

  reels.forEach(reel => {
    if (!width || reel.width < width) {
      width = reel.width;
    }

    if (!height || reel.diameter < height) {
      height = reel.diameter;
    }

    if (!weight || reel.reel_weight < weight) {
      weight = reel.reel_weight;
    }

    // We're assigning the smallest reel based on weight capacity
    const weightCapacity = reel.weight_capacity - reel.reel_weight;
    if (!weight_capacity || weightCapacity < weight_capacity) {
      weight_capacity = weightCapacity;
      reelProduct = reel;
    }
  });

  return {
    width: !metric ? width : convertUnits.fromInToCm(width, 'ceil'),
    height: !metric ? height : convertUnits.fromInToCm(height, 'ceil'),
    weight: !metric ? weight : convertUnits.fromLbToKg(weight, 'ceil'),
    weight_capacity,
    reelProduct
  };
};

/**
 * This takes a collection of available simreels and finds the smallest
 * value for each field: width, height, and weight.
 *
 * NOTE: This will find the smallest values for each field *independently*
 * meaning this won't return the smallest available reel, but return the smallest
 * possible values for each field given a selection of reels.
 *
 * @param  {Object}  reels  - object of reels where key is the reel ID and value is the reel object
 * @param  {Boolean} metric - should we convert the units into metric?
 * @return {Object}
 */
export const findSmallestSimreel = (reels = {}, metric = false) => {
  if (!Object.keys(reels).length) {
    return false;
  }

  let width = null;
  let height = null;
  let weight = null;
  let reelProduct = null;

  for (let id in reels) {
    if (!reels.hasOwnProperty(id)) {
      continue;
    }

    const reel = reels[id];

    if (!width || reel.outside_in < width) {
      width = reel.outside_in;
    }

    if (!weight || reel.weight_lb < weight) {
      weight = reel.weight_lb;
    }

    // We're assigning the smallest sim reel based on flange/diam
    if (!height || reel.flange_in < height) {
      height = reel.flange_in;
      reelProduct = {
        ...reel,
        name: id,
        id
      };
    }
  }

  return {
    width: !metric ? width : convertUnits.fromInToCm(width, 'ceil'),
    height: !metric ? height : convertUnits.fromInToCm(height, 'ceil'),
    weight: !metric ? weight : convertUnits.fromLbToKg(weight, 'ceil'),
    reelProduct
  };
};

/**
 * Returning an object with the total weight and volume of the circuits on a reel.
 *
 * @param  {Object} circuitInfos - circuit
 * @param  {Object} circuits     - job circuits
 * @return {Object}
 */
export const getCurrentCircuitLoad = (circuitInfos, circuits) => {
  return (circuitInfos || []).reduce(
    (accumulator, circuitInfo) => {
      return {
        weight:
          accumulator.weight +
          ((circuits[circuitInfo.id] && circuits[circuitInfo.id].weight) || 0),
        volume:
          accumulator.volume +
          ((circuits[circuitInfo.id] && circuits[circuitInfo.id].volume) || 0)
      };
    },
    {
      weight: 0,
      volume: 0
    }
  );
};
/**
 * Returning the largest diameter of the circuit on a reel.
 *
 * @param  {Object} circuitInfos - object with circuit id and color key of circuits on reel
 * @param  {Object} circuitsMap - object with job circuits
 * @return {Number}
 */
export const findLargestConductorDiameter = (circuitInfos, circuitsMap) => {
  let diameter = 0;
  circuitInfos.forEach(info => {
    if (circuitsMap[info.id] && circuitsMap[info.id].diameter > diameter) {
      diameter = circuitsMap[info.id].diameter;
    }
  });

  return diameter;
};

/**
 * Returning a reel product that better fits the load of the
 * current reel and its new maxReelProductVolume.
 *
 * NOTE: When re-calculating SIMreels, we DO NOT change the reel product.
 * If the current load does not fit within the given reel, it's simply not valid.
 *
 * @param  {Object} reel
 * @param  {Object} load
 * @param  {Number} conductorDiameter
 * @param  {Object} catalog
 * @param  {Object} simreels
 * @return {Object}
 */
export const findMoreAppropriateReel = (
  reel,
  load,
  conductorDiameter,
  catalog,
  simreels
) => {
  let reels;

  if (reel.simpull) {
    // We're filtering this collection of SIMreels to only allow
    // the type specified during creation.
    reels = Object.keys(simreels)
      .map(key => ({ ...simreels[key], id: key, name: key }))
      .filter(r => r.id === reel.reelProduct.id);
  } else {
    reels = [...catalog.reels.items].sort((a, b) => {
      // The bigger the drum the smaller the reel capacity when same diameter
      if (a.diameter === b.diameter) {
        return b.drum - a.drum;
      }

      return a.diameter - b.diameter;
    });
  }

  const reelRestrictions = reel.restrictions;
  for (let i = 0, l = reels.length; i < l; ++i) {
    const normalizedReelProduct = productFinder.normalizeReelProduct(reels[i]);
    const reelIsSupported = weightAndVolumeAreSupported(
      load,
      normalizedReelProduct,
      reelRestrictions,
      conductorDiameter,
      catalog,
      simreels
    );

    if (reelIsSupported) {
      return {
        reelProduct: normalizedReelProduct,
        maxReelProductVolume: getMaximumVolumeForReelProduct(
          reels[i],
          conductorDiameter,
          catalog,
          simreels
        )
      };
    }
  }

  return null;
};

/**
 * Returning the weight capacity of the reel product.
 *
 * @param  {Object} normalizedReelProduct
 * @return {Object}
 */
export const getReelWeightCapacity = normalizedReelProduct => {
  return (
    normalizedReelProduct.total_weight_capacity -
    normalizedReelProduct.reel_weight
  );
};

/**
 * Validate that current reel product can support circuits' weight and volume.
 *
 * @param  {Object} load
 * @param  {Object} normalizedReelProduct
 * @param  {Object} reelRestrictions
 * @param  {Number} conductorDiameter
 * @param  {Object} catalog
 * @param  {Object} simreels
 * @return {Boolean}
 */
export const weightAndVolumeAreSupported = (
  load,
  normalizedReelProduct,
  reelRestrictions,
  conductorDiameter,
  catalog,
  simreels
) => {
  if (!normalizedReelProduct) {
    return false;
  }

  const weightRestriction = parseFloat(reelRestrictions.weight);
  const widthRestriction = parseFloat(reelRestrictions.width);
  const heightRestriction = parseFloat(reelRestrictions.height);

  const maxWeight = getReelWeightCapacity(normalizedReelProduct);
  const maxVolume = getMaximumVolumeForReelProduct(
    normalizedReelProduct,
    conductorDiameter,
    catalog,
    simreels
  );

  const widthCheck =
    isNaN(widthRestriction) ||
    (normalizedReelProduct.width || normalizedReelProduct.outside_in) <=
      widthRestriction;

  const heightCheck =
    isNaN(heightRestriction) ||
    (normalizedReelProduct.diameter || normalizedReelProduct.flange_in) <=
      heightRestriction;

  const weightCheck =
    isNaN(weightRestriction) ||
    normalizedReelProduct.reel_weight + load.weight <= weightRestriction;

  //----

  const isVolumeSupported =
    load.volume < maxVolume && widthCheck && heightCheck;

  const isWeightSupported = load.weight < maxWeight && weightCheck;

  return isWeightSupported && isVolumeSupported;
};

/**
 * Returning an object of all the reel products.
 *
 * @param  {Object} catalog
 * @param  {Object} simreels
 * @return {Object}
 */
const getReelProductMap = (catalog, simreels) => {
  const reelProductMap = {};
  catalog.reels.items.forEach(reelProduct => {
    reelProductMap[reelProduct.id] = reelProduct;
  });
  return Object.assign(reelProductMap, simreels);
};

/**
 * Returning the max volume a reel product can support.
 *
 * @param  {Object} normalizedReelProduct
 * @param  {Number} conductorDiameter
 * @param  {Object} catalog
 * @param  {Object} simreels
 * @return {Number}
 */
const getMaximumVolumeForReelProduct = (
  normalizedReelProduct,
  conductorDiameter,
  catalog,
  simreels
) => {
  const reelProductMap = getReelProductMap(catalog, simreels);
  const reelProduct = reelProductMap[normalizedReelProduct.id];

  if (!reelProduct) {
    return 0;
  }

  const traverse = reelProduct.traverse || reelProduct.traverse_in;
  const flange = reelProduct.diameter || reelProduct.flange_in;
  const drum = reelProduct.drum || reelProduct.drum_in;

  conductorDiameter = conductorDiameter / 1000;

  return getMaxVolume(traverse, flange, drum, conductorDiameter);
};

/**
 * Calculate the maximum volume a reel can support, given a conductor diameter.
 * This is the actual max volume formula without any reel normalization stuff.
 *
 * @param  {Number} traverse
 * @param  {Number} flange
 * @param  {Number} drum
 * @param  {Number} conductorDiameter
 * @return {Number}
 */
export const getMaxVolume = (traverse, flange, drum, conductorDiameter) => {
  // Clearance factor to make sure they allow space for circuits
  // that aren't as tightly packed as theoretically possible.
  const P = 0.05;

  const formula1 = (flange - 2 * (P * flange + conductorDiameter)) / 2;
  const formula2 = drum / 2;

  return Math.PI * traverse * (formula1 * formula1 - formula2 * formula2);
};

/**
 * Returning the updated reel with the new reel product.
 *
 * @param  {Object} reel     - obj including circuits and reel product info
 * @param  {Object} circuits - all circuits on that job
 * @param  {Object} catalog  - country specific catalog
 * @param  {Object} simreels - simreel products
 * @return {Object|null}     - reel obj with appropriate reel product
 */
export const getUpdatedReelProduct = (
  reel = {},
  circuits = {},
  catalog = {},
  simreels = {}
) => {
  const load = getCurrentCircuitLoad(reel.circuits, circuits);
  const conductorDiameter = findLargestConductorDiameter(
    reel.circuits,
    circuits
  );

  const newReelProduct = findMoreAppropriateReel(
    reel,
    load,
    conductorDiameter,
    catalog,
    simreels
  );

  if (!newReelProduct) {
    return null;
  }

  return {
    ...reel,
    ...newReelProduct,
    circuitWeight: load.weight,
    currentVolume: load.volume
  };
};
