const FLOOR = 'floor';
const convertUnits = {
  fromFtToIn: (ft, rounder) => Math[rounder ? rounder : FLOOR](ft * 12),
  fromInToFt: (inches, rounder) => Math[rounder ? rounder : FLOOR](inches / 12),
  fromMToFt: (m, rounder) => Math[rounder ? rounder : FLOOR](m / 0.3048),
  fromMToCm: (m, rounder) => Math[rounder ? rounder : FLOOR](m * 100),
  fromCmToM: (cm, rounder) => Math[rounder ? rounder : FLOOR](cm / 100),
  fromCmToIn: (cm, rounder) => Math[rounder ? rounder : FLOOR]((cm * 1) / 2.54),
  fromInToCm: (inches, rounder) =>
    Math[rounder ? rounder : FLOOR](inches * 2.54),

  fromKgToLb: (kg, rounder) =>
    Math[rounder ? rounder : FLOOR](kg * 2.20462262185),
  fromLbToKg: (lb, rounder) => Math[rounder ? rounder : FLOOR](lb * 0.45359237)
};

export default convertUnits;
