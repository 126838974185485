import React from 'react';
import classNames from 'classnames';

const Checkbox = ({
  label,
  id,
  checked,
  onChange,
  required = false,
  disabled = false,
  deselectMode = false
}) => {
  const className = classNames('checkbox', {
    deselect: deselectMode
  });

  return (
    <div className={className}>
      <input
        id={id}
        type="checkbox"
        className={checked ? 'checked' : ''}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={id}>
        {label}
        {required && '*'}
      </label>
    </div>
  );
};

export default Checkbox;
