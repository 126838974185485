import React from 'react';
import Breadcrumbs from '../patterns/Breadcrumbs';
import FeederSchedule from './FeederSchedule';
import ReelVisual from './ReelVisual';
import ReelCapacityContainer from './ReelCapacityContainer';
import ReelList from './ReelList';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showRemoveCircuitFromReelModal } from '../actions/ModalActions';
import { changeSelectedReel } from '../actions/AppActions';
import withNotFoundRedirect from '../common/withNotFoundRedirect';
import { getUpdatedReelProduct } from '../utils/reelUtil';
import cloneDeep from 'lodash/cloneDeep';

export class Configure extends React.Component {
  reelProductMap;

  constructor(props) {
    super(props);
    this.state = {
      circuitsExceedingCapacity: {}
    };
  }

  initializeSelectedReel(reels) {
    if (reels && reels.length && !this.props.appState.selectedReel) {
      this.props.changeSelectedReel(0);
    }
  }

  componentDidMount() {
    if (!this.reelProductMap && this.props.jobs && this.props.catalogs) {
      const jobId = this.props.match.params.jobId;
      this.initializeReelProductMap(
        this.props.jobs[jobId] || {},
        this.props.catalogs
      );
      this.initializeSelectedReel(
        this.props.jobs[jobId] && this.props.jobs[jobId].reels
      );
    }

    if (this.props.appState.selectedReel) {
      let element = document.querySelector(
        '.selected.reel-list-item-container'
      );

      if (element) {
        element.scrollIntoView();
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      !this.reelProductMap &&
      nextProps.jobs &&
      nextProps.catalogs &&
      (!this.props.jobs || !this.props.catalogs)
    ) {
      const jobId = this.props.match.params.jobId;
      this.initializeReelProductMap(
        nextProps.jobs[jobId] || {},
        nextProps.catalogs
      );
      this.initializeSelectedReel(
        nextProps.jobs[jobId] && nextProps.jobs[jobId].reels
      );
    }
  }

  changeSelectedReel = id => {
    this.props.changeSelectedReel(id);
    this.setState({
      circuitsExceedingCapacity: {}
    });
  };

  render() {
    const projectId = this.props.match.params.projectId || false;
    const project =
      this.props.projects && projectId ? this.props.projects[projectId] : {};
    const jobId = this.props.match.params.jobId || false;
    const job = this.props.jobs && jobId ? this.props.jobs[jobId] : {};

    return (
      <div className="configure-job">
        <div className="action-heading">
          <Breadcrumbs type="projects" end>
            {project && (
              <li>
                <Link to={`/projects/${projectId}`}>{project.name}</Link>
              </li>
            )}
            {job && (
              <li>
                <Link
                  to={`/projects/${projectId}/jobs/${jobId}/summary/feeder`}
                >
                  {job.name}
                </Link>
              </li>
            )}
          </Breadcrumbs>
        </div>
        <div className="page-title secondary-title">
          <h2>Configurator</h2>
        </div>
        {this.renderConfigurators(job, projectId, jobId)}
      </div>
    );
  }

  renderConfigurators(job, projectId, jobId) {
    const catalogs = this.props.catalogs;
    let selectedReelIndex =
      this.props.appState && this.props.appState.selectedReel;
    let currentReel =
      job.reels && selectedReelIndex > -1 && job.reels[selectedReelIndex];

    return (
      <div className="configure-sections">
        <div>
          {projectId && job && job.reels && (
            <ReelList
              jobId={jobId}
              reels={job.reels}
              projectId={projectId}
              navigate={this.navigate}
              selectedReel={selectedReelIndex}
              quoteRequested={job.quoteRequested}
              changeReel={this.changeSelectedReel}
            />
          )}
        </div>
        <div className="feeder-schedule-section">
          {projectId && job && job.circuits && (
            <FeederSchedule
              circuits={job.circuits}
              availableCircuits={this.getAvailableCircuits(job)}
              projectId={projectId}
              jobId={jobId}
              catalog={catalogs ? catalogs[job.country || 'us'] : null}
              sortPreference={job.sortPreference}
              metric={job.metric}
              activeReel={currentReel}
              activeReelIndex={selectedReelIndex}
              exceeded={this.state.circuitsExceedingCapacity}
              onAddCircuitToReel={reel => this.onAddCircuitToReel(reel)}
              onRemoveCircuitFromReel={reel =>
                this.onRemoveCircuitFromReel(reel, selectedReelIndex)
              }
              onReorderCircuitOnReel={reel => this.onReorderCircuitOnReel(reel)}
            />
          )}
        </div>
        <div className="reel-visualization">
          <h4 className="reel-name-display">
            {currentReel && currentReel.name}
          </h4>
          <p className="reel-package-display">
            {currentReel &&
              currentReel.reelProduct &&
              currentReel.reelProduct.name}
          </p>
          {currentReel && (
            <ReelVisual activeReel={currentReel} circuits={job.circuits} />
          )}
          {currentReel && <ReelCapacityContainer activeReel={currentReel} />}
        </div>
      </div>
    );
  }

  getAvailableCircuits(job) {
    let available = Object.assign({}, job.circuits);

    if (job.reels && job.reels.length) {
      job.reels.forEach(reel => {
        if (reel.circuits && reel.circuits.length) {
          reel.circuits.forEach(circuit => delete available[circuit.id]);
        }
      });
    }

    return available;
  }

  /**
   * Navigate to a sub-page within configure.
   *
   * @param {String} url
   */
  navigate = url => {
    this.props.history.push(this.props.match.url + url);
  };

  onAddCircuitToReel(reel) {
    const { match, jobs, appState, catalogs } = this.props;

    const jobId = match.params.jobId || false;
    const job = jobs && jobId && jobs[jobId];
    const selectedReel = appState && appState.selectedReel;
    const catalog = catalogs[job.country || 'us'];
    const simreels = catalogs.simreels;

    if (!job || !job.reels.length || !job.reels[selectedReel]) {
      return false;
    }

    const updatedReel = getUpdatedReelProduct(
      reel,
      job.circuits,
      catalog,
      simreels
    );

    if (!updatedReel) {
      this.setState({
        circuitsExceedingCapacity: {
          ...this.state.circuitsExceedingCapacity,
          [reel.circuits[reel.circuits.length - 1].id]: true
        }
      });
      return false;
    }

    const reels = [...job.reels];
    reels[selectedReel] = updatedReel;

    this.props.firestore.update(`jobs/${jobId}`, { reels }).catch(error => {
      console.error('add circuit to reel', error.message);
    });
  }

  onReorderCircuitOnReel(reel) {
    const jobId = this.props.match.params.jobId || false;
    const job = this.props.jobs && jobId && this.props.jobs[jobId];
    let selectedReel = this.props.appState && this.props.appState.selectedReel;

    if (job && job.reels && job.reels[selectedReel]) {
      let reels = cloneDeep(job.reels);
      reels[selectedReel] = reel;

      this.props.firestore
        .update(`jobs/${jobId}`, {
          reels
        })
        .catch(error => {
          console.error('reorder circuit on reel', error.message);
        });
    }
  }

  onRemoveCircuitFromReel(reel, reelId) {
    const jobId = this.props.match.params.jobId;
    const projectId = this.props.match.params.projectId;
    const job = this.props.jobs && jobId && this.props.jobs[jobId];
    const catalog = this.props.catalogs[job.country || 'us'];
    const simreels = this.props.catalogs.simreels;
    if (!job) {
      return false;
    }

    const updatedReel = getUpdatedReelProduct(
      reel,
      job.circuits,
      catalog,
      simreels
    );

    this.props.showRemoveCircuitFromReelModal({
      projectId,
      jobId,
      reelId,
      reel: updatedReel
    });

    this.setState({
      circuitsExceedingCapacity: {}
    });
  }

  initializeReelProductMap(job, catalogs) {
    if (!this.reelProductMap) {
      let reels = catalogs[job.country || 'us'].reels.items;
      let simReels = catalogs.simreels;
      this.reelProductMap = {};

      reels.forEach(reelProduct => {
        this.reelProductMap[reelProduct.id] = reelProduct;
      });

      Object.assign(this.reelProductMap, simReels);
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showRemoveCircuitFromReelModal: removingObj => {
      dispatch(showRemoveCircuitFromReelModal(removingObj));
    },
    changeSelectedReel: index => {
      dispatch(changeSelectedReel(index));
    }
  };
};

const mapStateToProps = (state, props) => {
  return {
    projects: state.firestore.data.projects,
    jobs: state.firestore.data[`jobs-${props.match.params.projectId}`],
    catalogs: state.firestore.data.catalogs,
    appState: state.appState
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(Configure);
