import React from 'react';
import ReelVisual from './ReelVisual';
import ReelSummaryCircuits from './ReelSummaryCircuits';
import Expand from '../patterns/Expand';
import NoColumnValue from '../patterns/NoColumnValue';

export default class ReelSummaryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }
  handleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const reel = this.props.reel;
    const reelProduct = reel.reelProduct || {};
    const rows = [];
    let reelWidth =
      reelProduct && reelProduct.width ? (
        reelProduct.width + `"`
      ) : (
        <NoColumnValue />
      );

    const weight = Math.round(
      (reelProduct.reel_weight || 0) + (reel.circuitWeight || 0)
    );
    rows.push(
      <tr key={reel.name + '-base'} className="reel-summary-item">
        <td>{reel.name}</td>
        <td>{reelProduct.name}</td>
        <td>{reelProduct.diameter}”</td>
        <td>{reelWidth}</td>
        <td>{weight + ' lb'}</td>
        <td className="expand-cell">
          <Expand onClick={this.handleExpand} expanded={this.state.expanded} />
        </td>
      </tr>
    );

    if (this.state.expanded) {
      rows.push(
        <tr
          key={reel.name + '-expanded'}
          className="reel-summary-item-expanded"
        >
          <td colSpan="6">
            <div className="reel-summary-item-details">
              <ReelSummaryCircuits
                reel={reel}
                circuits={this.props.circuits}
                metric={this.props.metric}
                catalog={this.props.catalog}
              />
              <div className="reel-summary-visualization">
                <h3 className="detail-section-title">Reel Visualization</h3>
                <ReelVisual activeReel={reel} circuits={this.props.circuits} />
              </div>
            </div>
          </td>
        </tr>
      );
    }

    return rows;
  }
}
