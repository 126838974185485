import React from 'react';
import ModalTypes from '../constants/ModalTypes';

import DeleteCircuit from './DeleteCircuit';
import DeleteJob from './DeleteJob';
import DeleteReel from './DeleteReel';
import RemoveCircuitFromReel from './RemoveCircuitFromReel';
import EditCircuits from './EditCircuits';
import DeleteMVCircuits from './DeleteMVCircuits';
import EditMVCircuits from './EditMVCircuits';

/**
 * Figure out which modal we should display based on the type.
 * Add a new case statement here anytime we add a new modal type.
 *
 * @param  {String} type - the modal type
 * @return {Component}   - modal component constructor
 */
const getModal = type => {
  switch (type) {
    case ModalTypes.DELETE_CIRCUIT:
      return DeleteCircuit;
    case ModalTypes.DELETE_MV_CIRCUITS:
      return DeleteMVCircuits;
    case ModalTypes.DELETE_JOB:
      return DeleteJob;
    case ModalTypes.DELETE_REEL:
      return DeleteReel;
    case ModalTypes.REMOVE_CIRCUIT_FROM_REEL:
      return RemoveCircuitFromReel;
    case ModalTypes.EDIT_CIRCUITS:
      return EditCircuits;
    case ModalTypes.EDIT_MV_CIRCUITS:
      return EditMVCircuits;
    default:
      return null;
  }
};

/**
 * Our modal container, responsible for determining if/when to display a modal
 * and which type to display. This is pulled from our redux "modal" store.
 */
const ModalContainer = ({ modal, hideModal }) => {
  if (!modal.display) {
    return null;
  }

  const ModalComponent = getModal(modal.type);

  return (
    <div>
      <div id="modal-background" onClick={hideModal} />
      <ModalComponent payload={modal.payload} onCancel={hideModal} />
    </div>
  );
};

export default ModalContainer;
