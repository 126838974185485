import React from 'react';
import classNames from 'classnames';
import Tooltip from './Tooltip';

class IconButton extends React.Component {
  state = { isHovered: false };
  render() {
    const { type, tooltipText = null, disabled = false, ...rest } = this.props;

    const className = classNames('icon-button', type);

    return (
      <div className={'icon-button-container'}>
        {tooltipText && !disabled && (
          <Tooltip text={tooltipText} isHovered={this.state.isHovered} />
        )}
        <button
          type="button"
          id={`${type}-icon`}
          aria-label={`${type}-icon`}
          className={className}
          tooltip-text={tooltipText}
          disabled={disabled}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          {...rest}
        >
          {type === 'add-to-reel' && <span>Add to Reel</span>}
          {type === 'remove-from-reel' && <span>Remove from Reel</span>}
        </button>
      </div>
    );
  }
}

export default IconButton;
