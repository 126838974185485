import productFinder from '../../utils/productFinder';
import convertUnits from '../../utils/convertUnits';
import TemplateValidationError from './TemplateValidationError';

const MAX_FEET_DIFF_SIZES = 250;
const MAX_SIZE_DIFF = 10;

/**
 * Build an object that contains all available sizes in the catalog
 * keyed by the size ID.
 *
 * @param  {Object} catalog
 * @return {Object}
 */
const getSizes = catalog => {
  return (catalog.sizes.items || []).reduce((acc, size) => {
    acc[size.id] = size.diameter;
    return acc;
  }, {});
};

/**
 * Build an array of all products that map to each
 * conductor in the given circuit.
 *
 * @param  {Object} circuit
 * @param  {Object} catalog
 * @return {Array}
 */
const getProductsForCircuit = (circuit, catalog) => {
  return circuit.conductors.map(conductor => {
    return productFinder.getProduct(
      catalog,
      circuit.metal,
      circuit.insulation,
      conductor.size,
      conductor.color
    );
  });
};

/**
 * return valid ground product
 *
 * @param {Object} ground - object with ground info
 * @param {String} color
 * @param {Object} catalog
 */
const getGroundProduct = (ground, color, catalog) => {
  return productFinder.getProduct(
    catalog,
    ground.groundMetal,
    ground.insulation,
    ground.size,
    color
  );
};

/**
 * Map through all products and return an array of just the SKU.
 *
 * @param  {Array} products
 * @return {Array}
 */
const getSkus = products => products.map(p => (p ? p.stock_number : undefined));

/**
 * Find the sum total weight of all the given products.
 *
 * @param  {Array}  products
 * @param  {Object} circuit
 * @param  {Object} job
 * @return {Number}
 */
const getWeight = (products, circuit, job) => {
  const length = parseFloat(parseFloat(circuit.length));
  const lengthInFt = job.metric ? convertUnits.fromMToFt(length) : length;

  return products.reduce((accumulator, product) => {
    return accumulator + productFinder.getConductorWeight(product, lengthInFt);
  }, 0);
};

/**
 * Find the sum total volume of all the given products.
 *
 * @param  {Array}  products
 * @param  {Object} circuit
 * @param  {Object} job
 * @return {Number}
 */
const getVolume = (products, circuit, job) => {
  const length = parseFloat(parseFloat(circuit.length));
  const lengthInFt = job.metric ? convertUnits.fromMToFt(length) : length;

  return products.reduce((accumulator, product) => {
    return accumulator + productFinder.getConductorVolume(product, lengthInFt);
  }, 0);
};

/**
 * Does this circuit have at least one ground wire field defined?
 *
 * @param  {Object} circuit
 * @return {Boolean}
 */
const hasGroundWire = circuit => {
  return !!(
    circuit.groundSize ||
    circuit.groundMetal ||
    circuit.groundOnParallel
  );
};

/**
 * Get a list of all available circuit sizes filtered by metal/insulation,
 * sorted by diameter and deduplicated.
 *
 * @param  {String} metal
 * @param  {String} insulation
 * @param  {Object} catalog
 * @return {Array}
 */
const getAvailableCircuitSizes = (metal, insulation, catalog) => {
  const sizes = catalog.products.items
    .filter(p => p.metal === metal && p.product_type === insulation)
    .sort((a, b) => a.diameter - b.diameter)
    .map(({ size }) => size);

  // deduplicate so we're returning a unique list of sizes
  let seen = {};
  let filteredSizes = [];
  for (let i = 0, l = sizes.length; i < l; ++i) {
    let size = sizes[i];
    if (seen[size] !== 1) {
      seen[size] = 1;
      filteredSizes.push(size);
    }
  }
  return filteredSizes;
};

/**
 * check that a valid ground conductor can be created and return
 * ground conductor object and ground product object
 *
 * @param {Object} circuit
 * @param {String} groundMetal
 * @param {Object} catalog
 */
const getGroundInfo = (circuit, groundMetal, catalog) => {
  const sizes = getSizes(catalog);
  let groundConductor = { size: circuit.groundSize };
  let groundInfo = {
    insulation: circuit.insulation,
    size: circuit.groundSize,
    groundMetal: groundMetal
  };
  const diameter = sizes[circuit.groundSize];
  const defaultGroundColor = 'Green';

  groundConductor.diameter = diameter;

  let groundProduct = getGroundProduct(groundInfo, defaultGroundColor, catalog);
  groundConductor.color = defaultGroundColor;

  if (!groundProduct) {
    const secondaryGroundColor = 'Black';
    groundConductor.color = secondaryGroundColor;
    groundProduct = getGroundProduct(groundInfo, secondaryGroundColor, catalog);
    if (!groundProduct) {
      throw new TemplateValidationError(
        'The uploaded file has required fields that are not valid.'
      );
    }
  }
  return { groundConductor, groundProduct };
};

/**
 * This module handles translating the data format we receive
 * from the feeder schedule into the format we expect to store in firebase.
 *
 * @param  {Array}  data
 * @param  {Object} catalog
 * @param  {Object} job
 * @return {Object} circuits
 */
export default function formatTemplateData(data, catalog, job) {
  const sizes = getSizes(catalog);
  const now = Date.now();
  // dateCreated/ circuit id must take into account the total number of circuits
  // increase quantityCount for each circuit created
  let quantityCount = 0;

  return data.reduce((circuits, row) => {
    const diameter = sizes[row.size];
    const conductors = row.colors.map(color => ({
      size: row.size,
      diameter,
      color
    }));

    const baseCircuit = {
      from: row.from,
      to: row.to,
      metal: row.metal,
      insulation: row.insulation,
      size: row.size,
      diameter,
      length: row.lengthField,
      conductors,
      simpull: row.simpull
    };

    let products = getProductsForCircuit(baseCircuit, catalog);
    Object.assign(baseCircuit, {
      skus: getSkus(products),
      weight: getWeight(products, baseCircuit, job),
      volume: getVolume(products, baseCircuit, job)
    });

    /**
     * 1.) When there's no ground wire, we just add the circuits as-is onto the reel for each run and we're done.
     */
    if (!hasGroundWire(row)) {
      for (let i = 0; i < row.runs; i++) {
        quantityCount++;
        Object.assign(baseCircuit, {
          dateCreated: now - quantityCount
        });
        circuits[baseCircuit.dateCreated] = baseCircuit;
      }
      return circuits;
    }

    /**
     * 2.) We have a ground wire, but it's not on parallel
     */
    if (!row.groundOnParallel) {
      const groundDiameter = sizes[row.groundSize];
      const groundMetal = row.groundMetal;
      const groundConductor = getGroundInfo(row, groundMetal, catalog)
        .groundConductor;
      const groundProduct = getGroundInfo(row, groundMetal, catalog)
        .groundProduct;
      const groundCircuit = {
        ...baseCircuit,
        isGroundCircuit: true,
        metal: row.groundMetal,
        size: row.groundSize,
        diameter: groundDiameter,
        conductors: [groundConductor],
        skus: getSkus([groundProduct]),
        weight: getWeight([groundProduct], baseCircuit, job),
        volume: getVolume([groundProduct], baseCircuit, job)
      };

      // Let's add our base circuit and
      // separate ground circuit for each run.
      const circuitsArr = [];

      for (let i = 0; i < row.runs; i++) {
        circuitsArr.push(baseCircuit, groundCircuit);
      }

      circuitsArr.forEach((circuit, index) => {
        quantityCount++;
        const dateCreated = now - quantityCount;
        circuits[dateCreated] = {
          dateCreated,
          ...circuit
        };
        // add mainCircuit and groundCircuit association to circuits
        if (circuit.isGroundCircuit) {
          circuits[dateCreated] = {
            mainCircuitId: dateCreated + 1,
            ...circuits[dateCreated]
          };
        } else {
          circuits[dateCreated] = {
            groundCircuitId: dateCreated - 1,
            ...circuits[dateCreated]
          };
        }
      });
      return circuits;
    }

    /**
     * 3.) Last scenario, we have a ground wire and it's on parallel
     */

    // checkGroundRestrictions
    // ground metal on parallel must be the same metal as the circuit
    if (row.groundMetal && row.groundMetal !== row.metal) {
      throw new TemplateValidationError(
        'Ground metal must be the same as phase conductor metal.'
      );
    }

    const length = job.metric
      ? convertUnits.fromMToFt(row.lengthField)
      : row.lengthField;

    // If the circuit size and ground size are different lengths,
    // the circuit can't exceed MAX_FEET_DIFF_SIZES feet.
    if (row.groundSize !== row.size && length >= MAX_FEET_DIFF_SIZES) {
      throw new TemplateValidationError(
        'Circuits 250 ft or longer must have same size grounds as phase conductors.'
      );
    }

    const availableSizes = getAvailableCircuitSizes(
      row.metal,
      row.insulation,
      catalog
    );
    const phaseIndex = availableSizes.indexOf(row.size);
    const groundIndex = availableSizes.indexOf(row.groundSize);

    // Since the sizes use different measurements, we can't compare them directly.
    // So we sort the sizes by diameter and then here we're checking if the two sizes
    // are within 10 units of each other using their index positions.
    const exceedsAcceptableSizeRange =
      Math.abs(phaseIndex - groundIndex) > MAX_SIZE_DIFF;

    if (phaseIndex < 0 || groundIndex < 0 || exceedsAcceptableSizeRange) {
      throw new TemplateValidationError(
        'Ground size exceeds maximum size difference.'
      );
    }

    const groundMetal = row.metal;
    const groundConductor = getGroundInfo(row, groundMetal, catalog)
      .groundConductor;
    // add `isGroundConductor` and `groundMetal` prop to ground on parallel
    groundConductor.isGroundConductor = true;
    groundConductor.groundMetal = groundMetal;
    baseCircuit.conductors.push(groundConductor);

    products = getProductsForCircuit(baseCircuit, catalog);
    Object.assign(baseCircuit, {
      skus: getSkus(products),
      weight: getWeight(products, baseCircuit, job),
      volume: getVolume(products, baseCircuit, job)
    });

    for (var i = 0; i < row.runs; i++) {
      quantityCount++;
      Object.assign(baseCircuit, {
        dateCreated: now - quantityCount
      });
      circuits[baseCircuit.dateCreated] = baseCircuit;
    }
    return circuits;
  }, {});
}
