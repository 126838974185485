import React from 'react';

const ReelBar = props => {
  const percent = Math.min(100, Math.round(props.value * 100)) + '%';
  return (
    <div className="reel-bar">
      <div className="display">{percent}</div>
      <div className="background-bar">
        <div className="front-bar" style={{ width: percent }} />
      </div>
    </div>
  );
};

export default ReelBar;
