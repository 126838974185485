import React, { Component } from 'react';
import TemplateValidationError from './TemplateValidationError';
import formatTemplateData from './templateFormatter';

/**
 * Validate the file format.
 * The only valid file format is .xlsm
 *
 * @return {Boolean}
 */
const isValidFileFormat = file => {
  const name = file['name'] || '';

  return name.indexOf('.xlsm') !== -1;
};

/**
 * Validates the circuits pulled in from the template
 * are valid.
 *
 * @param  {Array}
 * @return {Boolean}
 */
const isValidTemplate = circuits => {
  return circuits.every(circuit => {
    return !!(
      circuit.from &&
      circuit.from.length < 16 &&
      circuit.to &&
      circuit.to.length < 16 &&
      circuit.runs &&
      Number.isInteger(circuit.runs) &&
      circuit.simpull !== undefined &&
      circuit.numberOfConductors &&
      circuit.metal &&
      circuit.size &&
      circuit.insulation &&
      circuit.colors.length &&
      circuit.lengthField &&
      Number.isInteger(circuit.lengthField) &&
      circuit.lengthField < 1000000
    );
  });
};

/**
 * Check if a product exists for each color variant for the circuit.
 *
 * If at least ONE variant does not have a corresponding product, we
 * should fail the entire circuit.
 *
 * @param  {Array}  products
 * @param  {Object} circuit
 * @return {Boolean}
 */
const doProductsExist = (products, circuit) => {
  const { colors } = circuit;

  for (let i = 0; i < colors.length; i++) {
    const exists = products.filter(
      product =>
        product.metal === circuit.metal &&
        product.product_type === circuit.insulation &&
        product.size === circuit.size &&
        product.color === colors[i]
    );

    if (!exists.length) {
      return false;
    }
  }

  // If we got here that means all circuit/color variants
  // had a corresponding product in the catalog.
  return true;
};

class UploadTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false
    };
  }

  /**
   * Returns a list of the circuits that are invalid from this template.
   * If no circuits were invalid, return empty array.
   *
   * @param  {Object}
   * @return {Array}
   */
  getInvalidCircuits(circuits) {
    const products = this.props.catalog.products.items;

    return circuits.filter(circuit => {
      // If there's at least one missing product for this circuit,
      // we should add it to our list of invalid circuits and fail the upload.
      return !doProductsExist(products, circuit);
    });
  }

  /**
   * File upload event handler.
   */
  handleUpload = event => {
    const file = event.target.files[0];
    if (!file) {
      this.props.onError('The uploaded file is not valid.');
      return;
    }

    if (!isValidFileFormat(file)) {
      this.props.onError('The uploaded file format is not supported.');
      return;
    }

    const { job } = this.props;
    this.setState({ processing: true });

    import('./templateParser')
      .then(({ default: parse }) => parse(file))
      .then(({ country, data }) => {
        // error - template country does not match job country
        if (country !== job.country) {
          throw new TemplateValidationError(
            'The country defined in the template does not match your job.'
          );
        }

        // error - empty template
        if (!data.length) {
          throw new TemplateValidationError(
            'The uploaded file has required fields that are not valid.'
          );
        }

        // error - incomplete circuits in template
        if (!isValidTemplate(data)) {
          throw new TemplateValidationError(
            'The uploaded file has required fields that are not valid.'
          );
        }

        // error - template has circuits that do not match product catalog
        const invalidCircuits = this.getInvalidCircuits(data);
        if (invalidCircuits.length) {
          const count = invalidCircuits.length;
          const verb = count === 1 ? 'is' : 'are';
          throw new TemplateValidationError(
            `${count} of your circuits ${verb} currently unavailable.` +
              ' Please enter your circuits in the configurator or revise your template.'
          );
        }

        // ---------
        // Success
        // ---------

        const formattedData = formatTemplateData(
          data,
          this.props.catalog,
          this.props.job
        );
        this.setState({ processing: false });
        this.props.onSuccess(formattedData);
      })
      .catch(error => {
        // Only use error.message if we ran into a known validation error.
        // Otherwise default to a generic message.
        const message =
          error instanceof TemplateValidationError
            ? error.message
            : 'The template could not be uploaded. Please contact Southwire.';

        this.setState({ processing: false });
        this.props.onError(message);
      });
  };

  /**
   * Chrome and Safari both treat a file input's onChange differently than Firefox.
   *
   * When you attempt to re-upload a file with the same name, Chrome and Safari both will
   * ignore the second upload attempt. This appears to the end user as though nothing happened.
   *
   * To fix, we clear out event.target.value every time you click the input field.
   * That way, each time you attempt to upload a file it appears as though it's the first time.
   */
  onClickHandler = event => {
    event.target.value = null;
  };

  render() {
    return (
      <div className={`upload ${this.state.processing ? 'processing' : ''}`}>
        <input
          type="file"
          id="upload-file"
          onChange={this.handleUpload}
          onClick={this.onClickHandler}
        />
        Upload Template
      </div>
    );
  }
}

/**
 * Props:
 *   catalog   {Object}
 *   job       {Object}
 *   onSuccess {Function} - calls firebase to add the circuits
 *   onError   {Function} - displays a toast error message
 */
export default UploadTemplate;
