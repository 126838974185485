import React from 'react';

const ThreeReels = () => (
  <table className="reel-config-table">
    <thead>
      <tr>
        <td>Reel 1</td>
        <td>Reel 2</td>
        <td>Reel 3</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>One Conductor</td>
        <td>One Conductor</td>
        <td>One Conductor</td>
      </tr>
    </tbody>
  </table>
);

const TwoReels = () => (
  <table className="reel-config-table">
    <thead>
      <tr>
        <td>Reel 1</td>
        <td>Reel 2</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Two Conductors</td>
        <td>One Conductor</td>
      </tr>
    </tbody>
  </table>
);

const OneReel = () => (
  <table className="reel-config-table">
    <thead>
      <tr>
        <td>Reel 1</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Three Conductors</td>
      </tr>
    </tbody>
  </table>
);

const ReelConfigTable = ({ type }) => {
  switch (type) {
    case '1/C':
      return <ThreeReels />;
    case '2/C':
      return <TwoReels />;
    case '3/C':
      return <OneReel />;
    default:
      return null;
  }
};

export default ReelConfigTable;
