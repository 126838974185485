import { findSmallestReel, getUpdatedReelProduct } from './reelUtil';
import productFinder from './productFinder';
import cloneDeep from 'lodash/cloneDeep';

const SIM_61 = 'SIM61';

/**
 * Automatically place the provided circuits onto reels.
 * Each reel will be automatically created using existing job restrictions.
 */
export default class ReelAutoBuilder {
  /**
   * @param  {Array}   circuits - array of circuit IDs
   * @param  {Object}  job      - job settings object
   * @param  {Boolean} isGround - are we dealing with ground circuits?
   * @return {Array}   reels    - the new reels to be persisted
   */
  constructor(circuits, job, catalog, isGround = false, count = 1) {
    this.isGround = isGround;
    this.circuits = circuits;
    this.job = job;
    this.catalog = catalog;

    // The count used in the reel name.
    this.count = count;

    // Save a local copy of all the existing reel names so we can prevent duplicate names.
    this.reelNames = this.job.reels.map(r => r.name);

    // The index position of the reel we are currently adding circuits to.
    this.currentReelIndex = 0;

    // Our collection of auto-built reels. We will always start with 1 reel.
    this.reels = [this.createReel()];
  }

  /**
   * Entrypoint, starts the auto-build process.
   *
   * If successful, return the new collection of reels.
   * If we run into any issues with adding the selected circuits to reels,
   * return false to abort the process.
   *
   * @return {Array|Boolean}
   */
  run() {
    const allCircuitsAdded = this.circuits.every(this.addCircuit);

    // If we've successfully added all the circuits, return our final reels collection and the reel count.
    // reel count is returned to maintain the count between the different ground groups
    // Otherwise return false to indicate something bad happened.
    const result = { reels: this.reels, reelCount: this.count };
    return allCircuitsAdded ? result : false;
  }

  /**
   * Add the circuit to the current reel.
   * If it doesn't fit on the current reel, create a new reel and try to add the circuit to that one.
   *
   * @param  {String} circuitId
   * @return {Boolean} - Return false if we couldn't add this circuit to the current reel OR a brand new one.
   */
  addCircuit = circuitId => {
    // Doing a deep clone of the current reel so that we can
    // add the new circuit and test that it supports the load
    // without modifying the original reel.
    const reel = cloneDeep(this.reels[this.currentReelIndex]);

    const updatedReel = this.getUpdatedReel(reel, circuitId);

    // The reel either accepted the new circuit or got bumped up to the next size.
    // Either way, let's update our reel reference and move on.
    if (updatedReel !== null) {
      return this.updateCurrentReel(updatedReel);
    }

    // Our current reel no longer supports the circuits we're giving it.
    // Let's create a new one and try to add the circuit again.
    this.currentReelIndex++;
    const newReel = this.getUpdatedReel(this.createReel(), circuitId);

    // The new reel supports our circuit! We're good to go, lets move on.
    if (newReel !== null) {
      return this.updateCurrentReel(newReel);
    }

    // A brand new reel doesn't support this circuit load. Something might be
    // wrong with the circuit. We should fail immediately and let the user resolve this issue.
    return false;
  };

  /**
   * Create a brand new reel.
   * If it's a SIMpull job, we should only use SIMpull reels (SIM61).
   *
   * @return {Object}
   */
  createReel() {
    const now = Date.now();

    let reelProduct;
    if (this.job.simpull) {
      reelProduct = productFinder.normalizeReelProduct({
        ...this.catalog.simreels[SIM_61],
        name: SIM_61,
        id: SIM_61
      });
    } else {
      reelProduct = productFinder.normalizeReelProduct(
        findSmallestReel(this.catalog.reels.items, this.job.metric).reelProduct
      );
    }

    return {
      name: this.getReelName(),
      simpull: this.job.simpull || false,
      currentWeight: 0,
      currentVolume: 0,
      dateCreated: now,
      dateModified: now,
      reelProduct,
      circuits: [],
      restrictions: {
        height: (this.job.restrictions && this.job.restrictions.height) || '',
        width: (this.job.restrictions && this.job.restrictions.width) || '',
        weight: (this.job.restrictions && this.job.restrictions.weight) || ''
      }
    };
  }

  /**
   * Figure out the most appropriate reel name.
   * Follow the template: "Auto X", prevent duplicate reel names.
   *
   * @return {String}
   */
  getReelName() {
    const groundSuffix = this.isGround ? ' (G)' : '';

    let name = `Auto ${this.count + groundSuffix}`;

    while (this.reelNames.indexOf(name) !== -1) {
      this.count++;
      name = `Auto ${this.count + groundSuffix}`;
    }

    this.reelNames.push(name);
    return name;
  }

  /**
   * Add the circuit to the reel and attempt to find a more appropriate reel product.
   *
   * @param  {Object} reel
   * @param  {String} circuitId
   * @return {Object|Boolean}
   */
  getUpdatedReel(reel, circuitId) {
    reel.circuits.push({
      id: circuitId,
      colorKey: reel.circuits.length + 1
    });

    return getUpdatedReelProduct(
      reel,
      this.job.circuits,
      this.catalog,
      this.catalog.simreels
    );
  }

  /**
   * Update the current reel reference to include any new circuits we've added.
   *
   * @param  {Object} reel
   * @return {Boolean}
   */
  updateCurrentReel(reel) {
    this.reels[this.currentReelIndex] = reel;

    return true;
  }
}
