/**
 * Lifted straight from https://github.com/sindresorhus/array-move
 *
 * The array-move package, as an external dependency, can not be compiled
 * in the production build due to an issue with the minifier not being able to process
 * modern javascript. This can be changed back to referencing the external package
 * once the build process is updated
 *
 * https://facebook.github.io/create-react-app/docs/troubleshooting#npm-run-build-fails-to-minify
 */
const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

export default arrayMove;
