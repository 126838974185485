import React from 'react';
import Input from '../../patterns/Input';
import Toggle from '../../patterns/Toggle';
import Button from '../../patterns/Button';
import Dropdown from '../../patterns/Dropdown';
import JobRestrictionsInputs from '../../patterns/JobRestrictionsInputs';
import SimPull from '../../patterns/SimPull';
import productFinder from '../../utils/productFinder';
import { findSmallestReel, findSmallestSimreel } from '../../utils/reelUtil';

const NAME_MAX_LENGTH = 30;

export default class CreateReelForm extends React.Component {
  static defaultProps = {
    saving: false
  };

  constructor(props) {
    super(props);

    const job = props.job;
    this.state = {
      name: '',
      message: '',
      error: false,
      disabled: true,
      simreelSize: 'SIM61',
      isRestrictionValid: true,
      simpull: job.simpull || false,
      width: (job.restrictions && job.restrictions.width) || '',
      height: (job.restrictions && job.restrictions.height) || '',
      weight: (job.restrictions && job.restrictions.weight) || ''
    };
  }

  /**
   * Event handler for when we update one of the restriction fields (width, height, weight).
   *
   * @param {Object} updatedRestriction
   */
  onRestrictionChange = updatedRestriction => {
    this.setState({ ...updatedRestriction });
  };

  /**
   * Event handler for toggling the toggle.
   */
  onToggleChange = () => {
    this.setState({ simpull: !this.state.simpull });
  };

  /**
   * Event handler for updating the reel name.
   *
   * @param {Object} event
   */
  onNameChange = event => {
    const name = event.target.value;

    if (name.length > NAME_MAX_LENGTH) {
      this.setState({
        name,
        error: true,
        message: 'Reel name cannot exceed 30 characters.',
        disabled: true
      });
      return;
    }

    this.setState({
      name,
      error: false,
      message: '',
      disabled: name.length === 0
    });
  };

  /**
   * Event handler for updating the simreel size.
   *
   * @param {String} simreelSize
   */
  onSimreelSizeChange = simreelSize => {
    this.setState({ simreelSize });
  };

  /**
   * Do some additional form validation and construct the new
   * reel object. Call props.onSubmit for final persistence.
   *
   * @param {Object} event
   */
  submitReel = event => {
    event.preventDefault();

    const { simpull, height, width, weight } = this.state;
    const { reels } = this.props.job;
    const name = this.state.name.trim();
    const now = Date.now();

    // Check for no name after trimming empty white space.
    if (!name) {
      this.setState({
        name: '',
        error: true,
        disabled: true,
        message: 'Please provide a name.'
      });
      return;
    }

    // Check for duplicate names.
    const existingReelNames = reels.map(reel => reel.name);
    if (existingReelNames.indexOf(name) !== -1) {
      this.setState({
        error: true,
        message: 'Reel name already exists.',
        disabled: true
      });
      return;
    }

    this.props.onSubmit({
      name,
      simpull,
      reelProduct: this.getReelProduct(),
      currentWeight: 0,
      currentVolume: 0,
      dateCreated: now,
      dateModified: now,
      restrictions: { height, width, weight }
    });
  };

  /**
   * Get the reel product object that we should use for this reel.
   * If it's a simreel, use the size chosen by the user.
   * If it's a normal reel, find the smallest possible reel size.
   *
   * @return {Object}
   */
  getReelProduct() {
    const { reelTypes, simreelTypes, job } = this.props;
    const { simpull, simreelSize } = this.state;

    if (simpull) {
      return productFinder.normalizeReelProduct({
        ...simreelTypes[simreelSize],
        name: simreelSize,
        id: simreelSize
      });
    }

    return productFinder.normalizeReelProduct(
      findSmallestReel(reelTypes, job.metric).reelProduct
    );
  }

  isFormValid() {
    return (
      this.state.isRestrictionValid &&
      !this.state.error &&
      !this.props.saving &&
      !this.state.disabled
    );
  }

  render() {
    const {
      width,
      height,
      weight,
      name,
      message,
      error,
      simpull,
      simreelSize
    } = this.state;
    const { job, saving, simreelTypes } = this.props;
    const { metric, country } = job;

    const simpullToggleName = [<SimPull key="simpull2" />, ' Reel'];
    const simpullDisclaimer = [
      'By selecting this option, you will have to submit your quote through a ',
      <SimPull key="simpull" />,
      ' Partner Distributor.'
    ];

    const simreelSizes = Object.keys(simreelTypes).map(type => ({
      value: type,
      label: type
    }));

    // If the user chooses a SIMreel, pull out the one they select
    // so we can pass that along to JobRestrictionsInput to properly
    // validate height/width/weight.
    let selectedReel = null;
    if (simpull) {
      selectedReel = findSmallestSimreel(
        { [simreelSize]: simreelTypes[simreelSize] },
        metric
      );
    }

    return (
      <form className="job-reel-form" onSubmit={this.submitReel}>
        <Input
          required
          autoFocus
          type="text"
          value={name}
          isError={error}
          label="Reel Name"
          errorMessage={message}
          onChange={this.onNameChange}
        />

        <div className="restrictions">
          <p className="title">Reel Restrictions</p>
          <div className="disclaimer">
            <div className="info" />
            Update these settings to override your jobsite and equipment
            restrictions on this reel.
          </div>
        </div>

        <JobRestrictionsInputs
          metric={metric}
          country={country}
          selectedReel={selectedReel}
          jobSettings={{ height, width, weight, simpull }}
          handleRestrictionUpdate={this.onRestrictionChange}
        />

        <Toggle
          info="true"
          checked={simpull}
          name={simpullToggleName}
          onChange={this.onToggleChange}
          disclaimer={simpullDisclaimer}
        />

        {simpull && (
          <Dropdown
            required
            label="Size"
            id="simreel-size"
            value={simreelSize}
            options={simreelSizes}
            onSelect={this.onSimreelSizeChange}
            placeholder="Please Select"
          />
        )}

        <div className="action-buttons">
          <Button className="secondary" onClick={this.props.returnToList}>
            Cancel
          </Button>
          <Button type="submit" disabled={!this.isFormValid()} saving={saving}>
            Create
          </Button>
        </div>
      </form>
    );
  }
}
