import React from 'react';

class SimPull extends React.Component {
  render() {
    return (
      <span style={{ textTransform: 'none' }}>
        SIM<i>pull</i>
        {this.props.trademark === false ? '' : ' ™'}
      </span>
    );
  }
}

export default SimPull;
