import React from 'react';
import Button from '../patterns/Button';
import Input from '../patterns/Input';
import Radio from '../patterns/Radio';
import JobRestrictionsInputs from '../patterns/JobRestrictionsInputs';
import SimPull from '../patterns/SimPull';
import Toggle from '../patterns/Toggle';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../common/withNotFoundRedirect';

export class JobSettings extends React.Component {
  state = {
    jobName: '',
    jobType: '',
    message: '',
    error: false,
    disabled: false,
    height: '',
    width: '',
    weight: '',
    isAFrame: false,
    simpull: false,
    saving: false,
    isRestrictionValid: true
  };

  // handle updating state after refreshing job settings screen
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.jobs !== this.props.jobs &&
      typeof nextProps.jobs === 'object'
    ) {
      const job = nextProps.jobs[this.props.match.params.jobId];
      this.updateJobState(job);
    }
  }

  // handle updating state after navigating to job settings
  componentDidMount() {
    const job =
      this.props.jobs && this.props.jobs[this.props.match.params.jobId];
    if (job) {
      this.updateJobState(job);
    }
  }

  updateJobState(job) {
    if (job.quoteRequested) this.props.history.goBack(); // prevent editing submitted job
    // jobs created before jobType was saved on the job are assumed to be 600v
    const jobType = job.jobType || '600V';
    const reelOption =
      jobType === '600V'
        ? { simpull: job.simpull }
        : { isAFrame: job.isAFrame };

    this.setState({
      jobName: job.name,
      jobType: jobType,
      height: job.restrictions.height,
      width: job.restrictions.width,
      weight: job.restrictions.weight,
      ...reelOption
    });
  }

  handlejobName = e => {
    let jobName = e.target.value;
    this.setState({ jobName: jobName });
    // using event target value instead of state bc calculates length immediately, state lags
    if (jobName.length <= 30) {
      this.setState({ message: '', disabled: false, error: false });
    } else {
      // disable submit button if length exceeds 30 chars
      this.setState({
        message: 'Job name cannot exceed 30 characters.',
        disabled: true,
        error: true
      });
    }
    // set disabled back to true if user types and deletes all characters
    if (jobName.length === 0) {
      this.setState({ disabled: true, error: false });
    }
  };

  handleToggle = name => {
    return event => {
      this.setState({ [name]: !this.state[name] });
    };
  };

  handleRestrictionUpdate = updatedRestriction => {
    this.setState({ ...updatedRestriction });
  };

  isFormValid = () => {
    return (
      this.state.isRestrictionValid &&
      !this.state.error &&
      !this.state.saving &&
      !this.state.disabled
    );
  };

  returnToList = () => {
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}`,
      null
    );
  };

  handleSave = e => {
    e.preventDefault();
    const { height, width, weight, simpull, isAFrame, jobType } = this.state;
    let jobName = this.state.jobName.trim();
    let userId = this.props.firebase.auth().currentUser.uid;
    let projectId = this.props.match.params.projectId;
    let jobs = this.props.jobs || {};
    // job name already exists if it matches a name of a job that is visible and is not the current job being updated
    let match = Object.keys(jobs).some(jobId => {
      const job = jobs[jobId];
      return (
        job.visible &&
        job.name === jobName &&
        jobId !== this.props.match.params.jobId
      );
    });

    if (!match) {
      const reelOption = jobType === '600V' ? { simpull } : { isAFrame };
      this.setState({ saving: true });
      const updatedJob = {
        name: jobName,
        userId: userId,
        projectId: projectId,
        restrictions: { height: height, width: width, weight: weight },
        dateModified: Date.now(),
        ...reelOption
      };

      this.props.firestore
        .update(`jobs/${this.props.match.params.jobId}`, updatedJob)
        .then(() => {
          this.setState({ saving: false });
          this.returnToList();
        })
        .catch(error => {
          // if error, display error message
          this.setState({ saving: false });
          this.setState({ message: error.message });
        });
    } else {
      this.setState({ message: 'Job name already exists.', error: true });
    }
  };

  renderReelOption() {
    const simpullDisclaimer = [
      'By selecting this option, you will have to submit your quote through a SIM',
      <span key={0}>
        <i>pull</i>&nbsp;&#8482;
      </span>,
      ' Partner Distributor.'
    ];

    if (this.state.jobType === '600V') {
      return (
        <Toggle
          name={[<SimPull key="simpull2" />, ' Reel']}
          info="true"
          disclaimer={simpullDisclaimer}
          checked={this.state.simpull}
          onChange={this.handleToggle('simpull')}
          containerName={'simpull-toggle'}
        />
      );
    }
    return (
      <Toggle
        name="Use A-Frames"
        checked={this.state.isAFrame}
        onChange={this.handleToggle('isAFrame')}
        containerName={'isAFrame-toggle'}
      />
    );
  }

  render() {
    const jobSettings = {
      height: this.state.height,
      width: this.state.width,
      weight: this.state.weight,
      simpull: this.state.simpull
    };
    const job =
      this.props.jobs && this.props.jobs[this.props.match.params.jobId];
    const metric = (job && job.metric) || false;
    const country = (job && job.country) || 'us';

    return (
      <div className="project job">
        <h2 className="page-title">Edit Job Settings</h2>

        <form className="job-reel-form" onSubmit={this.handleSave}>
          <Input
            label="Job Name*"
            className="job-name"
            type="text"
            placeholder="Name"
            onChange={this.handlejobName}
            value={this.state.jobName}
            autoComplete="name"
            errorMessage={this.state.message}
            isError={this.state.error}
          />
          <p className="title">Job Type</p>
          <div className="job-type">
            <Radio
              label="600V"
              name="jobType"
              value="600V"
              checked={this.state.jobType === '600V'}
              disabled
            />
            <Radio
              label="Medium Voltage"
              name="jobType"
              value="MV"
              checked={this.state.jobType === 'MV'}
              disabled
            />
          </div>
          <div className="restrictions">
            <p className="title">Jobsite and Equipment Restrictions</p>
            <div className="body">
              <div className="info" />
              <p>
                You can override your jobsite and equipment restrictions when
                you are configuring your individual reels.
              </p>
            </div>
          </div>
          <JobRestrictionsInputs
            handleRestrictionUpdate={this.handleRestrictionUpdate}
            setRestrictionError={this.setRestrictionError}
            jobSettings={jobSettings}
            metric={metric}
            country={country}
            jobType={this.state.jobType}
          />
          {this.renderReelOption()}
          <div className="action-buttons">
            <Button onClick={this.returnToList} className="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!this.isFormValid()}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(({ firebase: { profile }, firestore: { data } }, props) => ({
    profile,
    projects: data.projects,
    jobs: data[`jobs-${props.match.params.projectId}`]
  })),
  withNotFoundRedirect('jobId', 'jobs')
)(JobSettings);
