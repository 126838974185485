import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import dateFormat from '../utils/dateFormat';
import Overflow from '../patterns/Overflow';
import Table from '../patterns/Table';

export class ProjectsHome extends React.Component {
  render() {
    return (
      <div className="project">
        <div className="page-title lb">
          <h2>Projects</h2>

          <Link className="action create" to="/projects/create">
            Create Project
          </Link>
        </div>

        {this.renderProjectList()}
      </div>
    );
  }

  renderProjectList() {
    const { projects } = this.props;
    if (projects === undefined) {
      return null;
    }

    if (projects === null) {
      return (
        <div className="empty-container">
          <img
            src="/assets/img/no-projects.svg"
            width="715"
            height="376"
            alt=" "
          />
          <p className="important-message">
            You haven’t started any projects yet.
          </p>
        </div>
      );
    }

    const columns = [
      { field: 'project-name', label: 'Project Name' },
      { field: 'created', label: 'Created' },
      { field: 'jobs', label: 'Jobs' },
      { field: 'expand-menu' }
    ];

    const data = Object.keys(projects)
      .map(id => ({ ...projects[id], id }))
      .sort((a, b) => (b.dateCreated || 0) - (a.dateCreated || 0))
      .map(project => {
        return {
          row: (
            <tr
              key={project.id}
              className="link"
              onClick={e => this.goToProject(e, project.id)}
            >
              <td>
                <Link to={`/projects/${project.id}`}>{project.name}</Link>
              </td>
              <td>
                {project.dateCreated &&
                  dateFormat.mmddyyyy(project.dateCreated)}
              </td>
              <td>{project.jobCount || '0'}</td>
              <td className="overflow-cell">
                <Overflow>
                  <li>
                    <Link to={`/projects/${project.id}/settings`}>
                      Edit Settings
                    </Link>
                  </li>
                </Overflow>
              </td>
            </tr>
          )
        };
      });

    return <Table id="projects-table" columns={columns} data={data} />;
  }

  goToProject(e, id) {
    if (e.target.tagName === 'A' || e.target.classList.contains('overflow')) {
      return;
    }

    this.props.history.push(`/projects/${id}`, null);
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      }
    ];
  }),
  connect(({ firebase: { auth }, firestore: { data } }) => ({
    projects: data.projects,
    auth
  }))
)(ProjectsHome);
