const colorSets = {
  us: {
    three: [
      ['Black', 'Red', 'Blue'],
      ['Brown', 'Orange', 'Yellow'],
      ['Brown', 'Purple', 'Yellow'],
      ['Pink', 'Purple', 'Tan'],
      ['Black', 'Black', 'Black']
    ],
    four: [
      ['Black', 'Red', 'Blue', 'White'],
      ['Brown', 'Orange', 'Yellow', 'Gray'],
      ['Brown', 'Purple', 'Yellow', 'Gray'],
      ['Pink', 'Purple', 'Tan', 'Gray'],
      ['Black', 'Black', 'Black', 'Black']
    ]
  },
  ca: {
    three: [
      ['Black', 'Red', 'Blue'],
      ['Brown', 'Orange', 'Yellow'],
      ['Black', 'Black', 'Black']
    ],
    four: [
      ['Black', 'Red', 'Blue', 'White'],
      ['Black', 'Black', 'Black', 'Black']
    ]
  }
};

export default colorSets;
