import React, { Component } from 'react';
import { showToast } from '../actions/ToastActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { getUpdatedReelProduct } from '../utils/reelUtil';
import Modal from './Modal';
import { cloneDeep, forEach } from 'lodash';

/**
 * This modal is used for both deleting a single circuit (FeederSchedule)
 * and deleting multiple circuits (FeederSummary multi-select)
 */
export class DeleteCircuit extends Component {
  state = { saving: false };

  onClickDelete = () => {
    this.setState({ saving: true });
    let deleteCircuitObj = this.props.payload;
    const job = this.props.jobs[deleteCircuitObj.jobId];
    const circuits = job && cloneDeep(job.circuits);
    const reels = job.reels;
    const catalog = this.props.catalogs[job.country || 'us'];
    const simreels = this.props.catalogs.simreels;
    const circuitsOnReels = reels.some(
      reel => reel.circuits && reel.circuits.length > 0
    );

    deleteCircuitObj.circuitIds.forEach(id => {
      // We need to check each circuit to see if it
      // has an association with the circuits we're about to delete.
      forEach(circuits, circuit => {
        if (
          circuit.mainCircuitId &&
          circuit.mainCircuitId === parseInt(id, 10)
        ) {
          delete circuit.mainCircuitId;
          delete circuit.isGroundCircuit;
        }

        if (
          circuit.groundCircuitId &&
          circuit.groundCircuitId === parseInt(id, 10)
        ) {
          delete circuit.groundCircuitId;
        }
      });

      // Now that the associations have been updated, we can delete the circuit.
      delete circuits[id];
    });

    // if a there are circuits on any reels, go through each reel and update reel product
    let firestoreUpdateObj = { circuits };
    if (circuitsOnReels) {
      const updatedReels = reels.map(reel => {
        // new empty reels do not have 'circuits' prop
        const reelWillUpdate =
          reel.circuits &&
          reel.circuits.some(
            circuit => deleteCircuitObj.circuitIds.indexOf(circuit.id) !== -1
          );
        // if the reel doesnt have any circuits that are going to be deleted, return original reel
        if (!reelWillUpdate) {
          return reel;
        }
        let updatedReel = {
          ...reel
        };
        if (updatedReel.circuits.length) {
          updatedReel.circuits = updatedReel.circuits.filter(circuit => {
            return deleteCircuitObj.circuitIds.indexOf(circuit.id) < 0;
          });
        }
        if (updatedReel.circuits && updatedReel.circuits.length === 0) {
          updatedReel.circuitType = '';
        }
        const updatedReelProduct = getUpdatedReelProduct(
          updatedReel,
          circuits,
          catalog,
          simreels
        );
        return updatedReelProduct;
      });
      firestoreUpdateObj = { circuits, reels: updatedReels };
    }

    this.props.firestore
      .update(`jobs/${deleteCircuitObj.jobId}`, firestoreUpdateObj)
      .then(() => {
        // success
        this.setState({ saving: false });
        this.props.onCancel();
        if (deleteCircuitObj.onSuccess) {
          deleteCircuitObj.onSuccess();
        }
      })
      .catch(error => {
        // error
        this.setState({ saving: false });
        this.props.onCancel();
        const circuitForm =
          deleteCircuitObj.circuitIds.length === 1 ? 'Circuit' : 'Circuits';
        this.props.showToast(`${circuitForm} could not be deleted`, 'error');
      });
  };

  render() {
    const { payload, onCancel } = this.props;
    const isPlural = payload.circuitIds.length > 1;

    const message = `Are you sure you want to delete ${
      payload.circuitIds.length
    } ${isPlural ? 'circuits' : 'circuit'}?`;

    return (
      <Modal
        message={message}
        onCancel={onCancel}
        onConfirm={this.onClickDelete}
        loading={this.state.saving}
        confirmText="Confirm"
      />
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [];
  }),
  connect(
    ({ firestore: { data } }, props) => ({
      catalogs: data.catalogs,
      jobs: data[`jobs-${props.payload.projectId}`]
    }),
    dispatch => ({
      showToast: (message, messageType) => {
        dispatch(showToast(message, messageType));
      }
    })
  )
)(DeleteCircuit);
