import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';

const ExpandCollapse = ({ onClick, expanded }) => {
  const tooltip = expanded ? 'Collapse All' : 'Expand All';
  const type = expanded ? 'collapse' : 'expanded';

  return <IconButton type={type} onClick={onClick} tooltipText={tooltip} />;
};

ExpandCollapse.propTypes = {
  onClick: PropTypes.func,
  expanded: PropTypes.bool
};

ExpandCollapse.defaultProps = {
  expanded: false
};

export default ExpandCollapse;
