const ActionTypes = {
  SHOW_DELETE_CIRCUIT_MODAL: 'SHOW_DELETE_CIRCUIT_MODAL',
  SHOW_DELETE_MV_CIRCUITS_MODAL: 'SHOW_DELETE_MV_CIRCUITS_MODAL',
  SHOW_DELETE_JOB_MODAL: 'SHOW_DELETE_JOB_MODAL',
  SHOW_REMOVE_CIRCUIT_MODAL: 'SHOW_REMOVE_CIRCUIT_MODAL',
  SHOW_DELETE_REEL_MODAL: 'SHOW_DELETE_REEL_MODAL',
  SHOW_EDIT_CIRCUITS_MODAL: 'SHOW_EDIT_CIRCUITS_MODAL',
  SHOW_EDIT_MV_CIRCUITS_MODAL: 'SHOW_EDIT_MV_CIRCUITS_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',

  SHOW_TOAST: 'SHOW_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
  RESET_TOAST_PASS: 'RESET_TOAST_PASS',

  CHANGE_SELECTED_REEL: 'CHANGE_SELECTED_REEL',

  RESET_OVERFLOW_CLOSE_FUNCTION: 'RESET_OVERFLOW_CLOSE_FUNCTION',
  SET_OVERFLOW_CLOSE_FUNCTION: 'SET_OVERFLOW_CLOSE_FUNCTION'
};

export default ActionTypes;
