import React from 'react';
import EmptySmall from '../patterns/EmptySmall';
import ReelSummaryCircuitItem from './ReelSummaryCircuitItem';
import Table from '../patterns/Table';

const ReelSummaryCircuits = props => {
  const { reel, circuits, metric, catalog } = props;

  if (!reel.circuits || !reel.circuits.length) {
    return (
      <div className="reel-summary-circuits">
        <EmptySmall type="circuits-on-reel" />
      </div>
    );
  }

  const columns = [
    { field: 'from', label: 'From' },
    { field: 'to', label: 'To' },
    { field: 'size', label: 'Size' },
    { field: 'length', label: 'Length' },
    { field: 'expand-menu' }
  ];

  const data = reel.circuits.map(circuit => ({
    row: (
      <ReelSummaryCircuitItem
        metric={metric}
        catalog={catalog}
        colorKey={circuit.colorKey}
        circuit={circuits[circuit.id]}
        key={`reel-circuit-${circuit.id}`}
      />
    )
  }));

  return (
    <div className="reel-summary-circuits">
      <Table columns={columns} data={data} />
    </div>
  );
};

export default ReelSummaryCircuits;
