import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import EmptyFeederSummary from '../../common/EmptyFeederSummary';
import Table from '../../patterns/Table';
import ExpandCollapse from '../../patterns/ExpandCollapse';
import Checkbox from '../../patterns/Checkbox';
import SummaryTableItem from './SummaryTableItem';
import IconButton from '../../patterns/IconButton';

class SummaryTable extends Component {
  state = {
    expandedCircuits: [],
    selectedCircuits: [],
    lastDuplicatedCircuits: [],
    tableCheckboxIsSelected: false
  };

  /**
   * When you select a circuit row using multi-select.
   *
   * @param {Object} event
   * @param {Number} circuitId
   */
  onSelectCircuit = (e, circuitId) => {
    const selectedCircuits = this.state.selectedCircuits;
    if (e.target.checked) {
      this.setState({
        selectedCircuits: [...selectedCircuits, circuitId],
        tableCheckboxIsSelected: true
      });
      return;
    }

    const updatedCircuits = selectedCircuits.filter(cId => cId !== circuitId);

    this.setState({
      selectedCircuits: updatedCircuits,
      ...(updatedCircuits.length === 0 && { tableCheckboxIsSelected: false })
    });
  };

  /**
   * Event handler for toggling the table-wide selection checkbox.
   * Selects all circuits or unselects all circuits.
   *
   * @param {Object} event
   */
  toggleTableCheckbox = event => {
    const { job } = this.props;

    let selectedCircuits = Object.keys(job.circuits);
    if (!event.target.checked) {
      selectedCircuits = [];
    }

    this.setState({
      tableCheckboxIsSelected: !this.state.tableCheckboxIsSelected,
      selectedCircuits
    });
  };

  /**
   * Expand an individual circuit row.
   *
   * @param {Number} circuitId,
   * @param {Boolean} expanded
   */
  toggleExpandCircuit = (circuitId, expanded) => {
    const expandedCircuits = [...this.state.expandedCircuits];

    if (expanded) {
      expandedCircuits.push(circuitId);
    } else {
      expandedCircuits.splice(expandedCircuits.indexOf(circuitId), 1);
    }

    this.setState({ expandedCircuits });
  };

  /**
   * Toggle expanding/un-expanding all circuit rows.
   */
  toggleExpandAll = () => {
    const { job } = this.props;
    let expandedCircuits = Object.keys(job.circuits);

    if (this.state.expandedCircuits.length) {
      expandedCircuits = [];
    }

    this.setState({ expandedCircuits });
  };

  /**
   * Open the delete circuits confirmation modal.
   */
  deleteCircuits = () => {
    this.props.onDeleteCircuits({
      circuitIds: this.state.selectedCircuits,
      onSuccess: () => {
        this.setState({
          selectedCircuits: [],
          tableCheckboxIsSelected: false
        });
      }
    });
  };

  /**
   * When user clicks duplicate button,
   * call `onDuplicateCircuits` then update state
   */
  duplicateCircuits = () => {
    const selectedCircuits = this.state.selectedCircuits;
    this.props.onDuplicateCircuits(selectedCircuits).then(duplicateCircuits => {
      const duplicateCircuitsIds = Object.keys(duplicateCircuits);
      this.setState({
        selectedCircuits: [...selectedCircuits, ...duplicateCircuitsIds],
        lastDuplicatedCircuits: duplicateCircuitsIds
      });
    });
  };

  onEditCircuits = () => {
    this.props.onEditCircuits({
      circuitIds: this.state.selectedCircuits
    });
  };

  render() {
    const { job, onChangeSort } = this.props;
    const showFooter = this.state.selectedCircuits.length > 0;

    if (isEmpty(job.circuits)) {
      return <EmptyFeederSummary />;
    }

    const columns = this.getTableColumns();
    const data = this.getTableData();

    return (
      <Table
        sortable
        data={data}
        columns={columns}
        id="mv-feeder-summary-table"
        onChangeSort={onChangeSort}
        sortPreference={job.feederSummarySortPreference || {}}
      >
        {showFooter && this.renderFooter()}
      </Table>
    );
  }

  renderFooter() {
    const { job } = this.props;
    const { selectedCircuits } = this.state;

    return (
      <tfoot>
        <tr>
          <td>
            <Checkbox
              id="footer-checkbox"
              checked={selectedCircuits.length > 0}
              onChange={this.toggleTableCheckbox}
              deselectMode={
                Object.keys(job.circuits).length !== selectedCircuits.length
              }
            />
          </td>
          <td>
            <div className="circuit-count">{selectedCircuits.length}</div>
            {selectedCircuits.length > 1 ? 'Circuits' : 'Circuit'} Selected
          </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td> </td>
          <td className="footer-icons">
            <IconButton
              onClick={this.deleteCircuits}
              type="delete"
              tooltipText="Delete Circuits"
            />
            <IconButton
              onClick={this.duplicateCircuits}
              type="duplicate"
              tooltipText="Duplicate Circuits"
            />
            <IconButton
              onClick={this.onEditCircuits}
              type="mass-edit"
              tooltipText="Mass Edit Circuits"
            />
          </td>
        </tr>
      </tfoot>
    );
  }

  getTableColumns = () => {
    const { job } = this.props;

    const columns = [
      { field: 'from', label: 'From', sortable: true },
      { field: 'to', label: 'To', sortable: true },
      { field: 'size', label: 'Size', sortable: true },
      { field: 'length', label: 'Length', sortable: true },
      { field: 'reel', label: 'Reel', sortable: true },
      {
        field: 'expand-menu',
        sortable: false,
        label: (
          <ExpandCollapse
            expanded={!!this.state.expandedCircuits.length}
            onClick={this.toggleExpandAll}
          />
        )
      }
    ];

    if (!job.quoteRequested) {
      columns.unshift({
        field: 'checkbox',
        label: (
          <Checkbox
            id="header-checkbox"
            onChange={this.toggleTableCheckbox}
            checked={this.state.tableCheckboxIsSelected}
            deselectMode={
              Object.keys(job.circuits).length !==
              this.state.selectedCircuits.length
            }
          />
        ),
        sortable: false
      });
    }

    return columns;
  };

  getTableData = () => {
    const { job } = this.props;
    const { selectedCircuits, lastDuplicatedCircuits } = this.state;

    return Object.keys(job.circuits).map(id => {
      const circuit = { ...job.circuits[id], id };
      const isSelected = selectedCircuits.indexOf(circuit.id) > -1;
      const reelName = (circuit.reel && circuit.reel.name) || '';
      const isEditable = !job.quoteRequested;
      const isDuplicated = lastDuplicatedCircuits.indexOf(circuit.id) > -1;

      return {
        fields: {
          from: circuit.from,
          to: circuit.to,
          size: circuit.diameter,
          length: circuit.length,
          reel: reelName,
          dateCreated: circuit.dateCreated
        },
        row: (
          <SummaryTableItem
            key={circuit.id}
            circuit={circuit}
            metric={job.metric}
            isSelected={isSelected}
            isDuplicate={isDuplicated}
            reel={reelName}
            quoteRequested={job.quoteRequested}
            onSelectCircuit={this.onSelectCircuit}
            toggleExpandCircuit={this.toggleExpandCircuit}
            expanded={this.state.expandedCircuits.indexOf(id) !== -1}
            isEditable={isEditable}
          />
        )
      };
    });
  };
}

export default SummaryTable;
