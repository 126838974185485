import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast, hideToast } from '../../actions/ToastActions';
import { showDeleteJobModal } from '../../actions/ModalActions';

import JobSummary from './JobSummary';

const mapStateToProps = () => (
  { firebase: { auth, profile }, firestore: { data } },
  props
) => ({
  projects: data.projects,
  jobs: data[`jobs-${props.match.params.projectId}`],
  auth,
  profile,
  catalogs: data.catalogs
});

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    },
    hideToast: () => dispatch(hideToast()),
    showDeleteJobModal: deleteJobObj => {
      dispatch(showDeleteJobModal(deleteJobObj));
    }
  };
};

export default withRouter(
  compose(
    firestoreConnect((props, store) => {
      return [
        {
          collection: 'projects',
          where: ['userId', '==', store.firebase.auth().currentUser.uid]
        },
        {
          collection: 'jobs',
          storeAs: `jobs-${props.match.params.projectId}`,
          where: [
            ['projectId', '==', props.match.params.projectId],
            ['userId', '==', store.firebase.auth().currentUser.uid]
          ]
        },
        {
          collection: 'catalogs'
        }
      ];
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(JobSummary)
);
