import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import Checkbox from '../../patterns/Checkbox';
import HoverableText from '../../patterns/HoverableText';
import NoColumnValue from '../../patterns/NoColumnValue';
import Expand from '../../patterns/Expand';
import IconButton from '../../patterns/IconButton';
import SummaryTableExpandedItem from './SummaryTableExpandedItem';

const SummaryTableItem = props => {
  const {
    reel,
    metric,
    circuit,
    expanded,
    isSelected,
    isEditable,
    quoteRequested,
    onSelectCircuit,
    toggleExpandCircuit,
    isDuplicate
  } = props;

  const { jobId, projectId } = props.match.params;
  const onExpand = () => toggleExpandCircuit(circuit.id, !expanded);
  const routeToEditCircuit = () =>
    props.history.push(
      `/projects/${projectId}/jobs/${jobId}/configure/circuit/${circuit.id}`,
      null
    );
  const isFromHoverable = circuit.from && circuit.from.length > 10;
  const isToHoverable = circuit.to && circuit.to.length > 10;
  const isReelHoverable = reel && reel.length > 20;

  const className = classNames('summary-circuit-item', {
    duplicate: isDuplicate
  });

  const rows = [
    <tr className={className} key={`circuit-${circuit.id}`}>
      {!quoteRequested && (
        <td className="checkbox-cell">
          <Checkbox
            id={circuit.id}
            value={circuit.id}
            onChange={e => onSelectCircuit(e, circuit.id)}
            checked={isSelected}
          />
        </td>
      )}
      <td>
        {circuit.from ? (
          <HoverableText text={circuit.from} isHoverable={isFromHoverable} />
        ) : (
          <NoColumnValue />
        )}
      </td>
      <td>
        {circuit.to ? (
          <HoverableText text={circuit.to} isHoverable={isToHoverable} />
        ) : (
          <NoColumnValue />
        )}
      </td>
      <td>{circuit.size}</td>
      <td>{`${circuit.length}${metric ? 'm' : "'"}`}</td>
      <td>
        {reel ? (
          <HoverableText text={reel} isHoverable={isReelHoverable} />
        ) : (
          <NoColumnValue />
        )}
      </td>
      <td className="icon-cell">
        {isEditable && (
          <IconButton onClick={routeToEditCircuit} type="single-edit" />
        )}
        <Expand expanded={expanded} onClick={onExpand} />
      </td>
    </tr>
  ];

  if (expanded) {
    rows.push(
      <SummaryTableExpandedItem
        circuit={circuit}
        key={`expanded-circuit-${circuit.id}`}
      />
    );
  }

  return rows;
};

export default withRouter(SummaryTableItem);
