import React from 'react';

const TYPE_ON_REEL = 'circuits-on-reel';
const TYPE_NO_REELS = 'no-reels';

class EmptySmall extends React.Component {
  render() {
    return (
      <div className="empty-configure-section">
        {this.renderImage()}

        <p className="empty-message">{this.renderMessage()}</p>
      </div>
    );
  }

  renderImage() {
    if (this.props.type === TYPE_ON_REEL) {
      return <img src="/assets/img/empty-reel.svg" alt=" " />;
    } else if (this.props.type === TYPE_NO_REELS) {
      return <img src="/assets/img/configure-no-reels.svg" alt=" " />;
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="74"
        viewBox="0 0 83 74"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#E6F1FF"
            d="M73.5 20l-4.219 22.5h13.5L60.281 74 64.5 51.5H51zM22.5 20l-4.219 22.5h13.5L9.281 74 13.5 51.5H0z"
          />
          <path
            fill="#5685C1"
            d="M51.75 0l-5.39 28.75h17.25L34.86 69l5.39-28.75H23z"
          />
        </g>
      </svg>
    );
  }

  renderMessage() {
    if (this.props.type === TYPE_ON_REEL) {
      return 'You haven’t added any circuits to this reel.';
    } else if (this.props.type === TYPE_NO_REELS) {
      return 'You haven’t created any reels.';
    }
    return 'You don’t have any available circuits.';
  }
}

export default EmptySmall;
