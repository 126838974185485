import ActionTypes from '../constants/ActionTypes.js';

export const showDeleteCircuitModal = deleteCircuitObj => {
  return {
    type: ActionTypes.SHOW_DELETE_CIRCUIT_MODAL,
    deleteCircuitObj: deleteCircuitObj
  };
};

export const showDeleteMVCircuitsModal = payload => {
  return {
    type: ActionTypes.SHOW_DELETE_MV_CIRCUITS_MODAL,
    payload
  };
};

export const hideModal = () => {
  return {
    type: ActionTypes.HIDE_MODAL
  };
};

export const showDeleteJobModal = deleteJobObj => {
  return {
    type: ActionTypes.SHOW_DELETE_JOB_MODAL,
    deleteJobObj: deleteJobObj
  };
};
export const showRemoveCircuitFromReelModal = removeCircuitObj => {
  return {
    type: ActionTypes.SHOW_REMOVE_CIRCUIT_MODAL,
    removeCircuitObj: removeCircuitObj
  };
};

export const showDeleteReelModal = deleteReelObj => {
  return {
    type: ActionTypes.SHOW_DELETE_REEL_MODAL,
    deleteReelObj: deleteReelObj
  };
};

export const showEditCircuitsModal = payload => {
  return {
    type: ActionTypes.SHOW_EDIT_CIRCUITS_MODAL,
    payload
  };
};

export const showEditMVCircuitsModal = payload => {
  return {
    type: ActionTypes.SHOW_EDIT_MV_CIRCUITS_MODAL,
    payload
  };
};
