import React from 'react';
import SimPullHead from '../../patterns/SimPullHead';
import NoColumnValue from '../../patterns/NoColumnValue';

const ExpandedDetail = ({ readonly = false, label, value }) => {
  return (
    <li>
      <h3>
        {readonly && '*'}
        {label}:
      </h3>
      <p>{value}</p>
    </li>
  );
};

const getMetal = metal => {
  switch (metal) {
    case 'CU':
      return 'Copper';
    case 'AL':
      return 'Aluminum';
    default:
      return <NoColumnValue />;
  }
};

const SummaryTableExpandedItem = props => {
  const { circuit } = props;
  const voltageThickness = `${circuit.voltage}kv / ${circuit.thickness}%`;
  const reel = circuit.reel || {};

  const metal = getMetal(circuit.metal);

  let groundInfo = <NoColumnValue />;
  if (circuit.ground) {
    const { metal, insulation, size } = circuit.ground;
    const groundMetal = getMetal(metal);
    groundInfo = `${groundMetal} ${insulation}, ${size}`;
  }

  return (
    <tr className="summary-circuit-item-details">
      <td className="expanded-item" colSpan="7">
        <div className="notch" />
        <ul className="mv-expanded-details">
          <ExpandedDetail label="Metal" value={metal} />

          <ExpandedDetail
            label="Voltage / Thickness"
            value={voltageThickness}
          />

          <ExpandedDetail label="Jacket" value={circuit.jacket} />

          <ExpandedDetail
            label={<SimPullHead />}
            value={circuit.simpull ? 'Yes' : 'No'}
          />

          <ExpandedDetail label="Ground" value={groundInfo} />

          <ExpandedDetail
            label="Reel Configuration"
            value={reel.config || ''}
          />

          <ExpandedDetail
            label="A-Frames"
            value={circuit.isAFrame ? 'Yes' : 'No'}
          />

          <ExpandedDetail
            readonly
            label="Insulation"
            value={circuit.insulation}
          />
          <ExpandedDetail readonly label="Shield" value={circuit.shield} />
        </ul>
        <div className="fixed-disclaimer">*Fixed Values (Cannot be edited)</div>
      </td>
    </tr>
  );
};

export default SummaryTableExpandedItem;
