import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DownloadBOM from './MVDownloadBOM';
import SummaryTable from './SummaryTable';
import Button from '../../patterns/Button';
import Breadcrumbs from '../../patterns/Breadcrumbs';
import JobSummaryHeader from '../../common/JobSummaryHeader';

export default class MVFeederSummary extends Component {
  onDeleteJob = () => {
    const { projectId, jobId } = this.props.match.params;

    this.props.showDeleteJobModal({
      jobId,
      projectId,
      routeAfterDelete: () => {
        this.props.history.push(`/projects/${projectId}`, null);
      }
    });
  };

  submitJob = () => {
    const { profile, job } = this.props;
    const { projectId, jobId } = this.props.match.params;

    const hasWrongRole = profile && profile.role === 'other';
    const hasNoCircuits = Object.keys(job.circuits).length === 0;

    if (hasWrongRole) {
      this.props.showToast(
        'Your account role “other” is not authorized to request a quote.',
        'error-persist'
      );
      return;
    }

    if (hasNoCircuits) {
      this.props.showToast(
        'Add circuits to your job before submitting a quote.',
        'error-persist'
      );
      return;
    }

    this.props.history.push(`/projects/${projectId}/jobs/${jobId}/viewers`);
  };

  onChangeSort = feederSummarySortPreference => {
    const { jobId } = this.props.match.params;

    this.props.firestore
      .update(`jobs/${jobId}`, { feederSummarySortPreference })
      .catch(error => {
        this.props.showToast(
          'There was a problem saving your sort preferences',
          'error'
        );
      });
  };

  onDeleteCircuits = payload => {
    const { projectId, jobId } = this.props.match.params;

    this.props.showDeleteCircuitsModal({
      ...payload,
      jobId,
      projectId
    });
  };

  /**
   * Updating circuit props for duplicate circuits .reduce()
   *
   * @param {Object} acc
   * @param {String} circuitId
   * @param {Number} index
   *
   * @return {Object} acc
   */
  updateCircuitProps = (acc, circuitId, index) => {
    const circuits = this.props.job.circuits;
    const now = Date.now();
    const dateCreated = now + index;
    const id = parseInt(circuitId, 10);
    const circuit = Object.assign({}, circuits[id]);

    acc[dateCreated] = {
      ...circuit,
      dateCreated,
      dateModified: dateCreated
    };

    return acc;
  };

  /**
   * Updating firestore and error handling
   * when user duplicates selected circuits
   *
   * @param {Array} selectedCircuits
   */
  onDuplicateCircuits = selectedCircuits => {
    const { job } = this.props;
    const circuits = job.circuits;
    const duplicateCircuits = selectedCircuits
      .sort()
      .reduce(this.updateCircuitProps, {});

    // update firestore circuits with duplicate circuits
    return this.props.firestore
      .update(`jobs/${this.props.match.params.jobId}`, {
        circuits: { ...circuits, ...duplicateCircuits }
      })
      .then(() => {
        // return `duplicateCircuits` so <SummaryTable /> can update `selectedCircuits` and `lastDuplicatedCircuits` state
        return duplicateCircuits;
      })
      .catch(error => {
        this.props.showToast('Circuits could not be created', 'error');
      });
  };

  onEditCircuits = payload => {
    const { projectId, jobId } = this.props.match.params;

    this.props.showEditCircuitsModal({
      ...payload,
      jobId,
      projectId
    });
  };

  render() {
    const { job, jobId, project, projectId } = this.props;
    const configurable = job && !job.quoteRequested;
    const baseUrl = `/projects/${projectId}/jobs/${jobId}`;
    const showBOM = job.circuits && Object.keys(job.circuits).length > 0;

    let quoteRequestedDate;
    if (job && job.quoteRequested) {
      let a = new Date(job.quoteRequested);
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const year = a
        .getFullYear()
        .toString()
        .slice(-2);
      quoteRequestedDate = `${month}/${date}/${year}`;
    }

    return (
      <div>
        <div className="project job-summary">
          <div className="action-heading">
            <Breadcrumbs type="projects">
              {project && (
                <li>
                  <Link to={`/projects/${projectId}`}>{project.name}</Link>
                </li>
              )}
            </Breadcrumbs>
          </div>

          <JobSummaryHeader
            job={job}
            jobId={jobId}
            project={project}
            projectId={projectId}
            onDeleteJob={this.onDeleteJob}
            jobMetaActions={
              <div>
                {showBOM && <DownloadBOM jobId={jobId} job={job} />}
                {configurable && (
                  <Button onClick={this.submitJob}>Request Quote</Button>
                )}
                {!configurable && (
                  <div className="quote-submitted">
                    Quote Submitted {quoteRequestedDate}
                  </div>
                )}
              </div>
            }
          />

          <div className="section-toggle">
            <div className="container">
              <div className="toggle">
                <Link to={`${baseUrl}/summary/feeder`} className="selected">
                  <img
                    src="/assets/img/icon-circuits.svg"
                    alt="circuits icon"
                  />
                  <span>Feeder Schedule</span>
                </Link>
              </div>
              <div className="section-links">
                {job.dateCreated && configurable && (
                  <Link to={`${baseUrl}/configure/circuit`} className="action">
                    Create Circuit
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        <SummaryTable
          job={job}
          onChangeSort={this.onChangeSort}
          onDeleteCircuits={this.onDeleteCircuits}
          onDuplicateCircuits={this.onDuplicateCircuits}
          onEditCircuits={this.onEditCircuits}
        />
      </div>
    );
  }
}
