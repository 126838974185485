import React from 'react';
import Button from '../patterns/Button';
import Input from '../patterns/Input';
import Radio from '../patterns/Radio';
import JobRestrictionsInputs from '../patterns/JobRestrictionsInputs';
import SimPull from '../patterns/SimPull';
import Toggle from '../patterns/Toggle';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';

export class CreateJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobName: '',
      jobType: '600V',
      message: '',
      error: false,
      isRestrictionValid: true,
      disabled: true,
      height: '',
      width: '',
      weight: '',
      simpull: false,
      isAFrame: false,
      saving: false
    };
  }

  handleJobName = e => {
    let jobName = e.target.value;
    this.setState({ jobName: jobName });
    // using event target value instead of state bc calculates length immediately, state lags
    if (jobName.length <= 30) {
      this.setState({ message: '', disabled: false, error: false });
    } else {
      // disable submit button if length exceeds 30 chars
      this.setState({
        message: 'Job name cannot exceed 30 characters.',
        error: true,
        disabled: true
      });
    }
    // set disabled back to true if user types and deletes all characters
    if (jobName.length === 0) {
      this.setState({ disabled: true, error: false });
    }
  };

  handleCancel = () => {
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}`,
      null
    );
  };

  isFormValid = () => {
    return (
      this.state.isRestrictionValid &&
      !this.state.error &&
      !this.state.saving &&
      !this.state.disabled
    );
  };

  setJobType = e => {
    this.setState({ jobType: e.target.value });
  };

  handleRestrictionUpdate = updatedRestriction => {
    this.setState({ ...updatedRestriction });
  };

  handleToggle = () => {
    this.setState({ simpull: !this.state.simpull });
  };

  handleAframe = () => {
    this.setState({ isAFrame: !this.state.isAFrame });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { height, width, weight, simpull, isAFrame } = this.state;
    let jobName = this.state.jobName.trim();
    let jobType = this.state.jobType;
    let userId = this.props.firebase.auth().currentUser.uid;
    let projectId = this.props.match.params.projectId;
    let jobs = this.props.jobs || {};
    const reelOptions = jobType === '600V' ? { simpull } : { isAFrame };
    let match = Object.values(jobs).some(function(job) {
      if (job.visible && job.name === jobName) {
        return true;
      }
      return false;
    });

    if (match) {
      this.setState({ message: 'Job name already exists.', error: true });
    } else if (jobName.length === 0) {
      this.setState({ message: 'Job name is invalid.', error: true });
    } else {
      // prevent saving multiple jobs on rapid clicking
      this.setState({ saving: true });
      this.props.firestore
        .add(
          { collection: 'jobs' },
          {
            name: jobName,
            jobType: jobType,
            userId: userId,
            projectId: projectId,
            restrictions: {
              height: height,
              width: width,
              weight: weight
            },
            metric: !!this.props.profile.metric,
            country: this.props.profile.country || 'us',
            dateCreated: Date.now(),
            reels: [],
            circuits: {},
            sortPreference: 'dateCreated',
            visible: true,
            ...reelOptions
          }
        )
        .then(() => {
          this.setState({ saving: false });
          this.props.history.push(
            `/projects/${this.props.match.params.projectId}`,
            null
          );
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.showToast(
            "Your job can't be created at this time. Please try again later.",
            'error'
          );
        });
    }
  };

  render() {
    const simpullDisclaimer = [
      'By selecting this option, you will have to submit your quote through a SIM',
      <span key={0}>
        <i>pull</i>&nbsp;&#8482;
      </span>,
      ' Partner Distributor.'
    ];
    const jobSettings = {
      height: this.state.height,
      width: this.state.width,
      weight: this.state.weight,
      simpull: this.state.simpull
    };
    const metric = this.props.profile.metric || false;
    const country = this.props.profile.country || 'us';

    return (
      <div className="project job">
        <h2 className="page-title">Create Job</h2>

        <form className="job-reel-form" onSubmit={this.handleSubmit}>
          <Input
            label="Job Name*"
            type="text"
            placeholder="Name"
            onChange={this.handleJobName}
            value={this.state.jobName}
            autoComplete="name"
            errorMessage={this.state.message}
            isError={this.state.error}
          />
          <p className="title">Job Type</p>
          <div className="job-type">
            <Radio
              label="600V"
              name="jobType"
              value="600V"
              checked={this.state.jobType === '600V'}
              onChange={this.setJobType}
            />
            <Radio
              label="Medium Voltage"
              name="jobType"
              value="MV"
              checked={this.state.jobType === 'MV'}
              onChange={this.setJobType}
            />
          </div>
          <div className="restrictions">
            <p className="title">Jobsite and Equipment Restrictions</p>
            <div className="body">
              <div className="info" />
              <p>
                You can override your jobsite and equipment restrictions when
                you are configuring your individual reels.
              </p>
            </div>
          </div>
          <JobRestrictionsInputs
            handleRestrictionUpdate={this.handleRestrictionUpdate}
            jobSettings={jobSettings}
            metric={metric}
            country={country}
            jobType={this.state.jobType}
          />
          {this.state.jobType === '600V' ? (
            <Toggle
              name={[<SimPull key="simpull2" />, ' Reel']}
              info="true"
              disclaimer={simpullDisclaimer}
              checked={this.state.simpull}
              onChange={this.handleToggle}
            />
          ) : (
            <Toggle
              name="Use A-Frames"
              checked={this.state.isAFrame}
              onChange={this.handleAframe}
            />
          )}

          <div className="action-buttons">
            <Button onClick={this.handleCancel} className="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!this.isFormValid()}
              saving={this.state.saving}
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (
  { firebase: { auth, profile }, firestore: { data } },
  props
) => ({
  profile,
  projects: data.projects,
  jobs: data[`jobs-${props.match.params.projectId}`]
});

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateJob);
