import React from 'react';

const Input = ({
  containerClassName = '',
  errorMessage,
  isError,
  errorDelay,
  ...props
}) => (
  <div className={`form-field ${containerClassName}`}>
    <label className={props.required && 'required'}>{props.label}</label>
    <input className={isError ? 'error-border' : null} {...props} />
    {(props.disclaimer || errorMessage) && (
      <div className="field-messages">
        {props.disclaimer && (
          <div className="disclaimer">
            {props.info && <div className="info" />}
            <span>{props.disclaimer}</span>
          </div>
        )}
        {errorMessage && (
          <div
            className={`field-alert${isError ? ' alerted' : ''}${
              errorDelay ? ' delay-alert' : ''
            }`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    )}
  </div>
);

export default Input;
