import React from 'react';
import ConductorSwatch from './ConductorSwatch';
import EmptySwatch from './EmptySwatch';

const ColorPicker = props => {
  const { availableColors, conductor, onChange, selectedIndex } = props;

  return (
    <div className="color-picker">
      <p className="label required">{`Conductor ${selectedIndex + 1} Color`}</p>
      <ul>
        {!availableColors.length && <EmptySwatch id="swatch-placeholder" />}

        {availableColors.map((color, index) => (
          <li key={index}>
            <ConductorSwatch
              color={color}
              id={`color-picker-${index}`}
              onChange={() => onChange(color)}
              isSelected={conductor.color.name === color.name}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ColorPicker;
