import React from 'react';

const Logo = () => (
  <h1>
    <picture className="logo">
      <img
        src="/assets/img/logo.png"
        srcSet="/assets/img/logo.png 1x, /assets/img/logo@2x.png 2x, /assets/img/logo@3x.png 3x"
        alt="Southwire"
      />
    </picture>
    <span className="heading-title">
      Configurator Plus<span>&trade;</span>
    </span>
  </h1>
);

export default Logo;
