import React from 'react';
import MVCircuitBuilder from './MVCircuitBuilder';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';
import withNotFoundRedirect from '../common/withNotFoundRedirect';

export class MVCreateCircuit extends React.Component {
  routeOnCancel = () => {
    this.props.history.replace(
      `${this.props.match.url.replace('/configure/circuit', '/summary/feeder')}`
    );
  };

  render() {
    const jobId = this.props.match.params.jobId || false;
    const job = this.props.jobs && jobId ? this.props.jobs[jobId] : {};
    const isJob = job && job.name;
    const catalogs = this.props.catalogs;

    return (
      <div>
        <h2 className="page-title">Create Medium Voltage Circuits</h2>
        {isJob && catalogs && (
          <MVCircuitBuilder
            job={job}
            jobId={jobId}
            catalog={catalogs}
            routeOnCancel={this.routeOnCancel}
            showToast={this.props.showToast}
            firestore={this.props.firestore}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, type) => {
      dispatch(showToast(message, type));
    }
  };
};

const mapStateToProps = ({ firestore: { data } }, props) => ({
  jobs: data[`jobs-${props.match.params.projectId}`],
  catalogs: data.catalogs
});

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(MVCreateCircuit);
