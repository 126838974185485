import React from 'react';
import authHelpers from '../utils/authHelpers';

const Footer = props => {
  const rest = { ...props, component: undefined };
  const loggedIn = authHelpers.isLoggedIn(rest.auth);
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className={loggedIn ? null : 'login-footer'}>
      <div className="footer-group icons">
        <a
          href="https://twitter.com/southwire"
          target="blank"
          className="footer-item icon"
        >
          <img
            src="/assets/img/icon-twitter.svg"
            alt="Twitter icon"
            height="16.5"
          />
        </a>
        <a
          href="https://www.facebook.com/southwire"
          target="blank"
          className="footer-item icon"
        >
          <img
            src="/assets/img/icon-facebook.svg"
            alt="Facebook icon"
            height="16.5"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/southwire-company/"
          target="blank"
          className="footer-item icon"
        >
          <img
            src="/assets/img/icon-linkedin.svg"
            alt="LinkedIn icon"
            height="16.5"
          />
        </a>
        <a
          href="https://www.youtube.com/user/SouthwireChannel"
          target="blank"
          className="footer-item icon"
        >
          <img
            src="/assets/img/icon-youtube.svg"
            alt="Youtube icon"
            height="16.5"
          />
        </a>
      </div>
      <div className="footer-group links">
        <a
          className="footer-item"
          href="http://ContractorSolutions.Southwire.com"
          target="blank"
        >
          ContractorSolutions.Southwire.com
        </a>
        |
        <a
          className="footer-item"
          href="https://www.southwire.com/privacy-policy"
          target="blank"
        >
          Privacy Policy
        </a>
        |
        <a
          className="footer-item"
          href="https://www.southwire.com/terms-of-use"
          target="blank"
        >
          Terms of Use
        </a>
        |
        <span className="footer-item">
          ProjectConstructionTeam@Southwire.com
        </span>
        |
        <a
          className="footer-item"
          href="http://www.patentsw.com"
          target="blank"
        >
          Patent 10,410,267
        </a>
      </div>
      <span className="footer-item">{`©${currentYear} Southwire Company, LLC. All Rights Reserved. ®Registered Trademark and ™Trademark of Southwire Company, LLC`}</span>
    </footer>
  );
};

export default Footer;
