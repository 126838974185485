import React from 'react';
import Expand from '../patterns/Expand';
import SimPullHead from '../patterns/SimPullHead';
import ConductorDetail from '../patterns/ConductorDetail';
import reelColors from '../utils/reelColors';
import NoColumnValue from '../patterns/NoColumnValue';

class ReelSummaryCircuitItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  render() {
    const expanded = this.state.expanded ? 'true' : null;
    const circuit = this.props.circuit;
    const groundConductor = circuit.conductors.find(c => c.isGroundConductor);

    const rows = [
      <tr
        className="reel-summary-circuit-item"
        key={`circuit-on-reel-${circuit.id}`}
      >
        <td
          className="from-cell"
          style={{
            backgroundImage: `linear-gradient(to right, ${
              reelColors[this.props.colorKey % reelColors.length]
            } 6px, rgba(0,0,0,0) 6px)`
          }}
        >
          {circuit.from || <NoColumnValue />}
        </td>
        <td>{circuit.to || <NoColumnValue />}</td>
        <td>{circuit.size}</td>
        <td>{`${circuit.length}${this.props.metric ? `m` : `'`}`}</td>
        <td className="expand-cell">
          <Expand
            onClick={() => {
              this.handleExpand();
            }}
            expanded={expanded}
          />
        </td>
      </tr>
    ];

    if (expanded) {
      rows.push(
        <tr
          className="reel-summary-circuit-item-details"
          key={`expanded-on-reel-${circuit.id}`}
        >
          <td className="expanded-item" colSpan="5">
            <div className="expanded-header">
              <span>
                Metal / Insulation: {circuit.metal} / {circuit.insulation}
              </span>
              {!!groundConductor && (
                <span>
                  Ground: {groundConductor.groundMetal} / {circuit.insulation}
                </span>
              )}
              {circuit && circuit.simpull && <SimPullHead />}
            </div>
            <div className="expanded-colors">{this.displayColors(circuit)}</div>
          </td>
        </tr>
      );
    }
    return rows;
  }

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  displayColors(circuit) {
    const conductors = circuit.conductors;
    if (conductors && conductors.length) {
      //new updated model 8/2018
      const colorCollection = conductors.map((conductor, id) => {
        return (
          <ConductorDetail
            color={conductor.color}
            size={conductor.size}
            key={id + '1'}
            catalog={this.props.catalog}
          />
        );
      });
      return colorCollection;
    } else {
      //original data model
      const colors = circuit.colors;
      const colorCollection = colors.map((color, id) => {
        return (
          <ConductorDetail
            color={color}
            size={circuit.size}
            key={id + '1'}
            catalog={this.props.catalog}
          />
        );
      });
      return colorCollection;
    }
  }
}

export default ReelSummaryCircuitItem;
