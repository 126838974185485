import React from 'react';
import EmptySwatch from './EmptySwatch';

import info from '../../assets/information.svg';

const ConductorSwatch = props => {
  const {
    id,
    size,
    color,
    onChange,
    isSelected = false,
    showSizeWarning = false
  } = props;

  if (!color) {
    return <EmptySwatch {...props} />;
  }

  const styles = {
    backgroundColor: color.rgb ? `rgb(${color.rgb})` : color.name
  };

  const className = ['swatch'];
  if (isSelected) {
    className.push('selected');
  }

  return (
    <div className="conductor-swatch">
      <span className={className.join(' ')}>
        <input
          type="button"
          style={styles}
          id={id}
          onClick={onChange}
          value={color.name}
          className="swatch-color"
        />
        {showSizeWarning && (
          <img className="size-warning-icon" alt="Size Warning" src={info} />
        )}
      </span>
      <label htmlFor={id}>
        {color.name}
        {size && ` / ${size}`}
      </label>
    </div>
  );
};

export default ConductorSwatch;
