import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import authHelpers from '../utils/authHelpers';
import Logo from './Logo';

export const Header = props => {
  // list routes for which we want to hide the Link to account settings
  let path = props.location && props.location.pathname;
  const pagesToHideName = ['/account'];
  let showName = true;
  if (pagesToHideName.indexOf(path) >= 0) {
    showName = false;
  }

  return (
    <header>
      <div className="container">
        <Link to="/">
          <Logo />
        </Link>
        {showName && authHelpers.isLoggedIn(props.auth) && (
          <Link to="/account" className="account-link">
            {props.profile && props.profile.name}
          </Link>
        )}
      </div>
    </header>
  );
};

export default withRouter(
  compose(
    firestoreConnect(),
    connect(({ firebase: { auth, profile } }) => ({
      profile,
      auth
    }))
  )(Header)
);
