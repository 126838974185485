import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Input from '../patterns/Input';
import { findSmallestReel, findSmallestSimreel } from '../utils/reelUtil';

/**
 * JobRestrictionsInputs props:
 * handleRestrictionUpdate - (fnc) sets restriction field states of parent
 * jobSettings - (obj) job settings from parent
 * metric - (bool) is job is in metric units
 * country - (string) country of job. metric and country props of jobs can differ from user's current profile props
 */
const WIDTH = 'width';
const HEIGHT = 'height';
const WEIGHT = 'weight';

export class JobRestrictionsInputs extends React.Component {
  componentDidUpdate(prevProps) {
    // when job settings update validate each restriction field and set parent 'isRestrictionValid' state
    const jobSettingsChanged = !(
      prevProps.jobSettings.height === this.props.jobSettings.height &&
      prevProps.jobSettings.width === this.props.jobSettings.width &&
      prevProps.jobSettings.weight === this.props.jobSettings.weight &&
      prevProps.jobSettings.simpull === this.props.jobSettings.simpull
    );

    const selectedReelChanged =
      prevProps.selectedReel &&
      this.props.selectedReel &&
      prevProps.selectedReel.reelProduct.name !==
        this.props.selectedReel.reelProduct.name;

    const jobTypeChanged = prevProps.jobType !== this.props.jobType;

    if (jobSettingsChanged || selectedReelChanged || jobTypeChanged) {
      const fields = [HEIGHT, WIDTH, WEIGHT];
      let isRestrictionValid = true;

      // If at least one field has an error, the restriction is no longer valid.
      if (fields.some(this.restrictionHasError)) {
        isRestrictionValid = false;
      }

      this.props.handleRestrictionUpdate({ isRestrictionValid });
    }
  }

  /**
   * Update state for width, weight, and height.
   */
  updateRestrictedInput = field => {
    return event => {
      const value = event.target.value;
      const re = /^\d*$/;
      if (re.test(value)) {
        this.props.handleRestrictionUpdate({ [field]: value });
      }
    };
  };

  /**
   * Validate the given restricted input field (width, height, weight).
   * Return true if the given restriction has an error.
   *
   * Only validate for 600V jobs.
   *
   * @param  {String} field
   * @return {Boolean}
   */
  restrictionHasError = field => {
    if (this.props.jobType === 'MV') {
      return false;
    }

    const value = this.props.jobSettings[field];

    let minReel = this.props.jobSettings.simpull
      ? this.props.smallestSimreel
      : this.props.smallestReel;

    if (this.props.selectedReel) {
      minReel = this.props.selectedReel;
    }

    return parseFloat(value) < minReel[field];
  };

  render() {
    const heightDisclaimer = 'Example: Entryway height and width limits.';
    const weightDisclaimer = 'Example: Material-handling weight restrictions.';
    const { height, width, weight } = this.props.jobSettings;
    const metric = this.props.metric;

    return (
      <div className="job-settings-fields">
        <Input
          label="Height"
          disclaimer={heightDisclaimer}
          containerClassName="height"
          type="text"
          placeholder={metric ? 'cm' : 'Inches'}
          onChange={this.updateRestrictedInput(HEIGHT)}
          value={height}
          errorMessage="No reel available"
          isError={this.restrictionHasError(HEIGHT)}
          errorDelay={true}
        />
        <Input
          label="Width"
          containerClassName="width"
          type="text"
          placeholder={metric ? 'cm' : 'Inches'}
          onChange={this.updateRestrictedInput(WIDTH)}
          value={width}
          errorMessage="No reel available"
          isError={this.restrictionHasError(WIDTH)}
          errorDelay={true}
        />
        <Input
          label="Weight"
          disclaimer={weightDisclaimer}
          containerClassName="weight"
          type="text"
          placeholder={metric ? 'kg' : 'Pounds'}
          onChange={this.updateRestrictedInput(WEIGHT)}
          value={weight}
          errorMessage="No reel available"
          isError={this.restrictionHasError(WEIGHT)}
          errorDelay={true}
        />
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(({ firestore: { data } }, props) => {
    const metric = props.metric;
    const country = props.country;
    const catalogs = data && data.catalogs;
    const reelTypes = catalogs && catalogs[country].reels.items;
    const simreelTypes = catalogs && catalogs.simreels;
    return {
      smallestReel: findSmallestReel(reelTypes, metric),
      smallestSimreel: findSmallestSimreel(simreelTypes, metric)
    };
  })
)(JobRestrictionsInputs);
