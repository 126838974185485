import React from 'react';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import SimPull from '../patterns/SimPull';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../common/withNotFoundRedirect';
import cloneDeep from 'lodash/cloneDeep';

// TODO: we should remove the reliance on transferring state via location.state
// and instead pull the job details based on redux and url params.
export class DuplicateJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobName: '',
      message: '',
      error: false,
      disabled: true,
      saving: false
    };
  }

  handlejobName(e) {
    let jobName = e.target.value;
    this.setState({ jobName: jobName });
    // using event target value instead of state bc calculates length immediately, state lags
    if (jobName.length <= 30) {
      this.setState({ message: '', disabled: false, error: false });
    } else {
      // disable submit button if length exceeds 30 chars
      this.setState({
        message: 'Job name cannot exceed 30 characters.',
        error: true,
        disabled: true
      });
    }
    // set disabled back to true if user types and deletes all characters
    if (jobName.length === 0) {
      this.setState({ disabled: true, error: false });
    }
  }

  handleCancel() {
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}`,
      null
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    // job obj passed in from react router
    const job = cloneDeep(this.props.location.state.job);
    let jobName = this.state.jobName.trim();
    let jobs = this.props.jobs || {};

    let match = Object.values(jobs).some(function(job) {
      if (job.visible && job.name === jobName) {
        return true;
      }
      return false;
    });
    if (match) {
      this.setState({
        message: 'Job name already exists.',
        error: true,
        disabled: true
      });
    } else if (jobName.length === 0) {
      this.setState({
        message: 'Job name is invalid.',
        error: true,
        disabled: true
      });
    } else {
      // prevent saving multiple jobs on rapid clicking
      this.setState({ saving: true, error: false });
      // duplicate job but new name, date created, clear out date modified, set to in progress
      let duplicatedJob = Object.assign(job, {
        name: jobName,
        dateCreated: Date.now(),
        dateModified: '',
        quoteRequested: false
      });
      this.props.firestore
        .add({ collection: 'jobs' }, duplicatedJob)
        .then(() => {
          this.setState({ saving: false });
          this.props.history.push(
            `/projects/${this.props.match.params.projectId}`,
            null
          );
        })
        .catch(error => {
          this.setState({ saving: false });
          // if error, display error message
          this.setState({ message: error.message });
        });
    }
  }

  render() {
    const job = this.props.location.state.job;
    const lengthUnit = job.metric !== undefined && (job.metric ? 'cm' : '"');
    const weightUnit = job.metric !== undefined && (job.metric ? 'kg' : 'lb');
    return (
      <div className="project job">
        <h2 className="page-title">Duplicate Job</h2>
        <form
          onSubmit={e => {
            this.handleSubmit(e);
          }}
        >
          <Input
            label="Job Name*"
            type="text"
            placeholder="Name"
            onChange={e => {
              this.handlejobName(e);
            }}
            value={this.state.jobName}
            autoComplete="name"
            errorMessage={this.state.message}
            isError={this.state.error}
          />
          <div className="duplicate restrictions">
            <p className="title">Reel Restrictions</p>
          </div>
          <div className="settings">
            <div>
              <p className="label">Height</p>
              <p>
                {job && job.restrictions.height
                  ? `${job.restrictions.height}${lengthUnit}`
                  : 'N/A'}
              </p>
            </div>
            <div>
              <p className="label">Width</p>
              <p>
                {job && job.restrictions.width
                  ? `${job.restrictions.width}${lengthUnit}`
                  : 'N/A'}
              </p>
            </div>
            <div>
              <p className="label">Weight</p>
              <p>
                {job && job.restrictions.weight
                  ? `${job.restrictions.weight}${weightUnit}`
                  : 'N/A'}
              </p>
            </div>
          </div>
          {job && job.simpull && (
            <span className="simpull-text">
              <SimPull key="simpull" /> Reel
            </span>
          )}
          <div className="lineBreak" />
          <div className="submitButtons">
            <Button
              onClick={e => {
                this.handleCancel();
              }}
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={this.state.disabled || this.state.saving}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(({ firebase: { auth, profile }, firestore: { data } }, props) => ({
    profile,
    projects: data.projects,
    jobs: data[`jobs-${props.match.params.projectId}`]
  })),
  withNotFoundRedirect('jobId', 'jobs')
)(DuplicateJob);
