import React from 'react';
import { Redirect } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';

/**
 * Higher order component for redirecting when the requested resource could not be found.
 */
export default function withNotFoundRedirect(idName, dataName) {
  return WrappedComponent => {
    /**
     * Check that data exists in props for the resource ID specified.
     *
     * @param  {Object} props - component props
     * @return {Boolean}      - true when all criteria exists
     */
    const dataExists = props => {
      const id =
        props.match && props.match.params && props.match.params[idName];
      const data = props[dataName];

      return id && data && !!data[id];
    };

    /**
     * Check to make sure all firebase data has fully loaded.
     *
     * @param  {Object} props - component props
     * @return {Boolean}      - true when all data has fully loaded
     */
    const dataLoaded = props => isLoaded(props[dataName]);

    return props => {
      // Only redirect if the firebase data has loaded but the data requested doesn't exist.
      if (dataLoaded(props) && !dataExists(props)) {
        return <Redirect to="/404" />;
      }

      return <WrappedComponent {...props} />;
    };
  };
}
