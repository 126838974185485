import React from 'react';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../common/withNotFoundRedirect';

export class ProjectSettings extends React.Component {
  constructor(props) {
    super(props);
    let state = {
      projectName: '',
      message: '',
      disabled: true,
      error: false,
      saving: false
    };
    if (props.projects && props.projects[this.props.match.params.projectId]) {
      const project = props.projects[this.props.match.params.projectId];
      state = {
        ...state,
        projectName: project.name
      };
    }
    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.projects !== this.props.projects &&
      typeof nextProps.projects === 'object'
    ) {
      const project = nextProps.projects[this.props.match.params.projectId];
      this.setState({ projectName: project.name });
    }
  }

  handleProjectName(e) {
    let projectName = e.target.value;
    this.setState({ projectName: projectName });
    // using event target value instead of state bc calculates length immediately, state lags
    if (projectName.length <= 30) {
      this.setState({ message: '', disabled: false, error: false });
    } else {
      // disable submit button if length exceeds 30 chars
      this.setState({
        message: 'Project Name cannot exceed 30 characters.',
        disabled: true,
        error: true
      });
    }
    // set disabled back to true if user types and deletes all characters
    if (projectName.length === 0) {
      this.setState({ disabled: true, error: false });
    }
  }

  handleCancel() {
    this.props.history.goBack();
  }

  handleSubmit(e) {
    e.preventDefault();
    let projectName = this.state.projectName.trim();
    let projects = this.props.projects || {};
    let existingName = projects[this.props.match.params.projectId].name;

    // handles if user begins to update name and changes it back to existingName
    if (projectName !== existingName) {
      let match = Object.values(projects).some(function(project) {
        if (project.name === projectName) {
          return true;
        }
        return false;
      });

      if (match) {
        this.setState({ message: 'Project name already exists.', error: true });
      } else if (projectName.length === 0) {
        this.setState({ message: 'Project name is invalid.', error: true });
      } else {
        this.setState({ saving: true });
        this.props.firestore
          .update(`projects/${this.props.match.params.projectId}`, {
            name: projectName
          })
          .then(() => {
            // reroute to projects home screen on successful update
            this.setState({ saving: false });
            this.props.history.goBack();
          })
          .catch(error => {
            // if error, display error message
            this.setState({ saving: false, message: error.message });
          });
      }
    } else {
      this.handleCancel();
    }
  }

  render() {
    return (
      <div className="project">
        <h2 className="page-title">Edit Project Settings</h2>
        <form
          id="create-edit-project-form"
          onSubmit={e => {
            this.handleSubmit(e);
          }}
        >
          <Input
            required
            label="Project Name"
            type="text"
            placeholder={'Name'}
            onChange={e => {
              this.handleProjectName(e);
            }}
            value={this.state.projectName}
            autoComplete="name"
            errorMessage={this.state.message}
            isError={this.state.error}
          />
          <div className="lineBreak" />
          <div className="submitButtons">
            <Button
              onClick={e => {
                this.handleCancel();
              }}
              className="secondary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={this.state.disabled || this.state.saving}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      }
    ];
  }),
  connect(({ firebase: { auth }, firestore: { data } }) => ({
    projects: data.projects,
    auth
  })),
  withNotFoundRedirect('projectId', 'projects')
)(ProjectSettings);
