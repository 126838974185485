import productFinder from './productFinder';
import { getReelWeightCapacity, getMaxVolume } from './reelUtil';

/**
 * The maximum reel width that can be supported by an AFrame.
 * @var {Number}
 */
const MAX_AFRAME_REEL_WIDTH = 96;

/**
 * This class is responsible for assigning the proper reel products
 * based on the MV circuit provided.
 *
 * This class should typically not be used on its own. Instead, use the
 * default exported function below to get the assigned reels array.
 */
export class MVReelAssignment {
  constructor(mvCatalog, circuit, jobRestrictions) {
    const { steelReels, starkvilleReels } = mvCatalog;

    this.mvCatalog = mvCatalog;
    this.steelReels = this.sortReels(steelReels);
    this.starkvilleReels = this.sortReels(starkvilleReels);
    this.circuit = circuit;
    this.jobRestrictions = jobRestrictions;
  }

  /**
   * Main entrypoint.
   * Calculate the list MV reel products to persist to Firebase.
   *
   * @return {Array|Boolean}
   */
  getReelProducts() {
    const reelsCatalog =
      this.circuit.reel.type === 'steel'
        ? this.steelReels
        : this.starkvilleReels;

    const startingReels = this.getStartingReels();

    const reels = startingReels
      .map(this.findReelProduct(reelsCatalog))
      .filter(Boolean);

    // If we ended up with a different number of reels than when we started,
    // we ran into an error which invalids this circuit.
    if (!reels.length || reels.length !== startingReels.length) {
      return false;
    }

    return reels;
  }

  /**
   * Determine the starting reels array for the given circuit configuration.
   *
   * @return {Array}
   */
  getStartingReels() {
    switch (this.circuit.reel.config) {
      case '1/C':
        return Array(3).fill({ numConductors: 1 });
      case '2/C':
        return [{ numConductors: 2 }, { numConductors: 1 }];
      case '3/C':
        return [{ numConductors: 3 }];
      default:
        return [];
    }
  }

  /**
   * Find the most appropriate reel product for the circuit/reel info provided.
   *
   * `reel` will indicate how many conductors should be on the reel,
   * and `this.circuit` will indicate the conductor composition.
   *
   * @param  {Array} reelsCatalog
   * @return {Function} - the callback function for Array.map
   */
  findReelProduct = reelsCatalog => {
    return reel => {
      for (let i = 0; i < reelsCatalog.length; i++) {
        const reelProduct = reelsCatalog[i];

        // This reelProduct is valid if it meets these criteria:
        //  - weight and volume can support expected load
        //  - the drum diameter is above the threshold
        //  - other job site restrictions are met
        const weightCheck = this.isWeightSupported(reelProduct, reel);
        const volumeCheck = this.isVolumeSupported(reelProduct, reel);
        const drumCheck = this.isDrumDiameterAboveThreshold(reelProduct);
        const restrictionsCheck = this.areRestrictionsValid(reelProduct);

        const reelIsSupported =
          weightCheck && volumeCheck && drumCheck && restrictionsCheck;

        if (reelIsSupported) {
          return {
            ...reel,
            reelProduct
          };
        }
      }

      return null;
    };
  };

  /**
   * Determine if the suggested reel product can support the expected weight load
   * of our circuits.
   *
   * `this.circuit.weight` is calculated based on the default number of conductors (3).
   * Since this reel can potentially contain a subset of conductors, we need to first
   * divide by the default number of conductors, then multiply by how many conductors we
   * expect to be on this particular reel.
   *
   * @param  {Object} reelProduct - reel product from the catalog
   * @param  {Object} reel        - the starting reel object that represents what we will be persisting
   * @return {Boolean}
   */
  isWeightSupported(reelProduct, reel) {
    const normalizedReel = productFinder.normalizeReelProduct(reelProduct);
    const maxWeight = getReelWeightCapacity(normalizedReel);

    const weight =
      (this.circuit.weight / this.circuit.numConductors) * reel.numConductors;

    return weight < maxWeight;
  }

  /**
   * Determine if the suggested reel product can support the expected volume load
   * of our circuits.
   *
   * `this.circuit.volume` is calculated based on the default number of conductors (3).
   * Since this reel can potentially contain a subset of conductors, we need to first
   * divide by the default number of conductors, then multiply by how many conductors we
   * expect to be on this particular reel.
   *
   * @param  {Object} reelProduct - reel product from the catalog
   * @param  {Object} reel        - the starting reel object that represents what we will be persisting
   * @return {Boolean}
   */
  isVolumeSupported(reelProduct, reel) {
    const maxVolume = getMaxVolume(
      reelProduct.traverse_in,
      reelProduct.flange_in,
      reelProduct.drum_in,
      this.circuit.diameter / 1000
    );

    const volume =
      (this.circuit.volume / this.circuit.numConductors) * reel.numConductors;

    return volume < maxVolume;
  }

  /**
   * Determine if the proposed reel's drum diameter is above the minimum threshold.
   *
   * circuit.diameter is stored in mils, so we need to divide by 1000 to compare it to inches.
   *
   * @param  {Object} reelProduct
   * @return {Boolean}
   */
  isDrumDiameterAboveThreshold(reelProduct) {
    return reelProduct.drum_in >= 15 * (this.circuit.diameter / 1000);
  }

  /**
   * Check the final misc restrictions for the reel.
   *
   * If AFrame is enabled, we just need to check the reel width is under the max width that AFrames support.
   * If AFrame is disabled, we should check height and width jobsite restrictions per usual.
   *
   * @param  {Object} reelProduct
   * @return {Boolean}
   */
  areRestrictionsValid(reelProduct) {
    if (this.circuit.isAFrame) {
      return reelProduct.outside_in <= MAX_AFRAME_REEL_WIDTH;
    }

    const heightRestriction = parseFloat(this.jobRestrictions.height);
    const widthRestriction = parseFloat(this.jobRestrictions.width);

    return (
      (isNaN(heightRestriction) ||
        reelProduct.flange_in <= heightRestriction) &&
      (isNaN(widthRestriction) || reelProduct.outside_in <= widthRestriction)
    );
  }

  /**
   * Sort the provided reels based on size, smallest -> largest.
   *
   * @param  {Array} reels
   * @return {Array}
   */
  sortReels(reels) {
    return [...reels].sort((a, b) => {
      if (a.flange_in === b.flange_in) {
        return b.drum_in - a.drum_in;
      }

      return a.flange_in - b.flange_in;
    });
  }
}

/**
 * Just exposing the public API for this class as a single function
 * to make it easier to use.
 *
 * @param  {Object} mvCatalog
 * @param  {Object} circuit
 * @param  {Object} jobRestrictions
 * @return {Array}
 */
export default function assignReels(mvCatalog, circuit, jobRestrictions) {
  const assigner = new MVReelAssignment(mvCatalog, circuit, jobRestrictions);

  return assigner.getReelProducts();
}
