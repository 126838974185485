import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import convertUnits from '../utils/convertUnits';
import productFinder from '../utils/productFinder';
import { getUpdatedReelProduct } from '../utils/reelUtil';

import Modal from './Modal';
import Input from '../patterns/Input';
import Dropdown from '../patterns/Dropdown';
import SimPullHead from '../patterns/SimPullHead';

const MAX_FEET_DIFF_SIZES = 250;

/**
 * Mass edit selected circuits.
 */
export class EditCircuits extends Component {
  state = {
    saving: false,
    from: '',
    to: '',
    length: '',
    simpull: 'no-change',
    maxLengthWarning: false,
    exceedsCapacityError: false,
    confirmError: false
  };

  /**
   * Change generic field event handler.
   *
   * @param  {String} field - the field you want to update in state
   * @return {Function}     - event handler function
   */
  onChangeField = field => {
    return event => {
      this.setState({
        [field]: event.target.value
      });
    };
  };

  /**
   * Change length event handler.
   *
   * @param {Object} event
   */
  onChangeLength = event => {
    const length = parseFloat(event.target.value) || '';

    this.setState({
      length,
      maxLengthWarning: this.exceedsMaxLength(length),
      exceedsCapacityError: false
    });
  };

  /**
   * Change simpull dropdown event handler.
   *
   * @param {Boolean} simpull
   */
  onChangeSimpull = simpull => {
    this.setState({ simpull });
  };

  /**
   * Updating changed values for selected circuits
   *
   * @return {Object}
   */
  getUpdatedCircuits = () => {
    const { circuitIds } = this.props.payload;
    const { circuits } = this.props.job;
    const { from, to, length, simpull } = this.state;
    const now = Date.now();

    let updatedCircuits = {};
    for (let i = 0; i < circuitIds.length; i++) {
      const id = circuitIds[i];
      const circuit = { ...circuits[id] };
      if (!circuit) {
        continue;
      }

      let updated = {
        ...circuit,
        ...(from && { from }),
        ...(to && { to }),
        ...(length && { length }),
        dateModified: now - i
      };

      if (simpull !== 'no-change') {
        updated.simpull = simpull === 'yes';
      }

      if (this.exceedsMaxLength(updated.length)) {
        const { size, diameter } = circuit.conductors[0];
        const conductors = circuit.conductors.map(conductor => {
          return {
            ...conductor,
            size,
            diameter
          };
        });

        updated = {
          ...updated,
          conductors
        };
      }

      const products = updated.conductors
        .map(conductor => {
          const metal = conductor.isGroundConductor
            ? conductor.groundMetal
            : circuit.metal;

          return productFinder.getProduct(
            this.props.catalog,
            metal,
            circuit.insulation,
            conductor.size,
            conductor.color
          );
        })
        .filter(Boolean);

      if (updated.conductors.length !== products.length) {
        // the number of conductors doesn't match the number of products we found.
        // that means one of the conductors isn't configured correctly.
        return false;
      }

      updatedCircuits[id] = {
        ...updated,
        skus: products.map(p => p.stock_number),
        weight: this.getWeight(updated.length, products),
        volume: this.getVolume(updated.length, products)
      };
    }

    return updatedCircuits;
  };

  /**
   * When the user submits the mass edit circuits form.
   */
  onConfirm = () => {
    this.setState({ saving: true, confirmError: false });

    const { catalog, simreels } = this.props;
    const { circuitIds, jobId, onSuccess } = this.props.payload;
    const { circuits } = this.props.job;

    const updatedCircuits = this.getUpdatedCircuits();
    if (!updatedCircuits) {
      this.setState({ saving: false, confirmError: true });
      return;
    }

    const adjustedCircuits = {
      ...circuits,
      ...updatedCircuits
    };

    const lengthHasChanged = this.state.length !== '';
    let reels = this.props.job.reels.slice(0);
    let hasCapacityError = false;

    // if length has changed update reels
    if (lengthHasChanged) {
      reels = reels.map(reel => {
        const hasSelectedCircuits =
          reel.circuits &&
          reel.circuits.some(c => circuitIds.indexOf(c.id) !== -1);

        // if the reel doesn't have selected circuits, return original reel
        if (!hasSelectedCircuits) {
          return reel;
        }

        // pass in adjusted circuits with updated length and get updated reel product
        const newReel = getUpdatedReelProduct(
          reel,
          adjustedCircuits,
          catalog,
          simreels
        );

        // if there is no available reel, the reel's capacity has been reached
        if (newReel === null) {
          this.setState({ exceedsCapacityError: true, saving: false });
          hasCapacityError = true;
          return reel;
        }

        return newReel;
      });
    }

    if (!hasCapacityError) {
      this.props.firestore
        .update(`jobs/${jobId}`, {
          circuits: adjustedCircuits,
          reels
        })
        .then(() => {
          this.setState({ saving: false });
          this.props.onCancel();
          if (onSuccess) onSuccess();
        })
        .catch(err => {
          this.setState({ saving: false, confirmError: true });
        });
    }
  };

  /**
   * Returns boolean to indicate if the given length exceeds the maximum length that supports different sizes.
   *
   * @param  {Number} length
   * @return {Boolean}
   */
  exceedsMaxLength(length) {
    const lengthInFeet = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return lengthInFeet > MAX_FEET_DIFF_SIZES;
  }

  /**
   * Calculate the sum total conductor weight for the given products.
   *
   * @param  {Number} length
   * @param  {Array} products
   * @return {Number}
   */
  getWeight(length, products) {
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return products.reduce((accumulator, product) => {
      return (
        accumulator + productFinder.getConductorWeight(product, lengthInFt)
      );
    }, 0);
  }

  /**
   * Calculate the sum total conductor volume for the given products.
   *
   * @param  {Number} length
   * @param  {Array}  products
   * @return {Number}
   */
  getVolume(length, products) {
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return products.reduce((accumulator, product) => {
      return (
        accumulator + productFinder.getConductorVolume(product, lengthInFt)
      );
    }, 0);
  }

  /**
   * Disable submit when we're saving OR
   * when the form hasn't changed.
   *
   * @return {Boolean}
   */
  shouldDisableSubmit() {
    return (
      this.state.saving ||
      (this.state.from === '' &&
        this.state.to === '' &&
        this.state.length === '' &&
        this.state.simpull === 'no-change')
    );
  }

  getModalMessage = () => {
    const { maxLengthWarning, exceedsCapacityError, confirmError } = this.state;
    if (confirmError) {
      return (
        <p className="message error-warning">
          We could not update the circuits as requested.
        </p>
      );
    } else if (exceedsCapacityError) {
      return (
        <p className="message error-warning">
          Circuit length exceeds reel capacity.
        </p>
      );
    } else if (maxLengthWarning) {
      return (
        <p className="message warning-message">
          All conductor sizes will be changed to the primary conductor.
        </p>
      );
    }
    return (
      <p className="message info-message">
        Fields left empty will keep existing values.
      </p>
    );
  };

  render() {
    const { circuitIds } = this.props.payload;
    const { job } = this.props;

    const count = circuitIds.length;
    const title = `Editing ${count} ${count > 1 ? 'Circuits' : 'Circuit'}`;

    return (
      <Modal
        title={title}
        className="edit-circuits-modal"
        loading={this.shouldDisableSubmit()}
        onCancel={this.props.onCancel}
        onConfirm={this.onConfirm}
      >
        <Input
          type="text"
          label="From"
          value={this.state.from}
          onChange={this.onChangeField('from')}
          maxLength="15"
        />
        <Input
          type="text"
          label="To"
          value={this.state.to}
          onChange={this.onChangeField('to')}
          maxLength="15"
        />

        <Input
          type="text"
          label="Length"
          placeholder={job.metric ? 'Meters' : 'Feet'}
          value={this.state.length}
          onChange={this.onChangeLength}
          maxLength="6"
        />
        <div>
          <Dropdown
            id="simpull"
            label={<SimPullHead />}
            value={this.state.simpull}
            placeholder="Please Select"
            onSelect={this.onChangeSimpull}
            options={[
              { value: 'no-change', label: 'Do Not Change' },
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' }
            ]}
          />
        </div>
        {this.getModalMessage()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  const jobs = state.firestore.data[`jobs-${props.payload.projectId}`];
  const job = jobs[props.payload.jobId];
  const catalog = state.firestore.data.catalogs[job.country];
  const simreels = state.firestore.data.catalogs.simreels;
  return {
    job,
    catalog,
    simreels
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.payload.projectId}`,
        where: [
          ['projectId', '==', props.payload.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      { collection: 'catalogs' }
    ];
  }),
  connect(mapStateToProps)
)(EditCircuits);
