import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import withNotFoundRedirect from '../../common/withNotFoundRedirect';
import { changeSelectedReel } from '../../actions/AppActions';
import { showToast } from '../../actions/ToastActions';

import CreateReel from './CreateReel';

const mapStateToProps = ({ firestore: { data } }, props) => ({
  projects: data.projects,
  jobs: data[`jobs-${props.match.params.projectId}`],
  catalogs: data.catalogs
});

const mapDispatchToProps = dispatch => {
  return {
    changeSelectedReel: index => dispatch(changeSelectedReel(index)),
    showToast: (message, type) => dispatch(showToast(message, type))
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'projects',
        where: ['userId', '==', store.firebase.auth().currentUser.uid]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${props.match.params.projectId}`,
        where: [
          ['projectId', '==', props.match.params.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      },
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNotFoundRedirect('jobId', 'jobs')
)(CreateReel);
