import React, { Component } from 'react';
import CreateReelForm from './CreateReelForm';

export default class CreateReel extends Component {
  state = {
    saving: false
  };

  render() {
    const { match, jobs, catalogs } = this.props;

    const jobId = match.params.jobId || false;
    const job = jobs && jobId ? jobs[jobId] : {};
    const isJob = job && job.name;
    const catalog = catalogs && catalogs[job.country || 'us'];

    return (
      <div className="project reel">
        <h2 className="page-title">Create Reel</h2>
        {isJob && catalog && (
          <CreateReelForm
            job={job}
            jobId={jobId}
            onSubmit={this.onSubmit}
            saving={this.state.saving}
            reelTypes={catalog.reels.items}
            returnToList={this.returnToList}
            simreelTypes={this.props.catalogs.simreels}
          />
        )}
      </div>
    );
  }

  /**
   * Return to the configure page.
   */
  returnToList = () => {
    const { history, match } = this.props;
    history.replace(`${match.url.replace('/reel', '')}`);
  };

  /**
   * Persist the new reel to firebase.
   *
   * @param {Object} reel
   */
  onSubmit = reel => {
    this.setState({ saving: true });
    const { match, jobs } = this.props;

    const jobId = match.params.jobId || false;
    const job = jobs && jobId ? jobs[jobId] : {};
    const reels = [...job.reels, reel];

    this.props.firestore
      .update(`jobs/${jobId}`, { reels })
      .then(() => {
        this.setState({ saving: false });
        this.props.changeSelectedReel(reels.length - 1);
        this.returnToList();
      })
      .catch(error => {
        this.setState({ saving: false });
        this.props.showToast(
          'There was a problem creating your new reel.',
          'error'
        );
      });
  };
}
