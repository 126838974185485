import ActionTypes from '../constants/ActionTypes';
import ModalTypes from '../constants/ModalTypes';

export const initialState = {
  display: false,
  type: null,
  payload: null
};

export default function modalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.SHOW_DELETE_CIRCUIT_MODAL:
      return {
        display: true,
        type: ModalTypes.DELETE_CIRCUIT,
        payload: action.deleteCircuitObj
      };

    case ActionTypes.SHOW_DELETE_MV_CIRCUITS_MODAL:
      return {
        display: true,
        type: ModalTypes.DELETE_MV_CIRCUITS,
        payload: action.payload
      };

    case ActionTypes.SHOW_DELETE_JOB_MODAL:
      return {
        display: true,
        type: ModalTypes.DELETE_JOB,
        payload: action.deleteJobObj
      };

    case ActionTypes.SHOW_DELETE_REEL_MODAL:
      return {
        display: true,
        type: ModalTypes.DELETE_REEL,
        payload: action.deleteReelObj
      };

    case ActionTypes.SHOW_REMOVE_CIRCUIT_MODAL:
      return {
        display: true,
        type: ModalTypes.REMOVE_CIRCUIT_FROM_REEL,
        payload: action.removeCircuitObj
      };

    case ActionTypes.SHOW_EDIT_CIRCUITS_MODAL:
      return {
        display: true,
        type: ModalTypes.EDIT_CIRCUITS,
        payload: action.payload
      };

    case ActionTypes.SHOW_EDIT_MV_CIRCUITS_MODAL:
      return {
        display: true,
        type: ModalTypes.EDIT_MV_CIRCUITS,
        payload: action.payload
      };

    case ActionTypes.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
}
