import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import CreateAccount from './CreateAccount';

export class EditAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }

  handleLogout() {
    this.props.firebase
      .logout()
      .then(() => {
        this.props.dispatch({
          type: '@@reduxFirestore/CLEAR_DATA'
        });
        // route to home screen on successful logout
        this.props.history.push('/login', null);
      })
      .catch(error => {
        this.setState({ message: error.message });
      });
  }

  render() {
    return (
      <div className="edit-account">
        <h2 className="page-title">Edit Account Settings</h2>
        <div className="content-top">
          <div className="title">{this.props.profile.name}</div>
          <div className="email">{this.props.auth.email}</div>
          <div
            className="logout"
            onClick={e => {
              this.handleLogout(e);
            }}
          >
            Logout
          </div>
        </div>
        <div className="content-bottom lb">
          <CreateAccount edit history={this.props.history} />
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile, // pass profile data as this.props.profile
    auth: state.firebase.auth // pass auth data as this.props.auth
  }))
)(EditAccount);
