import ActionTypes from '../constants/ActionTypes';

export const initialState = {
  overflowCloseFunction: false,
  toastMessage: '',
  toastType: false,
  toastPass: false,
  selectedReel: null
};

const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SHOW_TOAST:
      return {
        ...state,
        toastType: action.messageType,
        toastMessage: action.message,
        toastPass: action.passToNewRoute
      };

    case ActionTypes.HIDE_TOAST:
      return {
        ...state,
        toastType: false,
        toastMessage: '',
        toastPass: false
      };

    case ActionTypes.RESET_TOAST_PASS:
      return {
        ...state,
        toastPass: false
      };

    case ActionTypes.CHANGE_SELECTED_REEL:
      return {
        ...state,
        selectedReel: action.index
      };

    case ActionTypes.RESET_OVERFLOW_CLOSE_FUNCTION:
      return {
        ...state,
        overflowCloseFunction: false
      };

    case ActionTypes.SET_OVERFLOW_CLOSE_FUNCTION:
      return {
        ...state,
        overflowCloseFunction: action.overflowCloseFunction
      };

    default:
      return state;
  }
};

export default appReducer;
