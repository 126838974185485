import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showDeleteReelModal } from '../../actions/ModalActions';
import { changeSelectedReel } from '../../actions/AppActions';
import { showToast } from '../../actions/ToastActions';
import Overflow from '../../patterns/Overflow';
import ReelBar from './ReelBar';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import NoColumnValue from '../../patterns/NoColumnValue';

const REEL_WEIGHT_WARNING_THRESHOLD = 6000;

/**
 * The clickable handle used for drag/drop functionality.
 */
const DragHandle = SortableHandle(() => <div className="drag-handle" />);

export class ReelListItem extends React.Component {
  /**
   * Makes firestore update call to duplicate the selected reel.
   */
  duplicateReel = () => {
    const { job, jobId } = this.props;

    const newCircuits = this.getDuplicateCircuits();
    const newReel = this.getDuplicateReel(newCircuits);

    this.props.firestore
      .update(`jobs/${jobId}`, {
        reels: [...job.reels, newReel],
        circuits: { ...job.circuits, ...newCircuits }
      })
      .then(() => {
        this.props.showToast(
          'Your reel was successfully duplicated.',
          'success'
        );
        this.props.changeSelectedReel(job.reels.length);
      })
      .catch(err => {
        this.props.showToast(
          'The selected reel could not be duplicated at this time. Please try again later.',
          'error'
        );
      });
  };

  /**
   * Dispatch the delete reel modal action.
   */
  onDeleteReel = () => {
    this.props.showDeleteReelModal({
      projectId: this.props.projectId,
      jobId: this.props.jobId,
      reelId: this.props.reelId
    });
  };

  /**
   * Duplicate the circuits on the reel,
   * use job.circuits to get the circuit object details.
   *
   * @return {Object}
   */
  getDuplicateCircuits() {
    const { reel, job } = this.props;
    const now = Date.now();

    if (!reel.circuits) {
      return {};
    }

    return reel.circuits.reduce((acc, circuit, index) => {
      const dateCreated = now - index;
      acc[dateCreated] = {
        ...job.circuits[circuit.id],
        dateCreated,
        dateModified: dateCreated
      };

      return acc;
    }, {});
  }

  /**
   * Get the new reel object using the old reel
   * and the new circuits that were just created.
   *
   * @param  {Object} newCircuits
   * @return {Object}
   */
  getDuplicateReel(newCircuits) {
    const { reel, job } = this.props;

    const names = job.reels.map(r => r.name);
    return {
      ...reel,
      name: this.getDuplicateName(names, reel.name),
      circuits: Object.keys(newCircuits).map((id, colorKey) => ({
        id,
        colorKey
      }))
    };
  }

  /**
   * When duplicating a job, figure out what the new reel's name should be.
   *
   * Append "Copy" to the name. If that already exists, continue adding "(X)"
   * until we get to an X value that no longer exists.
   *
   * @param  {Array} names
   * @param  {String} oldName
   * @return {String}
   */
  getDuplicateName(names, oldName) {
    let count = 1;
    let newName = oldName + ' Copy';

    while (names.indexOf(newName) !== -1) {
      newName = oldName + ` Copy (${count})`;
      count++;
    }

    return newName;
  }

  render() {
    const reel = this.props.reel;
    const reelProduct = reel && reel.reelProduct;
    // calculate weight and weight %
    const reelWeight = reelProduct && reelProduct.reel_weight;
    const circuitWeight = (reel && reel.circuitWeight) || 0;
    const reelTotalWeightCapacity =
      reelProduct && reelProduct.total_weight_capacity;
    let reelWeightPercent = 0;
    let totalReelWeight = Math.round(reelWeight + circuitWeight);

    if (circuitWeight && reelTotalWeightCapacity && reelWeight) {
      reelWeightPercent =
        circuitWeight / (reelTotalWeightCapacity - reelWeight);
    }

    // calculate volume %
    let reelVolumePercent = 0;
    const volumeCapacity = reel.maxReelProductVolume || 1000;
    if (reel.currentVolume) {
      reelVolumePercent = reel.currentVolume / volumeCapacity;
    }
    let exceedsWeightWarningThreshold =
      totalReelWeight > REEL_WEIGHT_WARNING_THRESHOLD;

    const className = classNames('reel-list-item-container', {
      selected: this.props.selected,
      selectedExceedsThreshold:
        this.props.selected && exceedsWeightWarningThreshold
    });

    return (
      <div className={className} onClick={this.props.onClick}>
        <div className="item-header">
          <div className="header-left" title={reel.name}>
            {reel.name}
          </div>
          <div className="header-right">
            <div className="package-name">
              {reelProduct && reelProduct.name}
            </div>
            <Overflow>
              <li className="actionable" onClick={this.props.onClickEdit}>
                Edit
              </li>
              <li className="actionable" onClick={this.duplicateReel}>
                Duplicate
              </li>
              {!this.props.quoteRequested && (
                <li className="actionable" onClick={this.onDeleteReel}>
                  Delete
                </li>
              )}
            </Overflow>
            <DragHandle />
          </div>
        </div>
        <div className="item-body">
          <div className="info-row">
            <div className="dimensions">
              <div className="height">
                Height: {`${reelProduct && reelProduct.diameter}"`}
              </div>
              <div>
                Width:{' '}
                {reelProduct && reelProduct.width ? (
                  reelProduct.width + `"`
                ) : (
                  <NoColumnValue />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="capacity">
          <div className="header-left">Capacity:</div>

          <div className="info-row">
            <div>Height/Width:</div>
            <ReelBar value={reelVolumePercent} />
          </div>

          <div className="info-row">
            <div>Weight:</div>
            <div className="right-side">
              <div>{reelProduct && `${totalReelWeight}lb.`}</div>
              <ReelBar value={reelWeightPercent} />
            </div>
          </div>

          {exceedsWeightWarningThreshold ? (
            <div>
              <div className="info-message">
                This reel weighs more than 6000LB.
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const jobs = state.firestore.data[`jobs-${props.projectId}`];
  return {
    job: jobs[props.jobId]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showDeleteReelModal: deleteReelObj => {
      dispatch(showDeleteReelModal(deleteReelObj));
    },
    changeSelectedReel: index => {
      dispatch(changeSelectedReel(index));
    },
    showToast: (message, type) => {
      dispatch(showToast(message, type));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'jobs',
        storeAs: `jobs-${props.projectId}`,
        where: [
          ['projectId', '==', props.projectId],
          ['userId', '==', store.firebase.auth().currentUser.uid]
        ]
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SortableElement(ReelListItem));
