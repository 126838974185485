import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';
import { changeSelectedReel } from '../actions/AppActions';
import cloneDeep from 'lodash/cloneDeep';
import Modal from './Modal';

export class DeleteReel extends Component {
  state = { saving: false };

  onClickDelete = () => {
    this.setState({ saving: true });
    const deleteReelObj = this.props.payload;
    const job = this.props.jobs[deleteReelObj.jobId];
    const reels = cloneDeep(job.reels);
    const previousCount = reels.length;
    // copy reel list and remove item
    let newReels = reels.splice(0);
    newReels.splice(deleteReelObj.reelId, 1);
    // save to firebase
    this.props.firestore
      .update(`jobs/${deleteReelObj.jobId}`, {
        reels: newReels
      })
      .then(() => {
        this.setState({ saving: false });
        // change selectedReel so that something stays highlighted
        let currentReel =
          this.props.appState && this.props.appState.selectedReel;
        if (previousCount === 1) {
          // set to null if no more reels
          this.props.changeSelectedReel(null);
        } else if (currentReel === 0) {
          // if deleting first reel, keep currentReel as 0
          this.props.changeSelectedReel(0);
        } else {
          // otherwise select the prior reel
          this.props.changeSelectedReel(currentReel - 1);
        }
        this.props.onCancel();
      })
      .catch(error => {
        this.setState({ saving: false });
        this.props.showToast('Reel could not be deleted.', 'error-persist');
      });
  };

  render() {
    return (
      <Modal
        loading={this.state.saving}
        message="Are you sure you want to delete this reel?"
        onCancel={this.props.onCancel}
        onConfirm={this.onClickDelete}
        confirmText="Delete"
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    jobs: state.firestore.data[`jobs-${props.payload.projectId}`],
    appState: state.appState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType) => {
      dispatch(showToast(message, messageType));
    },
    changeSelectedReel: index => {
      dispatch(changeSelectedReel(index));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeleteReel);
