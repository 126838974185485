import React from 'react';
import { connect } from 'react-redux';
import { hideToast } from '../actions/ToastActions';
import checkSvg from '../assets/check.svg';
import alertSvg from '../assets/alert.svg';

const PERSIST = 'persist';

export class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.toastRef = null;
  }

  componentDidMount() {
    if (!this.toastRef) {
      return;
    }

    this.toastRef.addEventListener('animationend', e => {
      if (e.animationName === 'toast') {
        this.props.hideToast();
      }
    });
  }

  render() {
    const toastType = this.props.toastType || '';
    const toasting =
      toastType.length && toastType.indexOf(PERSIST) < 0 ? 'toasting' : '';
    const sticking = toastType.indexOf(PERSIST) > -1 ? 'persist' : '';
    const classNames = ['toast-message', toastType, toasting, sticking];

    if (this.props.loggedIn) {
      classNames.push('loggedIn');
    }
    // decide on which icon to render
    let toastIcon;
    toastType.includes('success')
      ? (toastIcon = checkSvg)
      : (toastIcon = alertSvg);

    return (
      <div
        id="toast-message"
        ref={ref => (this.toastRef = ref)}
        className={classNames.join(' ')}
      >
        <div className="container">
          <img src={toastIcon} className="toast-icon" alt={toastType} />
          <span className="toast-text">{this.props.toastMessage}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  toastType: state.appState && state.appState.toastType,
  toastMessage: state.appState && state.appState.toastMessage
});
const mapDispatchToProps = dispatch => ({
  hideToast: () => dispatch(hideToast())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toast);
