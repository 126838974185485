import React from 'react';
import SimPull from './SimPull';

class SimPullHead extends React.Component {
  render() {
    return (
      <span className="simpull-text">
        <SimPull key="sp-1" trademark={false} /> HEAD<sup>&reg;</sup> Pulling
        Heads
      </span>
    );
  }
}

export default SimPullHead;
