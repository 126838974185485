import React from 'react';
import Button from '../../patterns/Button';
import EmptySmall from '../../patterns/EmptySmall';
import ReelListItem from './ReelListItem';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from '../../utils/arrayMove';

/**
 * Container for the list of reels, wrapped in the react-sortable-hoc container
 * for that drag/drop-y goodness.
 */
const SortableList = SortableContainer(
  ({
    reels,
    jobId,
    projectId,
    reordering,
    quoteRequested,
    selectedReel,
    changeReel,
    goToEditReel
  }) => {
    return (
      <div className={reordering ? 'currently-reordering' : ''}>
        {reels.map((reel, index) => (
          <ReelListItem
            index={index}
            reel={reel}
            jobId={jobId}
            reelId={index}
            projectId={projectId}
            key={`reel-${index}`}
            quoteRequested={quoteRequested}
            selected={index === selectedReel}
            onClick={() => changeReel(index)}
            onClickEdit={goToEditReel(index)}
          />
        ))}
      </div>
    );
  }
);

export default class ReelList extends React.Component {
  state = {
    reordering: false
  };

  /**
   * Some misc navigation related functions.
   */
  goToCreateReel = () => this.props.navigate('/reel');
  goToEditReel = reelId => event => {
    event.stopPropagation();
    this.props.navigate(`/reel/${reelId}`);
  };

  /**
   * Callback for when the user initiates the drag/drop behavior.
   */
  onReorderStart = () => {
    this.setState({ reordering: true });
  };

  /**
   * Callback for when the user finishes the drag/drop behavior.
   * We should re-arrange the reels using the provided indexes and update firebase.
   *
   * @param {Object} - object that contains sort meta-data, including the old and new index positions
   */
  onReorderEnd = ({ oldIndex, newIndex }) => {
    const {
      jobId,
      firestore,
      changeReel,
      selectedReel: selectedReelIndex
    } = this.props;

    this.setState({ reordering: false });

    if (oldIndex === newIndex) {
      return;
    }

    const reels = arrayMove(this.props.reels, oldIndex, newIndex);

    // In order to keep the selected reel the same after re-ordering,
    // we have to figure out what the new index will be for the selected reel
    // and update our redux store with that new index.
    const selectedReel = this.props.reels[selectedReelIndex];
    const newSelectedReelIndex = reels.findIndex(
      r => r.name === selectedReel.name
    );
    changeReel(newSelectedReelIndex);

    firestore.update(`jobs/${jobId}`, { reels }).catch(() => {
      changeReel(selectedReelIndex);
      this.props.showToast(
        'There was a problem while re-ordering reels. Please try again later',
        'error'
      );
    });
  };

  render() {
    const {
      reels,
      projectId,
      jobId,
      quoteRequested,
      selectedReel,
      changeReel
    } = this.props;
    const { reordering } = this.state;

    return (
      <div className="reel-list">
        <div className="reel-title">
          <div className="reel-title-text">Reels</div>
          <Button className="add" onClick={this.goToCreateReel}>
            Create Reel
          </Button>
        </div>

        {reels && reels.length ? (
          <div className="reel-item-container">
            <div className="reel-header">
              <div className="reel-header-container">
                <div className="reel-header-text">name</div>
              </div>
              <div className="reel-header-container">
                <div className="reel-header-text">package</div>
              </div>
            </div>

            <SortableList
              jobId={jobId}
              reels={reels}
              projectId={projectId}
              changeReel={changeReel}
              selectedReel={selectedReel}
              quoteRequested={quoteRequested}
              goToEditReel={this.goToEditReel}
              reordering={reordering}
              helperClass="reordering-item"
              useDragHandle={true}
              onSortEnd={this.onReorderEnd}
              onSortStart={this.onReorderStart}
              transitionDuration={200}
            />
          </div>
        ) : (
          <EmptySmall type="no-reels" />
        )}
      </div>
    );
  }
}
