import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableBody from './TableBody';
import TableSortIcon from './TableSortIcon';
import { SORT_ASC, SORT_DESC } from './sortTypes';

export default class Table extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    sortable: PropTypes.bool,
    sortPreference: PropTypes.object,
    onChangeSort: PropTypes.func,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        field: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        sortable: PropTypes.bool
      })
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        // An object that contains each field value, keys should match the
        // column headers. These values are used for sorting, when sorting is enabled.
        fields: PropTypes.object,

        // JSX element that is rendered for each table row
        row: PropTypes.element.isRequired
      })
    )
  };

  static defaultProps = {
    className: '',
    sortable: false,
    sortPreference: {}
  };

  /**
   * Event handler for when the user changes the table sort preferences.
   * Cycles between: asc, desc, and off.
   *
   * @param  {Object} column
   * @return {Function}
   */
  onChangeSort = column => {
    return event => {
      if (!column.sortable) {
        return;
      }

      const { sortPreference } = this.props;

      // If the sort field is different from before, always start them on DESC
      if (column.field !== sortPreference.field) {
        this.props.onChangeSort({
          field: column.field,
          direction: SORT_ASC
        });
        return;
      }

      // This switch statement will cycle through the 3 difference available sorting options:
      //  - if nothing is previously selected, the first option is ASC
      //  - if ASC is selected, we move to DESC
      //  - if DESC is selected, we move to unselected
      let newSort = { field: column.field };
      switch (sortPreference.direction) {
        case SORT_ASC:
          newSort.direction = SORT_DESC;
          break;
        case SORT_DESC:
          newSort = {};
          break;
        default:
          newSort.direction = SORT_ASC;
          break;
      }

      this.props.onChangeSort(newSort);
    };
  };

  /**
   * Renders the table header/columns.
   */
  renderHeader = () => {
    const { columns, sortable, sortPreference } = this.props;

    return (
      <thead>
        <tr>
          {columns.map(column => {
            const className = classNames(column.className || column.field, {
              sortable: sortable && column.sortable,
              'sort-selected': column.field === sortPreference.field
            });

            return (
              <th
                key={column.field}
                className={className}
                onClick={this.onChangeSort(column)}
              >
                {column.label || ''}
                {sortable && column.sortable && (
                  <TableSortIcon
                    field={column.field}
                    sortPreference={sortPreference}
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  render() {
    const { id, sortable, sortPreference, data, children } = this.props;

    const className = classNames('table', this.props.className, { sortable });

    return (
      <table id={id} className={className}>
        {this.renderHeader()}

        {data && data.length && (
          <TableBody
            data={data}
            sortable={sortable}
            sortPreference={sortPreference}
          />
        )}

        {children}
      </table>
    );
  }
}
