import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast } from '../actions/ToastActions';
import Modal from './Modal';

export class DeleteMVCircuits extends Component {
  state = { saving: false };

  onSubmit = () => {
    const { payload, jobs } = this.props;
    const job = jobs[payload.jobId];
    const circuits = { ...job.circuits };

    this.setState({ saving: true });

    payload.circuitIds.forEach(id => delete circuits[id]);

    this.props.firestore
      .update(`jobs/${payload.jobId}`, { circuits })
      .then(() => {
        this.setState({ saving: false });
        this.props.onCancel();
        if (payload.onSuccess) {
          payload.onSuccess();
        }
      })
      .catch(err => {
        this.setState({ saving: false });
        this.props.onCancel();
        this.props.showToast(
          `${
            payload.circuitIds.length > 1 ? 'Circuits' : 'Circuit'
          } could not be deleted.`,
          'error'
        );
      });
  };

  render() {
    const { payload, onCancel } = this.props;
    const isPlural = payload.circuitIds.length > 1;
    const message = `Are you sure you want to delete ${
      payload.circuitIds.length
    } ${isPlural ? 'circuits' : 'circuit'}?`;

    return (
      <Modal
        message={message}
        onCancel={onCancel}
        loading={this.state.saving}
        onConfirm={this.onSubmit}
        confirmText="Confirm"
      />
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(
    ({ firestore: { data } }, props) => ({
      jobs: data[`jobs-${props.payload.projectId}`]
    }),
    dispatch => ({
      showToast: (message, messageType) => {
        dispatch(showToast(message, messageType));
      }
    })
  )
)(DeleteMVCircuits);
