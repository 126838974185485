import React from 'react';
import Button from '../patterns/Button';

/**
 * The generic modal display component used by our other modal types.
 * This component should never be used on it's own, you need to wrap it in another
 * component that includes the proper functionality.
 */
const Modal = ({
  title,
  children,
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  loading = false,
  className = ''
}) => (
  <div id="modal" className={className}>
    {title && <h1>{title}</h1>}
    {children ? (
      <div className="modal-text">{children}</div>
    ) : (
      <div className="modal-text">
        <div className="alert-icon" />
        <div>{message}</div>
      </div>
    )}
    <div className="button-container">
      <Button className="cancel" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button className="confirm" onClick={onConfirm} disabled={loading}>
        {confirmText}
      </Button>
    </div>
  </div>
);

export default Modal;
