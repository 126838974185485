import React from 'react';
import ReelCapacityGraph from './ReelCapacityGraph';

export class ReelCapacityContainer extends React.Component {
  render() {
    const reel = this.props.activeReel;
    const reelProduct = reel.reelProduct;
    // calculate weight and weight %
    const reelWeight = reelProduct && reelProduct.reel_weight;
    const circuitWeight = reel.circuitWeight;
    const reelTotalWeightCapacity =
      reelProduct && reelProduct.total_weight_capacity;
    let reelWeightPercent = 0;
    if (circuitWeight && reelTotalWeightCapacity && reelWeight) {
      reelWeightPercent =
        circuitWeight / (reelTotalWeightCapacity - reelWeight);
    }
    // calculate volume %
    let reelVolumePercent = 0;
    const volumeCapacity = reel.maxReelProductVolume || 1000;
    if (reel.currentVolume) {
      reelVolumePercent = reel.currentVolume / volumeCapacity;
    }
    return (
      <div className="reel-capacity-container">
        <ReelCapacityGraph value={reelVolumePercent} label="Height / Width" />
        <ReelCapacityGraph value={reelWeightPercent} label="Weight" />
      </div>
    );
  }
}

export default ReelCapacityContainer;
