import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './css/app.scss';
import Home from './home/Home';
import ResetPassword from './account/ResetPassword';
import ProjectsHome from './projects/ProjectsHome';
import CreateProject from './projects/CreateProject';
import ProjectSummary from './projects/ProjectSummary';
import ProjectSettings from './projects/ProjectSettings';
import ReelSummary from './jobs/ReelSummary';
import FeederSummary from './jobs/FeederSummary';
import CreateJob from './jobs/CreateJob';
import JobSettings from './jobs/JobSettings';
import DuplicateJob from './jobs/DuplicateJob';
import Configure from './jobs/Configure';
import CreateReel from './jobs/CreateReel';
import CreateCircuit from './jobs/CreateCircuit';
import EditCircuit from './jobs/EditCircuit';
import EditReelContainer from './jobs/EditReelContainer';
import EditAccount from './account/EditAccount';
import CreateAccount from './account/CreateAccount';
import MVCreateCircuit from './MV/MVCreateCircuit';
import MVEditCircuit from './MV/MVEditCircuit';
import Header from './common/Header';
import Footer from './common/Footer';
import ModalContainer from './modals';
import Toast from './common/Toast';
import ChangeLocation from './common/ChangeLocation';
import AuthRoute from './common/AuthRoute';
import withJobTypeRedirect from './common/withJobTypeRedirect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import UnauthRoute from './common/UnauthRoute';
import authHelpers from './utils/authHelpers';
import Viewers from './viewers/Viewers';
import NotFound from './common/NotFound';
import MVFeederSummary from './MV/MVFeederSummary';
import MVSubmitRFQ from './MV/MVSubmitRFQ';

class App extends React.Component {
  render() {
    const { loggedIn } = this.props;

    return (
      <Router>
        <ChangeLocation>
          <ModalContainer />
          {loggedIn && <Header />}
          <main className={loggedIn ? null : 'login'}>
            <div className="container">
              <Switch>
                <AuthRoute exact path="/" component={ProjectsHome} />
                <UnauthRoute path="/login" component={Home} />
                <UnauthRoute path="/register" component={CreateAccount} />
                <UnauthRoute path="/reset-password" component={ResetPassword} />
                <AuthRoute exact path="/projects" component={ProjectsHome} />
                <AuthRoute exact path="/account" component={EditAccount} />

                <AuthRoute
                  exact
                  path="/projects/create"
                  component={CreateProject}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/"
                  component={ProjectSummary}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/settings"
                  component={ProjectSettings}
                />

                {/* ---- Job Routes ---- */}

                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/create"
                  component={CreateJob}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/settings"
                  component={JobSettings}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/duplicate"
                  component={DuplicateJob}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/viewers"
                  component={withJobTypeRedirect(Viewers, MVSubmitRFQ)}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/summary/feeder"
                  component={withJobTypeRedirect(
                    FeederSummary,
                    MVFeederSummary
                  )}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/summary/reels"
                  component={ReelSummary}
                />

                {/* ---- Configure Routes ---- */}

                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/configure"
                  component={Configure}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/configure/reel"
                  component={CreateReel}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/configure/reel/:reelId"
                  component={EditReelContainer}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/configure/circuit"
                  component={withJobTypeRedirect(
                    CreateCircuit,
                    MVCreateCircuit
                  )}
                />
                <AuthRoute
                  exact
                  path="/projects/:projectId/jobs/:jobId/configure/circuit/:circuitId"
                  component={withJobTypeRedirect(EditCircuit, MVEditCircuit)}
                />
                <AuthRoute exact path="/404" component={NotFound} />
                <AuthRoute component={NotFound} />
              </Switch>
              <Toast loggedIn={loggedIn} />
            </div>
          </main>
          <Footer />
        </ChangeLocation>
      </Router>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    loggedIn: authHelpers.isLoggedIn(state.firebase.auth)
  }))
)(App);
