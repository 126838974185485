import React from 'react';
import Tooltip from './Tooltip';

class HoverableText extends React.Component {
  state = { isHovered: false };

  render() {
    const { text = null, isHoverable = false } = this.props;

    return (
      <div
        className={'hoverable-text'}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {isHoverable && (
          <Tooltip
            text={text}
            isHovered={this.state.isHovered}
            caretPosition={'left'}
          />
        )}
        <p>{text}</p>
      </div>
    );
  }
}

export default HoverableText;
