import React, { Component } from 'react';
import SelectedConductors from './SelectedConductors';
import SizePicker from './SizePicker';
import ColorPicker from './ColorPicker';

export default class ConductorBuilder extends Component {
  onChangeColor = color => {
    const { selectedIndex, onChangeColor } = this.props;
    onChangeColor(color, selectedIndex);
  };

  onChangeSize = size => {
    const { selectedIndex, onChangeSize, sizes } = this.props;

    const selectedSize = sizes.filter(s => s.id === size)[0];
    if (selectedSize) {
      onChangeSize(size, selectedSize.diameter, selectedIndex);
    }
  };

  render() {
    const {
      selectedIndex,
      applyToAll,
      onChangeApplyToAll,
      applyToAllDisabled,
      availableSizes
    } = this.props;
    const conductors = this.appendColors();
    const availableColors = this.getAvailableColors();

    return (
      <div className="conductor-builder">
        <SelectedConductors
          selectedIndex={selectedIndex}
          conductors={conductors}
          availableSizes={availableSizes}
          onChange={this.props.onChangeSelected}
        />
        <div className="conductor-builder__size-color-picker">
          <SizePicker
            selectedIndex={selectedIndex}
            applyToAll={applyToAll}
            availableSizes={availableSizes}
            conductor={conductors[selectedIndex]}
            onChange={this.onChangeSize}
            onChangeApplyToAll={onChangeApplyToAll}
            applyToAllDisabled={applyToAllDisabled}
          />
          <ColorPicker
            selectedIndex={selectedIndex}
            onChange={this.onChangeColor}
            conductor={conductors[selectedIndex]}
            availableColors={availableColors}
          />
        </div>
      </div>
    );
  }

  /**
   * Returns a new conductors array that has the color object
   * added to each conductor, so we have access to RGB values.
   */
  appendColors() {
    const { colors, conductors } = this.props;

    return conductors.map(conductor => {
      let color = conductor.color;
      if (!color) {
        return conductor;
      }

      if (colors[conductor.color]) {
        color = colors[conductor.color];
      }

      return {
        ...conductor,
        color
      };
    });
  }

  /**
   * Get a list of available colors to display in the color picker component.
   *
   * NOTE: We're filtering out duplicate colors here on the off chance that the product
   * catalog contains products that match metal/insulation/size/color but have different SKUs.
   * This is mostly a precaution and shouldn't affect the production data set.
   *
   * @return {Array}
   */
  getAvailableColors() {
    const { selectedIndex, colors, colorsBySize, conductors } = this.props;
    const conductor = conductors[selectedIndex];

    const availableColors = colorsBySize[conductor.size];
    if (!availableColors) {
      return [];
    }

    return availableColors
      .filter((color, index) => {
        return availableColors.indexOf(color) === index;
      })
      .map(color => {
        return colors[color];
      });
  }
}
