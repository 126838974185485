import React from 'react';
import Logo from '../common/Logo';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import authHelpers from '../utils/authHelpers';

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      message: '',
      emailError: false,
      passwordError: false,
      viewVerify: false,
      clickedVerify: false,
      saving: false
    };
  }

  handleEmail(e) {
    this.setState({
      email: e.target.value,
      emailError: false,
      passwordError: false,
      viewVerify: false
    });
  }

  handlePassword(e) {
    this.setState({
      password: e.target.value,
      passwordError: false,
      emailError: false,
      viewVerify: false
    });
  }

  handleLogin(e) {
    this.setState({ saving: true });
    e.preventDefault();
    let email = this.state.email.trim();
    let password = this.state.password;
    const props = this.props;

    props.firebase.logout().then(() =>
      props.firebase
        .login({
          email: email,
          password: password
        })
        .then(() => {
          this.setState({ saving: false });
          // route to projects screen on successful login
          if (authHelpers.isLoggedIn(props.auth)) {
            props.history.push('/projects', null);
          } else {
            this.setState({
              message: `This account hasn't been verified.`,
              viewVerify: true,
              emailError: true,
              clickedVerify: false
            });
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          if (error.code === 'auth/invalid-email') {
            this.setState({
              message: 'This email format is invalid.',
              emailError: true
            });
          } else if (
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password'
          ) {
            this.setState({
              message: 'This email or password is incorrect.',
              emailError: true,
              passwordError: true
            });
          } else if (error.code === 'auth/network-request-failed') {
            this.setState({
              message: 'Please check your network connection.'
            });
          }
        })
    );
  }

  createAccount(e) {
    this.props.history.push('/register', null);
  }
  handleVerify() {
    const actionCodeSettings = {
      url: window.location.origin,
      handleCodeInApp: false
    };
    this.props.firebase
      .auth()
      .currentUser.sendEmailVerification(actionCodeSettings)
      .then(() => {
        this.setState({
          message: 'Verification email sent.',
          clickedVerify: true
        });
      });
  }
  render() {
    let message = this.state.clickedVerify ? (
      <div className="error-message success-message fade-in">
        <div className="success-icon" />
        {this.state.message}
        {this.state.viewVerify ? (
          <p className="resend-verify" onClick={() => this.handleVerify()}>
            Resend email.
          </p>
        ) : null}
      </div>
    ) : (
      <div className="error-message fade-in">
        <div className="alert-icon" />
        {this.state.message}
        {this.state.viewVerify ? (
          <p className="resend-verify" onClick={() => this.handleVerify()}>
            Resend verification email.
          </p>
        ) : null}
      </div>
    );
    return (
      <div className="wrapper">
        <Logo />
        <div className="home">
          {this.state.message ? message : null}
          <form
            onSubmit={e => {
              this.handleLogin(e);
            }}
          >
            <Input
              type="text"
              className={this.state.emailError ? 'error-border' : null}
              placeholder="Email"
              onChange={e => {
                this.handleEmail(e);
              }}
              value={this.state.email}
              autoComplete="email"
            />
            <Input
              type="password"
              className={this.state.passwordError ? 'error-border' : null}
              placeholder="Password"
              onChange={e => {
                this.handlePassword(e);
              }}
              value={this.state.password}
              autoComplete="current-password"
            />
            {/* disable button until email and password have input */}
            <Button
              type="submit"
              className="login"
              disabled={
                !(this.state.email && this.state.password) || this.state.saving
              }
              saving={this.state.saving}
            >
              Login
            </Button>
            <Link to="/reset-password" className="forgot-password">
              Forgot Password?
            </Link>
            <p className="line">
              <span>or</span>
            </p>
            <Button className="secondary" onClick={() => this.createAccount()}>
              Create Account
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    profile: state.firebase.profile, // pass profile data as this.props.profile
    auth: state.firebase.auth // pass auth data as this.props.auth
  }))
)(Home);
