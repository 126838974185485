import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MEDIUM_VOLTAGE = 'MV';

const mapStateToProps = ({ firestore: { data } }, props) => {
  const jobs = data[`jobs-${props.match.params.projectId}`];
  const job = (jobs && jobs[props.match.params.jobId]) || {};

  return { job };
};

// Create a temporary component so we can connect to the redux store,
// then call the render prop function passing along the job we fetched from the store.
const JobTypeRedirect = withRouter(
  connect(mapStateToProps)(props => props.render(props.job))
);

/**
 * This is an HOC responsible for rendering the proper component based on the job type.
 *
 * @param  {Component} SixHundredVoltComponent
 * @param  {Component} MVComponent
 * @return {JSX}
 */
export default function withJobTypeRedirect(
  SixHundredVoltComponent,
  MVComponent
) {
  return props => (
    <JobTypeRedirect
      render={job => {
        if (job.jobType && job.jobType === MEDIUM_VOLTAGE) {
          return <MVComponent {...props} />;
        }

        return <SixHundredVoltComponent {...props} />;
      }}
    />
  );
}
