import React from 'react';
import Logo from '../common/Logo';
import Input from '../patterns/Input';
import Button from '../patterns/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { showToast, hideToast } from '../actions/ToastActions';
import { SuccessToast, ErrorToast } from './Toasts';

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: '',
      emailError: false,
      saving: false
    };
  }

  /**
   * onChange handler for the email field.
   */
  onChangeEmail = event => {
    this.setState({
      email: event.target.value,
      emailError: false
    });
  };

  /**
   * Resend the password reset email.
   *
   * NOTE: This is executed from within the toast message,
   * but the context will be for the ResetPassword component.
   */
  resendEmail = () => {
    const { email } = this.state;

    this.props
      .sendEmail(email)
      .then(() => {
        this.props.showToast(
          <SuccessToast
            resending={true}
            onCancel={this.props.hideToast}
            onResend={this.resendEmail}
          />,
          'success-persist',
          true
        );
      })
      .catch(({ code }) => {
        if (code === 'auth/user-not-found') {
          this.props.showToast(
            <SuccessToast
              resending={true}
              onCancel={this.props.hideToast}
              onResend={this.resendEmail}
            />,
            'success-persist',
            true
          );
        }
      });
  };

  /**
   * Send the password reset email.
   */
  onSubmit = event => {
    event.preventDefault();
    const { email } = this.state;

    this.setState({ saving: true });

    this.props
      .sendEmail(email)
      .then(() => {
        this.setState({ saving: false });

        this.props.showToast(
          <SuccessToast
            onCancel={this.props.hideToast}
            onResend={this.resendEmail}
          />,
          'success-persist',
          true
        );

        this.props.history.push(`/login`, email);
      })
      .catch(error => {
        this.setState({ saving: false });

        switch (error.code) {
          case 'auth/invalid-email':
            this.setState({
              message: 'This email format is incorrect.',
              emailError: true
            });
            break;

          case 'auth/user-not-found':
            this.props.showToast(
              <SuccessToast
                onResend={this.resendEmail}
                onCancel={this.props.hideToast}
              />,
              'success-persist',
              true
            );

            this.props.history.push(`/login`, email);
            break;

          case 'auth/network-request-failed':
            this.setState({
              message: 'Please check your network connection.'
            });
            break;

          default:
            this.props.showToast(
              <ErrorToast onCancel={this.props.hideToast} />,
              'error-persist',
              true
            );

            this.props.history.push(`/login`, email);
            break;
        }
      });
  };

  render() {
    return (
      <div className="wrapper reset-password">
        <Logo />

        <div className="home reset-password">
          <h2>Reset Password</h2>

          <p className="informational-heading">
            Enter the email you signed up with, and we'll send you a link to
            reset your password.
          </p>

          {this.state.message && (
            <div className="error-message fade-in">
              <div className="alert-icon" />
              {this.state.message}
            </div>
          )}

          <form onSubmit={this.onSubmit}>
            <Input
              type="email"
              placeholder="Email"
              autoComplete="email"
              value={this.state.email}
              onChange={this.onChangeEmail}
              className={this.state.emailError ? 'error-border' : null}
            />

            <div className="button-container">
              <Button className="secondary" onClick={this.props.onCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                className="reset"
                saving={this.state.saving}
                disabled={!this.state.email || this.state.saving}
              >
                Reset
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    /**
     * Make the API call to send the password reset email through firebase.
     */
    sendEmail: email => {
      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: false
      };

      return props.firebase
        .auth()
        .sendPasswordResetEmail(email, actionCodeSettings);
    },

    /**
     * Redirect back to login when user clicks cancel.
     */
    onCancel: () => {
      props.history.push(`/login`, null);
    },

    showToast: (message, messageType, passToNewRoute) => {
      dispatch(showToast(message, messageType, passToNewRoute));
    },

    hideToast: () => dispatch(hideToast())
  };
};

export default compose(
  firestoreConnect(),
  connect(
    state => ({
      profile: state.firebase.profile, // pass profile data as this.props.profile
      auth: state.firebase.auth // pass auth data as this.props.auth
    }),
    mapDispatchToProps
  )
)(ResetPassword);
