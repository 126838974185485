import React from 'react';
import PropTypes from 'prop-types';
import { SORT_ASC, SORT_DESC } from './sortTypes';

/**
 * Render the table body rows. If the table supports sorting,
 * first sort the rows based on the sort preference, then render
 * each row.
 */
const TableBody = ({ sortable = false, data = [], sortPreference = {} }) => {
  const sortField = sortPreference.field;

  const sortAsc = (a, b) => {
    const aField = a.fields[sortField];
    const bField = b.fields[sortField];

    if (isNaN(Number(aField)) && isNaN(Number(bField))) {
      return aField.localeCompare(bField, 'en', { sensitivity: 'base' });
    }

    if (aField < bField) return -1;
    if (aField > bField) return 1;
    return 0;
  };

  const sortDesc = (a, b) => {
    const aField = a.fields[sortField];
    const bField = b.fields[sortField];

    if (isNaN(Number(aField)) && isNaN(Number(bField))) {
      return bField.localeCompare(aField, 'en', { sensitivity: 'base' });
    }

    if (bField < aField) return -1;
    if (bField > aField) return 1;
    return 0;
  };

  const rows = [...data];

  // Only sort if sort has been enabled on the parent Table component
  if (sortable) {
    // Initially sort everything by dateCreated,
    rows.sort((a, b) => b.fields.dateCreated - a.fields.dateCreated);

    // and only override that if the user has specified a preferred sort order.
    if (sortField) {
      rows.sort((a, b) => {
        return sortPreference.direction === SORT_ASC
          ? sortAsc(a, b)
          : sortDesc(a, b);
      });
    }
  }

  return <tbody>{rows.map(d => d.row)}</tbody>;
};

TableBody.propTypes = {
  sortable: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.object,
      render: PropTypes.func
    })
  ),
  sortPreference: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf([SORT_ASC, SORT_DESC])
  })
};

export default TableBody;
