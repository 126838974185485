/**
 * This error will be thrown when we encounter a
 * validation error during the template upload process.
 */
function TemplateValidationError(message) {
  this.message = message;
  this.name = this.constructor.name;
}

TemplateValidationError.prototype = Object.create(Error.prototype);

export default TemplateValidationError;
