import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="empty-container">
    <img src="/assets/img/404.svg" alt="404 - Not Found" />
    <p className="important-message">
      We can't find the page you're looking for.
    </p>
    <Link className="action" style={{ marginTop: '40px' }} to="/">
      Back to Home
    </Link>
  </div>
);

export default NotFound;
