import { MenuItem, openMenu, closeMenu } from 'react-aria-menubutton';

/**
 * Override some of the default behavior of a MenuItem
 * so that it works with the nested dropdown implementation.
 *
 * react-aria-menubutton uses React's context API to manage keyboard interactions,
 * focus changes, opening/closing menu, etc. Since I'm extending the MenuItem
 * component, we have access to this context object (this.context.ambManager).
 */
export default class NestedMenuItem extends MenuItem {
  /**
   * Override the keyDown callback to open/close the menu
   * instead of the default behavior.
   */
  handleKeyDown = event => {
    // When focus is on a nested menu item and you press the left arrow,
    // close the current menu and re-focus on the first parent menu item.
    if (event.key === 'ArrowLeft') {
      closeMenu(this.props.value);
      this.context.ambManager.focusItem(0);
      return;
    }

    // We want to ignore this keyDown event if:
    //  - the key pressed was NOT: Enter, Space, or ArrowRight
    //  - the tag rendered is a link
    const ignoreEvent =
      (event.key !== 'Enter' &&
        event.key !== ' ' &&
        event.key !== 'ArrowRight') ||
      (this.props.tag === 'a' && this.props.href);

    if (ignoreEvent) return;

    // That means we only open the menu if the user has pressed
    // Enter, Space, or ArrowRight.
    event.preventDefault();
    openMenu(this.props.value);
  };

  /**
   * For nested dropdowns, when the menu item gets selected,
   * we should just open up the nested wrapper instead of the normal behavior.
   */
  selectItem = event => {
    openMenu(this.props.value);
  };
}
