import { connect } from 'react-redux';
import { hideModal } from '../actions/ModalActions';
import ModalContainer from './ModalContainer';

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
