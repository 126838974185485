import React from 'react';
import Input from '../patterns/Input';
import Dropdown from '../patterns/Dropdown';
import Toggle from '../patterns/Toggle';
import Button from '../patterns/Button';
import Checkbox from '../patterns/Checkbox';
import ConductorBuilder from './ConductorBuilder';
import productFinder from '../utils/productFinder';
import convertUnits from '../utils/convertUnits';
import SimPullHead from '../patterns/SimPullHead';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import ConductorCountDropdown from './ConductorCountDropdown';
import { connect } from 'react-redux';
import { showToast } from '../actions/ToastActions';
import cloneDeep from 'lodash/cloneDeep';

const MAX_FEET_DIFF_SIZES = 250;
const MAX_SIZE_DIFF = 10;
const MAX_SIZE_WARNING_DIFF = 6;
const MAX_QUANTITY = 99;
const rex = /^\d*$/;

function sameSizeReducer(result, currentValue, currentIndex, conductors) {
  return result && currentValue.size === conductors[0].size;
}

export function isSameSizesMandatory(length, metric) {
  const asNumber = parseInt(length, 10);
  const lengthInFt = metric ? convertUnits.fromMToFt(asNumber) : asNumber;

  return lengthInFt > MAX_FEET_DIFF_SIZES;
}

export const DEFAULT_METAL = 'CU';
export const DEFAULT_INSULATION_US = 'THHN';
export const DEFAULT_INSULATION_CA = 'T90';
export const DEFAULT_SIMPULL = true;
export const DEFAULT_NUM_CONDUCTORS = 4;
export const DEFAULT_CONDUCTOR_SIZE = '500';
export const DEFAULT_CONDUCTOR_COLOR = 'Black';
export const DEFAULT_GROUND_METAL_NONE = 'none';

export class CircuitBuilder extends React.Component {
  constructor(props) {
    super(props);

    const formState = this.getDefaultFormState();

    this.state = {
      saving: false,
      sameSizes: true,
      sameSizesMandatory: false,
      quantity: 1,
      saveAsDefault: false,
      selectedConductor: 0,
      ...formState
    };
  }
  // getting ground values from ground conductor for editing a circuit with a ground on parallel
  getGroundValues(circuit) {
    let groundValues = {};
    let updatedConductors = (circuit.conductors || []).slice(0);
    updatedConductors.forEach((c, index) => {
      if (c.isGroundConductor) {
        updatedConductors.splice(index, 1);
        groundValues.conductors = updatedConductors;
        groundValues.numConductors = updatedConductors.length;
        groundValues.groundSize = c.size;

        // (SWC2-604) Falling back to circuit.metal for circuits that were created
        // before adding the groundMetal property here.
        groundValues.groundMetal = c.groundMetal || circuit.metal;
        return;
      }
    });
    return groundValues;
  }

  componentDidMount() {
    if (this.props.circuit) {
      let circuit = this.props.circuit;
      let newState = {
        from: circuit.from,
        to: circuit.to,
        metal: circuit.metal,
        insulation: circuit.insulation,
        length: circuit.length + '',
        simpullHead: circuit.simpull,
        numConductors: circuit.conductors
          ? circuit.conductors.length
          : circuit.colors.length,
        conductors: (circuit.conductors || []).slice(0),
        sameSizesMandatory: isSameSizesMandatory(
          circuit.length,
          this.props.job.metric
        )
      };
      const hasGroundConductor =
        circuit.conductors && circuit.conductors.some(c => c.isGroundConductor);
      // update ground field states if there is a ground on parallel
      if (hasGroundConductor) {
        const newGroundState = this.getGroundValues(circuit);
        newState = {
          ...newState,
          ...newGroundState,
          groundOnParallel: true
        };
      }
      if (newState.conductors.length > 1) {
        newState.sameSizes = newState.conductors.reduce(sameSizeReducer, true);
      } else if (
        newState.conductors.length < 1 &&
        (circuit.colors || []).length > 0 &&
        circuit.size &&
        circuit.diameter
      ) {
        let size = circuit.size;
        let diameter = circuit.diameter;
        circuit.colors.forEach(color => {
          newState.conductors.push({
            color,
            size,
            diameter
          });
        });
      }

      const available = this.getAvailableOptions(
        newState.metal,
        newState.insulation
      );

      newState.availableSizes = available.availableSizes;
      newState.availableColorsMap = available.availableColorsMap;

      this.setState(newState);
    }
  }

  submitCircuit = event => {
    event.preventDefault();
    this.setState({ saving: true });
    const circuits = this.buildUpdatedCircuits();
    const job = { circuits };

    // Persist their selected defaults if they enable "Save as Default"
    if (this.state.saveAsDefault) {
      job.circuitDefaults = {
        metal: this.state.metal,
        insulation: this.state.insulation,
        simpull: this.state.simpullHead,
        same_sizes: this.state.sameSizes,
        conductor_count: this.state.numConductors,
        conductors: this.state.conductors.map(({ size, color }) => ({
          size,
          color
        })),
        groundMetal: this.state.groundMetal
      };

      // save ground on parallel setting and ground size if ground metal is selected
      if (this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE) {
        job.circuitDefaults.groundOnParallel = this.state.groundOnParallel;
        job.circuitDefaults.groundSize = this.state.groundSize;
      }
    }

    this.props.firestore
      .update(`jobs/${this.props.jobId}`, job)
      .then(yay => {
        this.setState({ saving: false });
        this.props.routeOnCancel();
      })
      .catch(error => {
        this.props.showToast(
          "Your circuit can't be saved at this time. Please try again later.",
          'error'
        );
        this.setState({
          saving: false
        });
      });
  };

  buildUpdatedConductors = () => {
    let conductors = this.state.conductors.slice(0);
    if (
      this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE &&
      this.state.groundOnParallel
    ) {
      const groundConductor = this.buildValidGround();
      // add 'isGroundConductor' and 'groundMetal' prop for ground conductor on parallel
      groundConductor.isGroundConductor = true;
      groundConductor.groundMetal = this.state.groundMetal;

      conductors.push(groundConductor);
    }
    return conductors;
  };

  buildUpdatedCircuits = () => {
    let circuits = cloneDeep(this.props.job.circuits);
    const {
      from,
      to,
      metal,
      insulation,
      length,
      simpullHead,
      conductors,
      groundOnParallel,
      groundMetal,
      groundSize
    } = this.state;
    const now = Date.now();
    const updatedConductors = this.buildUpdatedConductors();
    const products = this.getProducts(updatedConductors);
    let updatedCircuit = {
      from: from.trim(),
      to: to.trim(),
      metal: metal,
      insulation: insulation,
      length: parseFloat(length || 0),
      simpull: simpullHead,
      conductors: updatedConductors,
      dateModified: now,
      size: conductors[0].size,
      diameter: conductors[0].diameter,
      skus: this.getSkus(products),
      weight: this.getWeight(products),
      volume: this.getVolume(products)
    };

    // ground circuit should be added if ground on parallel is toggled off
    // and a ground size has been selected
    const shouldCreateGroundCircuit =
      !this.state.groundOnParallel &&
      this.state.groundSize &&
      this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE;

    if (this.props.circuit) {
      let index = this.props.circuitId;
      if (!circuits[index]) {
        return false;
      }

      if (shouldCreateGroundCircuit) {
        const groundConductor = this.buildValidGround();
        const product = this.getGroundProduct(
          groundConductor.color,
          groundMetal
        );
        const groundSku = this.getSkus([product]);
        circuits[now] = {
          ...updatedCircuit,
          dateCreated: now,
          metal: groundMetal,
          size: groundSize,
          diameter: groundConductor.diameter,
          conductors: [groundConductor],
          skus: groundSku,
          weight: this.getWeight([product]),
          volume: this.getVolume([product]),
          isGroundCircuit: true,
          mainCircuitId: index
        };

        // remove ground conductor from main circuit
        const updatedConductors = circuits[index].conductors.filter(
          c => !c.isGroundConductor
        );
        const products = this.getProducts(updatedConductors);

        // update groundCircuitId, conductors, skus, weight, and volume of main circuit
        updatedCircuit = {
          ...updatedCircuit,
          groundCircuitId: now,
          conductors: updatedConductors,
          skus: this.getSkus(products),
          weight: this.getWeight(products),
          volume: this.getVolume(products)
        };
      }
      Object.assign(circuits[index], updatedCircuit);
    } else {
      let quantity = parseInt(this.state.quantity, 10) || 1;
      // array of duplicated circuits with main circuit followed by ground circuit if applicable
      const circuitsArr = [];

      for (let i = 0; i < quantity; i++) {
        circuitsArr.push(updatedCircuit);

        // create a ground circuit if ground on parallel is toggled off and a ground size has been selected
        if (shouldCreateGroundCircuit) {
          const groundConductor = this.buildValidGround();
          const product = this.getGroundProduct(
            groundConductor.color,
            groundMetal
          );
          const groundCircuit = {
            ...updatedCircuit,
            metal: groundMetal,
            size: groundSize,
            diameter: groundConductor.diameter,
            skus: this.getSkus([product]),
            weight: this.getWeight([product]),
            volume: this.getVolume([product]),
            conductors: [groundConductor],
            isGroundCircuit: true
          };

          circuitsArr.push(groundCircuit);
        }
      }
      // updating dateCreated for each circuit
      circuitsArr.forEach((circuit, index) => {
        const dateCreated = now - index;
        circuits[dateCreated] = {
          dateCreated,
          ...circuit
        };
        // if ground is OFF parallel add mainCircuitId and groundCircuit association to circuits
        if (!groundOnParallel && groundMetal !== DEFAULT_GROUND_METAL_NONE) {
          if (circuit.isGroundCircuit) {
            circuits[dateCreated] = {
              mainCircuitId: dateCreated + 1,
              ...circuits[dateCreated]
            };
          } else {
            circuits[dateCreated] = {
              groundCircuitId: dateCreated - 1,
              ...circuits[dateCreated]
            };
          }
        }
      });
    }
    return circuits;
  };

  render() {
    let className = 'build-circuit';
    if (this.props.circuit) {
      className += ' edit-circuit';
    }
    // display ground fields if editing a circuit that is not a ground circuit, a circuit with a ground off parallel, or creating a new circuit
    const shouldDisplayGroundFields =
      (this.props.circuit &&
        !this.props.circuit.isGroundCircuit &&
        !this.props.circuit.groundCircuitId) ||
      !this.props.circuit;

    const shouldDisplayMainWarning =
      this.props.circuit &&
      this.props.circuit.groundCircuitId &&
      !this.state.saving;

    const shouldDisableGroundSize =
      this.state.groundMetal === DEFAULT_GROUND_METAL_NONE ||
      (this.state.sameSizesMandatory && this.state.groundOnParallel);

    return (
      <form className={className} onSubmit={this.submitCircuit}>
        {!this.props.circuit && (
          <Input
            type="text"
            label="Quantity"
            onChange={this.updateQuantity()}
            value={this.state.quantity}
            maxLength="2"
          />
        )}

        <Input
          type="text"
          label="From"
          onChange={this.updateField('from')}
          value={this.state.from}
          maxLength="15"
        />

        <Input
          type="text"
          label="To"
          onChange={this.updateField('to')}
          value={this.state.to}
          maxLength="15"
        />

        <div className="pulling-heads-toggle">
          <Toggle
            name={<SimPullHead />}
            checked={this.state.simpullHead}
            onChange={this.updateCheckbox('simpullHead')}
          />
        </div>

        <Dropdown
          required
          id="metal-insulation"
          className="metal-insulation"
          label="Metal / Insulation"
          placeholder="Select Type"
          value={`${this.state.metal}|${this.state.insulation}`}
          onSelect={this.updateMetal}
          options={this.getMetalOptions()}
        />

        <Input
          type="text"
          label="Length"
          placeholder={this.props.job.metric ? 'Meters' : 'Feet'}
          onChange={this.updateLength()}
          value={this.state.length}
          required
          maxLength="6"
        />

        <ConductorCountDropdown
          value={this.state.numConductors}
          country={this.props.job.country}
          sameSizes={this.state.sameSizes}
          primaryConductor={this.state.conductors[0]}
          onSelect={this.updateConductorCount}
          colors={this.props.catalog.colorsMap}
          availableColors={this.state.availableColorsMap}
        />

        {this.state.numConductors &&
          this.state.metal &&
          this.state.insulation && (
            <ConductorBuilder
              applyToAll={this.state.sameSizes}
              applyToAllDisabled={this.state.sameSizesMandatory}
              conductors={this.state.conductors}
              selectedIndex={this.state.selectedConductor}
              sizes={this.props.catalog.sizes.items}
              availableSizes={this.getFilteredSizes()}
              colors={this.props.catalog.colorsMap}
              colorsBySize={this.state.availableColorsMap}
              onChangeSize={this.onChangeSize}
              onChangeColor={this.onChangeColor}
              onChangeApplyToAll={this.updateSameSizes}
              onChangeSelected={this.onChangeSelectedConductor}
            />
          )}

        {shouldDisplayGroundFields && (
          <div className="ground-fields">
            <Dropdown
              required
              id="ground-metal"
              label="Ground Metal"
              className="ground-metal"
              placeholder="Please Select"
              value={this.state.groundMetal}
              onSelect={this.updateGroundField('groundMetal')}
              options={this.getGroundMetalOptions()}
            />

            <Dropdown
              required={this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE}
              id="ground-size"
              label="Ground Size"
              className="ground-size"
              disabled={shouldDisableGroundSize}
              placeholder="Please Select"
              value={this.state.groundSize}
              onSelect={this.updateGroundField('groundSize')}
              options={this.getGroundSizeOptions()}
            />

            <Toggle
              name="Ground on Parallel"
              checked={this.state.groundOnParallel}
              onChange={this.updateCheckbox('groundOnParallel')}
            />
          </div>
        )}

        {this.shouldDisplayGroundError() && (
          <div className="ground-error-message field-alert alerted">
            Ground could not be created.
          </div>
        )}
        {shouldDisplayMainWarning && (
          <div className="ground-error-message field-warning">
            The Ground for this Circuit was created Off Parallel and must be
            edited individually.
          </div>
        )}
        {this.groundSizeExceedsWarningDiff() && this.state.groundOnParallel && (
          <div className="ground-error-message field-warning">
            We don't recommend sizes that vary this greatly.
          </div>
        )}

        <div className="action-buttons">
          <div>
            <Button
              className="secondary"
              onClick={() => this.props.routeOnCancel()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!this.isFormValid() || this.state.saving}
              saving={this.state.saving}
            >
              Save
            </Button>
          </div>
          <Checkbox
            id="save-defaults"
            label="Save as Default"
            checked={this.state.saveAsDefault}
            onChange={this.updateCheckbox('saveAsDefault')}
          />
        </div>
      </form>
    );
  }

  isFormValid() {
    // if a ground metal has been selected ground metal and ground size are both required, else only ground metal ('none') is required
    const groundFieldsAreValid =
      this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE
        ? this.state.groundMetal && this.state.groundSize
        : this.state.groundMetal;
    return (
      this.state.metal.trim().length &&
      this.state.insulation.trim().length &&
      this.state.length.trim().length &&
      !isNaN(parseFloat(this.state.length.trim())) &&
      this.state.conductors.length &&
      this.state.conductors.length === this.state.numConductors &&
      this.isEachConductorValid() &&
      groundFieldsAreValid
    );
  }

  isEachConductorValid() {
    const { conductors } = this.state;

    // every conductor must have a color, size,
    // and diameter to be considered valid.
    return conductors.every(conductor => {
      return (
        conductor.color &&
        conductor.color.length &&
        conductor.size &&
        conductor.size.length &&
        conductor.diameter
      );
    });
  }

  /**
   * Get the ground metal options for this circuit.
   *
   * If metal = CU, ground can be CU
   * If metal = AL, ground can be CU or AL
   *
   * @return {Array}
   */
  getGroundMetalOptions() {
    const { metal } = this.state;

    if (metal === 'CU') {
      return [
        { value: 'none', label: 'None' },
        { value: 'CU', label: 'Copper' }
      ];
    }

    return [
      { value: 'none', label: 'None' },
      { value: 'CU', label: 'Copper' },
      { value: 'AL', label: 'Aluminum' }
    ];
  }

  getGroundSizeOptions() {
    const availableSizes = this.getAvailableGroundSizes();
    return availableSizes.map(size => ({
      value: size.id,
      label: size.name
    }));
  }

  getMetalOptions() {
    const { catalog } = this.props;
    const metals = catalog && catalog.metals.items;
    const insulations = catalog && catalog.insulations.items;

    if (!metals || !insulations) {
      return [];
    }

    const all = metals.reduce((combos, metal) => {
      return combos.concat(
        insulations.reduce(
          (combosInsulation, insulation) =>
            combosInsulation.concat({ metal, insulation }),
          []
        )
      );
    }, []);

    return all.map(({ metal, insulation }) => ({
      value: `${metal.id}|${insulation.id}`,
      label: `${metal.id} / ${insulation.id}`
    }));
  }

  updateMetal = value => {
    const [metal, insulation] = value.split('|');
    const conductors = Array(this.state.conductors.length).fill({});
    const { availableSizes, availableColorsMap } = this.getAvailableOptions(
      metal,
      insulation
    );
    const updatedState = {
      metal,
      insulation,
      conductors,
      availableSizes,
      availableColorsMap
    };
    // clear ground metal if there is a ground on parallel with a different metal than updated main metal
    if (
      this.state.groundMetal !== DEFAULT_GROUND_METAL_NONE &&
      this.state.groundMetal !== metal &&
      this.state.groundOnParallel
    ) {
      updatedState.groundMetal = DEFAULT_GROUND_METAL_NONE;
    }

    this.setState(updatedState);
  };

  buildValidGround = () => {
    const { groundMetal, groundSize } = this.state;
    let groundConductor = { size: groundSize };
    const sizes = this.props.catalog.sizes.items;
    const selectedSize = sizes.filter(s => s.id === groundSize)[0];
    const diameter = selectedSize && selectedSize.diameter;
    groundConductor.diameter = diameter;
    const defaultGroundColor = 'Green';
    groundConductor.color = defaultGroundColor;
    if (!this.getGroundProduct(defaultGroundColor, groundMetal)) {
      const secondaryGroundColor = 'Black';
      groundConductor.color = secondaryGroundColor;
      if (!this.getGroundProduct(secondaryGroundColor, groundMetal)) {
        groundConductor = null;
      }
    }

    return groundConductor;
  };

  updateGroundField = groundField => {
    const {
      groundMetal,
      groundSize,
      sameSizesMandatory,
      groundOnParallel,
      conductors
    } = this.state;
    return value => {
      const updatedState = { [groundField]: value };
      // if ground metal has been updated and ground size has been selected check if size is available
      if (
        groundField === 'groundMetal' &&
        value !== groundMetal &&
        groundSize
      ) {
        const newGroundSizes = this.getAvailableGroundSizes(undefined, value);
        const groundSizeIsAvailable = newGroundSizes.some(
          s => s.id === groundSize
        );
        if (!groundSizeIsAvailable) {
          updatedState.groundSize = '';
        }
      }
      // if ground metal on parallel is being selected and sameSizesMandatory is true, update ground size
      if (
        groundField === 'groundMetal' &&
        sameSizesMandatory &&
        groundOnParallel &&
        value !== DEFAULT_GROUND_METAL_NONE
      ) {
        updatedState.groundSize = conductors[0].size;
      }
      this.setState(updatedState);
    };
  };

  /**
   * ground error occurs once all required ground fields are
   * selected and it does not map to a valid product
   */
  shouldDisplayGroundError = () => {
    const { groundMetal, groundSize } = this.state;
    // ground is 'none' by default so set 'isGroundValid' to true
    let isGroundValid = true;
    // validate that the ground fields map to a product once ground metal and ground size have been selected
    if (groundMetal !== DEFAULT_GROUND_METAL_NONE && groundSize) {
      isGroundValid = !!this.buildValidGround();
    }
    return !isGroundValid;
  };

  groundSizeExceedsWarningDiff = () => {
    const groundSize = this.state.groundSize;
    const primarySize = this.state.conductors[0].size;
    const availableSizes = this.getAvailableGroundSizes();
    const primaryIndex =
      primarySize && availableSizes.findIndex(s => s.id === primarySize);
    const currentIndex = availableSizes.findIndex(s => s.id === groundSize);
    if (!primarySize || primaryIndex === -1 || currentIndex === -1) {
      return;
    }
    return Math.abs(currentIndex - primaryIndex) > MAX_SIZE_WARNING_DIFF;
  };

  updateQuantity = () => {
    return event => {
      let value = event.target.value;
      if (rex.test(value) && value <= MAX_QUANTITY) {
        this.setState({ quantity: value });
      }
    };
  };

  updateLength = () => {
    return event => {
      let value = event.target.value;
      if (rex.test(value)) {
        let newState = {
          length: value,
          sameSizesMandatory: false
        };

        if (isSameSizesMandatory(value, this.props.job.metric)) {
          newState.sameSizes = true;
          newState.sameSizesMandatory = true;

          // set ground size to primary size if ground on parallel
          if (this.state.groundOnParallel) {
            const primarySize = this.state.conductors[0].size;
            newState.groundSize = primarySize;
          }
        }

        this.setState(newState, () => {
          if (this.state.sameSizes) {
            this.updateSizes();
          }
        });
      }
    };
  };

  updateField = name => {
    return event => {
      this.setState({ [name]: event.target.value });
    };
  };

  updateCheckbox = name => {
    const {
      metal,
      groundMetal,
      groundSize,
      sameSizesMandatory,
      conductors
    } = this.state;
    return event => {
      const isChecked = event.target.checked;
      const updatedState = { [name]: isChecked };
      if (name === 'groundOnParallel') {
        const newGroundSizes = this.getAvailableGroundSizes(isChecked);
        const groundSizeIsAvailable = newGroundSizes.some(
          s => s.id === groundSize
        );
        // if toggling on and ground metal is different than main metal, clear out ground metal
        if (isChecked && metal !== groundMetal) {
          updatedState.groundMetal = DEFAULT_GROUND_METAL_NONE;
        }
        if (isChecked && groundSize && !groundSizeIsAvailable) {
          updatedState.groundSize = '';
        }
        if (
          isChecked &&
          groundMetal !== DEFAULT_GROUND_METAL_NONE &&
          sameSizesMandatory
        ) {
          updatedState.groundSize = conductors[0].size;
        }
      }
      this.setState(updatedState);
    };
  };

  updateSameSizes = event => {
    const sameSizes = event.target.checked;

    this.setState({ sameSizes }, () => {
      if (sameSizes) {
        this.updateSizes();
      }
    });
  };

  /**
   * Get a list of available sizes within the maximum size difference of MAX_SIZE_DIFF off the primary conductor
   *
   * @return {Array}
   */
  getRestrictedSizes(availableSizes, primarySize, sizes) {
    const primaryIndex = availableSizes.indexOf(primarySize);
    const minimumIndex = Math.max(0, primaryIndex - MAX_SIZE_DIFF);
    const maximumIndex = primaryIndex + (MAX_SIZE_DIFF + 1);
    const filteredSizes = availableSizes.slice(minimumIndex, maximumIndex);
    return sizes.filter(s => filteredSizes.indexOf(s.id) !== -1);
  }

  /**
   * Get a list of available sizes to display in the size picker component.
   *
   * @return {Array}
   */
  getFilteredSizes() {
    const { conductors, availableSizes } = this.state;
    const sizes = this.props.catalog.sizes.items;
    const applyToAll = this.state.sameSizes;
    const selectedIndex = this.state.selectedConductor;
    const primarySize = conductors[0].size;
    // either we're editing all sizes together or we're looking at the primary conductor
    if (applyToAll || selectedIndex === 0 || !primarySize) {
      return sizes.filter(s => availableSizes.indexOf(s.id) !== -1);
    }
    return this.getRestrictedSizes(availableSizes, primarySize, sizes);
  }

  /**
   * Get a list of available sizes specifically for the ground conductor
   *
   * @return {Array}
   */
  getAvailableGroundSizes(
    groundOnParallel = this.state.groundOnParallel,
    groundMetal = this.state.groundMetal,
    primarySize = this.state.conductors[0].size
  ) {
    const insulation = this.state.insulation;
    const availableSizes = this.getAvailableOptions(groundMetal, insulation)
      .availableSizes;
    const sizes = this.props.catalog.sizes.items;
    // if ground is off parallel or there is no primary size, return all available sizes
    if (!groundOnParallel || !primarySize) {
      return sizes.filter(s => availableSizes.indexOf(s.id) !== -1);
    }
    // else display restricted subset of sizes dependent on the primary size
    return this.getRestrictedSizes(availableSizes, primarySize, sizes);
  }

  /**
   * Event handler for updating a conductor's size.
   *
   * @param {String} size     - the conductor's new size
   * @param {String} diameter - the conductor's new diameter
   * @param {Number} index    - the index position of the conductor we are modifying
   */
  onChangeSize = (size, diameter, index) => {
    const {
      sameSizes,
      groundMetal,
      groundSize,
      sameSizesMandatory,
      groundOnParallel
    } = this.state;
    let conductors = this.getUpdatedConductors(size, diameter, index);
    const initialCount = conductors.filter(c => Object.keys(c).length).length;
    const updatedState = { conductors };

    // if the primary size is being changed, validate that the ground size is still valid
    if (index === 0 && groundSize) {
      const newGroundSizes = this.getAvailableGroundSizes(
        undefined,
        undefined,
        size
      );
      const groundSizeIsAvailable = newGroundSizes.some(
        s => s.id === groundSize
      );
      if (!groundSizeIsAvailable) {
        updatedState.groundSize = '';
      }
    }

    // When we're editing the first conductor and sameSizes is disabled,
    // we need to make sure the conductors are still valid given size constraints.
    if (!sameSizes && index === 0) {
      updatedState.conductors = this.checkSizeRanges(conductors);
      const afterCount = conductors.filter(c => Object.keys(c).length).length;

      // After we checked for invalid size ranges, the conductor count is less.
      // That means we had to remove something. Show a message to the user.
      if (afterCount < initialCount) {
        this.props.showToast(
          'Conductors were reset because the size varies too greatly',
          'error'
        );
      }
    }

    // if there is a ground on parallel with a ground metal selected and same sizes is mandatory
    // update ground size to match the new primary size
    if (
      sameSizesMandatory &&
      groundMetal !== DEFAULT_GROUND_METAL_NONE &&
      groundOnParallel
    ) {
      updatedState.groundSize = size;
    }

    this.setState(updatedState);
  };

  /**
   * Event handler for changing a conductor's color.
   *
   * @param {Object} color
   * @param {Number} index
   */
  onChangeColor = (color, index) => {
    const conductors = this.state.conductors.map((conductor, i) => {
      if (index === i) {
        return {
          ...conductor,
          color: color.name
        };
      }
      return conductor;
    });

    this.setState({ conductors });
  };

  onChangeSelectedConductor = selectedConductor => {
    this.setState({ selectedConductor });
  };

  /**
   * Return the updated conductors array, updating size/diameter
   * for either:
   *
   *  A.) all conductors, if this.state.sameSizes is enabled
   *  B.) a single conductor, given the conductor's array index
   *
   * @param  {String}      size
   * @param  {String}      diameter
   * @param  {Number|null} index
   * @return {Array}
   */
  getUpdatedConductors(size, diameter, index = null) {
    const { sameSizes, availableColorsMap } = this.state;

    const newAvailableColors = availableColorsMap[size];

    return this.state.conductors.map((conductor, i) => {
      // Update all conductors if "Apply to All" checkbox is checked.
      // Or just update the conductor based on the given index.
      if (sameSizes || index === i) {
        // The size we just picked does not include the previously selected conductor color.
        // Update this conductor's color to black.
        let color = conductor.color;
        if (
          !newAvailableColors ||
          newAvailableColors.indexOf(conductor.color) === -1
        ) {
          color = 'Black';
        }

        return {
          ...conductor,
          diameter,
          color,
          size
        };
      }

      return conductor;
    });
  }

  /**
   * When sameSizes is enabled, this will make sure
   * all conductor's have the same size as the primary conductor.
   */
  updateSizes() {
    const primaryConductor = this.state.conductors[0];
    if (!Object.keys(primaryConductor).length) {
      return;
    }

    const conductors = this.getUpdatedConductors(
      primaryConductor.size,
      primaryConductor.diameter
    );

    this.setState({ conductors });
  }

  checkSizeRanges(conductors) {
    if (!conductors.length) {
      return conductors;
    }

    const { availableSizes } = this.state;
    const primaryIndex = availableSizes.indexOf(conductors[0].size);

    return conductors.map((conductor, index) => {
      // Don't mess with the primary conductor.
      // Also don't mess with the conductor if there's no size.
      if (index === 0 || !conductor.size) {
        return conductor;
      }

      const currentIndex = availableSizes.indexOf(conductor.size);

      // If the current conductor is more than MAX_SIZE_DIFF away
      // from the primary conductor, it's no longer valid and we need to
      // remove it from the current selection.
      if (
        currentIndex < 0 ||
        primaryIndex < 0 ||
        Math.abs(currentIndex - primaryIndex) > MAX_SIZE_DIFF
      ) {
        return {};
      }

      return conductor;
    });
  }

  /**
   * Update handler for the conductor count dropdown.
   *
   * If they choose a color preset, update conductors based on that.
   * If not, build the new conductors array using as many values from the previous
   * array as possible. Defaulting to color=Black for new ones.
   *
   * @param {Number}          numConductors
   * @param {Array|undefined} colors
   */
  updateConductorCount = (numConductors, colors) => {
    const primaryConductor = this.state.conductors[0];

    let conductors;
    if (colors && colors.length) {
      conductors = colors.map(color => {
        return this.getConductor(primaryConductor.size, color);
      });
    } else {
      conductors = Array(numConductors)
        .fill(this.getConductor(primaryConductor.size, 'Black'))
        .map((conductor, index) => {
          if (this.state.conductors[index]) {
            return { ...this.state.conductors[index] };
          }

          return conductor;
        });
    }

    this.setState({
      numConductors,
      conductors,
      selectedConductor: 0
    });
  };

  /**
   * Figure out the default state for the circuit form.
   * If the user has defined job-specific circuit defaults, we'll want to
   * pull from those first. If not, use the recommended defaults.
   *
   * If no valid product, default to an empty state.
   *
   * @return {Object}
   */
  getDefaultFormState() {
    const defaultCircuit = this.getDefaultCircuit();
    const validProducts = defaultCircuit.conductors.every(conductor => {
      return !!productFinder.getProduct(
        this.props.catalog,
        defaultCircuit.metal,
        defaultCircuit.insulation,
        conductor.size,
        conductor.color
      );
    });

    // No products correspond with the given defaults,
    // fallback the form to an empty state.
    if (!validProducts) {
      return {
        availableSizes: [],
        availableColorsMap: {},
        from: '',
        to: '',
        length: '',
        metal: '',
        insulation: '',
        simpullHead: true,
        numConductors: DEFAULT_NUM_CONDUCTORS,
        conductors: Array(DEFAULT_NUM_CONDUCTORS).fill({}),
        groundMetal: DEFAULT_GROUND_METAL_NONE,
        groundSize: '',
        groundOnParallel: true
      };
    }

    const { availableSizes, availableColorsMap } = this.getAvailableOptions(
      defaultCircuit.metal,
      defaultCircuit.insulation
    );

    return {
      availableSizes,
      availableColorsMap,
      from: '',
      to: '',
      length: '',
      ...defaultCircuit
    };
  }

  /**
   * If this job has user-saved defaults, return those first.
   * If not, return the recommended values.
   *
   * @return {Object}
   */
  getDefaultCircuit() {
    const { job } = this.props;
    if (job.circuitDefaults && !this.props.circuit) {
      const defaults = {
        metal: job.circuitDefaults.metal,
        insulation: job.circuitDefaults.insulation,
        simpullHead: job.circuitDefaults.simpull,
        sameSizes: job.circuitDefaults.same_sizes,
        numConductors: job.circuitDefaults.conductor_count,
        groundMetal: job.circuitDefaults.groundMetal,
        groundSize: '',
        groundOnParallel: true,
        conductors: job.circuitDefaults.conductors.map(({ size, color }) => {
          return this.getConductor(size, color);
        })
      };
      if (job.circuitDefaults.groundMetal !== DEFAULT_GROUND_METAL_NONE) {
        defaults.groundSize = job.circuitDefaults.groundSize;
        defaults.groundOnParallel = job.circuitDefaults.groundOnParallel;
      }
      return defaults;
    }

    return {
      metal: DEFAULT_METAL,
      insulation:
        job.country === 'us' ? DEFAULT_INSULATION_US : DEFAULT_INSULATION_CA,
      numConductors: DEFAULT_NUM_CONDUCTORS,
      simpullHead: DEFAULT_SIMPULL,
      groundMetal: DEFAULT_GROUND_METAL_NONE,
      groundSize: '',
      groundOnParallel: true,
      conductors: Array(DEFAULT_NUM_CONDUCTORS).fill(
        this.getConductor(DEFAULT_CONDUCTOR_SIZE, DEFAULT_CONDUCTOR_COLOR)
      )
    };
  }

  /**
   * Build a conductor object for the given size and color
   * Get the conductor's diameter from catalog.sizes (this may change in the future).
   *
   * @param  {String} size
   * @param  {String} color
   * @return {Object}
   */
  getConductor(size, color) {
    const { sizes } = this.props.catalog;
    const selectedSize = sizes.items.filter(s => s.id === size)[0];

    // if we can't find the selected size from the catalog,
    // fallback to an empty conductor.
    if (!selectedSize) {
      return {};
    }

    return {
      color,
      size,
      diameter: selectedSize.diameter
    };
  }

  getAvailableOptions(metal, insulation) {
    const sizes = this.getAvailableSizes(metal, insulation);

    const filteredSizes = sizes.filter((size, index, self) => {
      return self.indexOf(size) === index;
    });

    const colors = this.getAvailableColorsMap(metal, insulation, filteredSizes);

    return {
      availableSizes: filteredSizes,
      availableColorsMap: colors
    };
  }

  getAvailableSizes(metal, insulation) {
    return this.props.catalog.products.items
      .filter(product => {
        return product.metal === metal && product.product_type === insulation;
      })
      .map(product => {
        return {
          size: product.size,
          diameter: product.diameter
        };
      })
      .sort((a, b) => a.diameter - b.diameter)
      .map(product => product.size);
  }

  getAvailableColorsMap(metal, insulation, sizes) {
    const catalog = this.props.catalog;
    const map = {};

    sizes.forEach(size => {
      const colors = catalog.products.items
        .filter(product => {
          return (
            product.metal === metal &&
            product.product_type === insulation &&
            product.size === size
          );
        })
        .map(product => {
          return product.color;
        });
      map[size] = colors;
    });

    return map;
  }

  getProducts(conductors) {
    return conductors.map(conductor => {
      const metal = conductor.isGroundConductor
        ? conductor.groundMetal
        : this.state.metal;

      return productFinder.getProduct(
        this.props.catalog,
        metal,
        this.state.insulation,
        conductor.size,
        conductor.color
      );
    });
  }

  getGroundProduct(groundColor, groundMetal) {
    return productFinder.getProduct(
      this.props.catalog,
      groundMetal,
      this.state.insulation,
      this.state.groundSize,
      groundColor
    );
  }

  getSkus(products) {
    return products.map(product => {
      return product.stock_number;
    });
  }

  getWeight(products) {
    const length = parseFloat(this.state.length);
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return products.reduce((accumulator, product) => {
      return (
        accumulator + productFinder.getConductorWeight(product, lengthInFt)
      );
    }, 0);
  }

  getVolume(products) {
    const length = parseFloat(this.state.length);
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;

    return products.reduce((accumulator, product) => {
      return (
        accumulator + productFinder.getConductorVolume(product, lengthInFt)
      );
    }, 0);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, type) => {
      dispatch(showToast(message, type));
    }
  };
};

export default compose(
  firestoreConnect(),
  connect(
    null,
    mapDispatchToProps
  )
)(CircuitBuilder);
