import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import convertUnits from '../../utils/convertUnits';
import productFinder from '../../utils/productFinder';
import { showToast } from '../../actions/ToastActions';

export class DownloadBOM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false
    };
  }
  render() {
    const job = this.props.job;
    const project = this.props.project;
    const processingClass = this.state.processing ? 'processing' : '';
    return (
      <span
        className={`download-bom ${processingClass}`}
        onClick={() => {
          this.downloadBOM(job, project);
        }}
      >
        Download BOM
      </span>
    );
  }

  formatPhoneNumber(number) {
    const numberArr = number.split('');
    const phoneNumber1 = [
      '+1 (',
      numberArr[1],
      numberArr[2],
      numberArr[3],
      ') ',
      numberArr[4],
      numberArr[5],
      numberArr[6],
      '-',
      numberArr[7],
      numberArr[8],
      numberArr[9],
      numberArr[10]
    ];
    const phoneNumber = [
      '(',
      numberArr[0],
      numberArr[1],
      numberArr[2],
      ') ',
      numberArr[3],
      numberArr[4],
      numberArr[5],
      '-',
      numberArr[6],
      numberArr[7],
      numberArr[8],
      numberArr[9]
    ];

    return numberArr[0] === 1 && numberArr.length() === 11
      ? phoneNumber1
      : phoneNumber;
  }

  generatePDF(job, project, url) {
    const profile = this.props.profile;
    const number = profile.phoneNumber;
    const date = new Date();
    const currentYear = date.getFullYear();
    const jobType = job.jobType || '600V';

    var docDefinition = {
      header: function(currentPage, pageCount) {
        return [
          {
            columns: [
              { image: url, width: 300 },
              {
                text: 'configuratorplus.southwire.com',
                link: 'https://configuratorplus.southwire.com/',
                color: '#90979e',
                margin: [15, 15, 0, 0]
              },
              { text: currentPage, margin: [30, 15, 0, 0] }
            ],
            margin: [40, 17, 40, 0]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 0.5,
                lineColor: '#929396'
              }
            ],
            margin: [40, 0, 40, 15]
          }
        ];
      },
      footer: function(currentPage, pageCount) {
        const footer = [
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 0.5,
                lineColor: '#929396'
              }
            ],
            margin: [40, 12]
          },
          {
            text: [
              '©2013 - ',
              currentYear,
              ' Southwire Company, LLC. All rights reserved.'
            ],
            style: ['centerAlign', 'grayText'],
            margin: [0, 0, 0, 6]
          },
          {
            text: [
              { text: 'SIM', style: 'grayText' },
              { text: 'pull', style: 'grayText', italics: true },
              { text: '™ Calculator: ', style: 'grayText' },
              {
                text: 'www.southwire.com/commercial/calculators.htm',
                link: 'https://www.southwire.com/commercial/calculators.htm',
                decoration: 'underline',
                color: '#376bad'
              }
            ],
            style: 'centerAlign',
            margin: [0, 0, 0, 6]
          },
          {
            text: [
              { text: 'Southwire Contractor Solutions: ', style: 'grayText' },
              {
                text: 'www.contractorsolutions.southwire.com/',
                link: 'http://contractorsolutions.southwire.com/',
                decoration: 'underline',
                color: '#376bad'
              }
            ],
            style: 'centerAlign',
            margin: [0, 0, 0, 6]
          }
        ];
        return currentPage === pageCount ? footer : null;
      },
      content: [
        {
          columns: [
            [
              {
                text: [
                  { text: 'Project: ', style: 'grayText' },
                  { text: project.name, bold: true }
                ]
              },
              {
                text: [
                  { text: 'Job: ', style: 'grayText' },
                  { text: job.name, bold: true }
                ]
              },
              {
                text: [
                  { text: 'Job Type: ', style: 'grayText' },
                  { text: jobType, bold: true }
                ]
              }
            ],
            [
              {
                text: [profile.name, ' - ', profile.role],
                bold: true,
                style: 'rightAlign'
              },
              {
                text: this.props.auth.email,
                style: ['rightAlign', 'grayText']
              },
              number
                ? {
                    text: this.formatPhoneNumber(number),
                    style: ['rightAlign', 'grayText']
                  }
                : null
            ]
          ]
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 10,
              x2: 515,
              y2: 10,
              lineWidth: 0.5,
              lineColor: '#929396'
            }
          ],
          margin: [0, 7.5, 0, 14.5]
        },
        // if there are reels, display reel summary and project header for conductor summaries, else just display conductor summaries
        job.reels.length ? this.displayReelsSummary(job) : null,
        this.displayConductorsSummary(job),
        this.displayConductorsTotalSummary(job),
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 10,
              x2: 515,
              y2: 10,
              lineWidth: 0.5,
              lineColor: '#929396'
            }
          ],
          margin: [0, 7.5, 0, 14.5]
        },
        this.displayMetalsTotals(job),
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 10,
              x2: 515,
              y2: 10,
              lineWidth: 0.5,
              lineColor: '#929396'
            }
          ],
          margin: [0, 7.5, 0, 14.5]
        },
        {
          text: [
            'This Bill of Material was generated utilizing Southwire’s patented Configurator Plus™ tool and is intended for quotations that solely utilize Southwire products.',
            ' Utilizing this Bill of Material for the quote(s) of another manufacturer other than Southwire may produce incorrect results due to calculations based exclusively on Southwire specifications and data.',
            ' Neither Southwire nor any of its officers, directors, shareholders, employees, or agents shall be liable for any direct, indirect, consequential or incidental damages arising out of improper use of, or the inability to use, the Configurator Plus™ tool or the use of this Bill of Material for quotes from any other manufacturer.'
          ],
          fontSize: 8,
          style: ['grayText'],
          margin: [40, 3],
          valign: 'bottom'
        }
      ],
      pageMargins: [40, 70, 40, 90],
      styles: {
        rightAlign: {
          alignment: 'right'
        },
        centerAlign: {
          alignment: 'center'
        },
        grayText: {
          color: '#68727d'
        },
        nameText: {
          fontSize: 14
        }
      },
      defaultStyle: {
        fontSize: 10
      }
    };

    import('./createPdf')
      .then(({ default: createPdf }) => createPdf(docDefinition))
      .then(doc => {
        doc.download(`${job.name}_BOM.pdf`);
        this.setState({ processing: false });
      })
      .catch(err => {
        console.error('unable to generate PDF', err);
        this.setState({ processing: false });
      });
  }

  downloadBOM(job, project) {
    this.setState({ processing: true });
    // set this for binding
    const self = this;

    let url;
    fetch('/assets/img/pdfLogo.png')
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then(blob => {
        const reader = new FileReader();
        reader.onload = function(event) {
          // get url from converted logo image and then generate pdf
          url = event.target.result;
          self.generatePDF(job, project, url);
        };
        const file = blob;
        reader.readAsDataURL(file);
      })
      .catch(error => {
        this.props.showToast('Please check your network connection.', 'error');
        this.setState({ processing: false });
      });
  }

  displayReelsSummary(job) {
    let reels = job.reels;
    let reelSummary = [];

    for (let i = 0; i < reels.length; i++) {
      let reel = reels[i];
      const reelWidthDisplay = reel.reelProduct.width
        ? `, ${reel.reelProduct.width}in W, `
        : ', ';
      // create a section for each reel
      reelSummary.push([
        {
          columns: [
            // reel summary header
            [
              { text: 'Reel Name: ', style: 'grayText' },
              { text: reel.name, style: 'nameText' }
            ],
            [
              { text: ' ' },
              {
                text: [
                  reel.reelProduct.name,
                  ', ',
                  reel.reelProduct.diameter,
                  'in H',
                  reelWidthDisplay,
                  Math.round(
                    (reel.reelProduct.reel_weight || 0) +
                      (reel.circuitWeight || 0)
                  ),
                  ' LBS'
                ],
                bold: true,
                style: 'rightAlign'
              }
            ]
          ]
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 10,
              x2: 515,
              y2: 10,
              lineWidth: 0.5,
              lineColor: '#929396'
            }
          ],
          margin: [0, 7.5, 0, 14.5]
        },
        // if there are circuits/pulls generate tables, else just display the reel header
        reel.circuits ? this.displayPulls(reel.circuits, job) : null
      ]);
    }
    reelSummary.push({
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 10,
          x2: 515,
          y2: 10,
          lineWidth: 0.5,
          lineColor: '#929396'
        }
      ],
      margin: [0, 7.5, 0, 14.5],
      pageBreak: 'after'
    });
    return reelSummary;
  }

  normalizePull(pull) {
    let normalized = { ...pull };
    if (!pull.conductors && pull.colors && pull.colors.length) {
      normalized.conductors = [];
      let size = pull.size;
      let diameter = pull.diameter;
      pull.colors.forEach(color => {
        normalized.conductors.push({
          color,
          size,
          diameter
        });
      });
    }

    // If no "From" or "To" is defined for this pull, default them to "X"
    if (!normalized.from) {
      normalized.from = 'X';
    }

    if (!normalized.to) {
      normalized.to = 'X';
    }

    // Does this pull have a ground conductor?
    normalized.hasGround = false;
    const groundConductor = normalized.conductors.find(
      c => c.isGroundConductor
    );

    if (groundConductor) {
      normalized.hasGround = true;
      // if groundMetal doesn't exist (from SWC2-738 bug) use pull metal
      normalized.groundMetal = groundConductor.groundMetal || pull.metal;
    }

    return normalized;
  }

  displayPulls(pulls, job) {
    let circuits = job.circuits;
    let pullsArr = [];

    for (let i = 0; i < pulls.length; i++) {
      let pull = circuits[pulls[i].id];
      let length = job.metric
        ? convertUnits.fromMToFt(pull.length)
        : pull.length;
      let pullCount = pulls.length - i;
      pull = this.normalizePull(pull);
      pullsArr.unshift([
        {
          text: ['PULL ', pullCount, ' OF ', pulls.length, ': ', length, ' FT'],
          bold: true,
          style: 'grayText',
          margin: [0, 0, 0, 10]
        },
        {
          columns: [
            {
              table: {
                widths: [100, 100],
                body: [
                  [
                    {
                      text: 'From/To',
                      fillColor: '#e6e7e8',
                      border: [true, true, false, true]
                    },
                    {
                      text: [pull.from, ' / ', pull.to],
                      border: [false, true, true, true]
                    }
                  ],
                  [
                    {
                      text: 'Metal/Insulation',
                      fillColor: '#e6e7e8',
                      border: [true, true, false, true]
                    },
                    {
                      text: [pull.metal, ' ', pull.insulation],
                      border: [false, true, true, true]
                    }
                  ],
                  ...(pull.hasGround
                    ? [
                        [
                          {
                            text: 'Ground',
                            fillColor: '#e6e7e8',
                            border: [true, true, false, true]
                          },
                          {
                            text: [pull.groundMetal, ' ', pull.insulation],
                            border: [false, true, true, true]
                          }
                        ]
                      ]
                    : []),
                  [
                    {
                      text: ['SIM', { text: 'pull', italics: true }, ' HEAD®'],
                      fillColor: '#e6e7e8',
                      border: [true, true, false, true]
                    },
                    {
                      text: pull.simpull ? 'Yes' : 'No',
                      border: [false, true, true, true]
                    }
                  ]
                ]
              },
              margin: [0, 0, 0, 14],
              layout: {
                hLineColor: function(i, node) {
                  return '#929396';
                },
                vLineColor: function(i, node) {
                  return '#929396';
                }
              }
            },
            {
              width: 290,
              table: {
                widths: '*',
                body: this.displayPullColors(pull.conductors)
              },
              layout: {
                hLineColor: function(i, node) {
                  return '#929396';
                },
                vLineColor: function(i, node) {
                  return '#929396';
                }
              }
            }
          ]
        }
      ]);
    }

    return pullsArr;
  }

  displayPullColors(conductors) {
    let firstRow = [];
    let secondRow = [];
    let colorTable = [
      [{ colSpan: 3, text: 'Colors / Sizes', fillColor: '#e6e7e8' }, ' ', ' '],
      firstRow,
      secondRow
    ];

    for (let i = 0, numConductors = 6; i < numConductors; i++) {
      let conductor = conductors[i];
      let row = i < numConductors / 2 ? firstRow : secondRow;

      if (!conductor) {
        row.push([' ']);
      } else {
        let color = conductor.color;
        let size = conductor.size;
        // pdfmake does not accept rgb values
        let colorCode = color ? this.generateHex(color) : null;
        row.push([
          {
            columns: [
              {
                width: 16,
                canvas: [
                  {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 10,
                    h: 10,
                    r: 10,
                    lineColor: '#d3d5db',
                    color: colorCode,
                    lineWidth: 1
                  }
                ]
              },
              color + ' / ' + size
            ]
          }
        ]);
      }
    }
    return colorTable;
  }

  generateHex(color) {
    const catalog =
      this.props.catalogs && this.props.catalogs[this.props.job.country];
    const colorsMap = catalog.colorsMap;
    let rgb = colorsMap[color]['rgb'];
    let rgbList = rgb.split(',');
    let r = parseInt(rgbList[0], 10);
    let g = parseInt(rgbList[1], 10);
    let b = parseInt(rgbList[2], 10);
    let hex;

    function componentToHex(c) {
      hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }
    // if there is an rgb value in colorsmap, convert to hex, else use color text
    return rgb
      ? '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
      : color.toLowerCase();
  }

  fetchConductorWeight(circuit, conductor) {
    const catalog =
      this.props.catalogs && this.props.catalogs[this.props.job.country];
    const length = parseFloat(circuit.length);
    const lengthInFt = this.props.job.metric
      ? convertUnits.fromMToFt(length)
      : length;
    const metal = conductor.isGroundConductor
      ? circuit.groundMetal
      : circuit.metal;

    let product = productFinder.getProduct(
      catalog,
      metal,
      circuit.insulation,
      conductor.size,
      conductor.color
    );

    return productFinder.getConductorWeight(product, lengthInFt);
  }

  groupSimilarConductors(circuits, fnc) {
    let circuitsArr = Object.values(circuits);
    let circuitColorsArr = [];

    circuitsArr.forEach(circuit => {
      let normalized = this.normalizePull(circuit);

      normalized.conductors.forEach(conductor => {
        const weight = this.fetchConductorWeight(normalized, conductor);
        const metal = conductor.isGroundConductor
          ? normalized.groundMetal
          : normalized.metal;

        circuitColorsArr.push({
          metal,
          insulation: normalized.insulation,
          length: normalized.length,
          color: conductor.color,
          size: conductor.size,
          conductorWeight: weight
        });
      });
    });

    let result = this.groupBy(circuitColorsArr, fnc);
    return result;
  }

  groupBy(array, fnc) {
    let groups = {};
    array.forEach(circuit => {
      let group = JSON.stringify(fnc(circuit));
      groups[group] = groups[group] || [];
      groups[group].push(circuit);
    });
    return Object.keys(groups).map(group => {
      return groups[group];
    });
  }

  simpullPrefix(insulation) {
    return insulation !== 'RWU' && insulation !== 'USE'
      ? ['SIM', { text: 'pull', italics: true }, '™']
      : '';
  }

  displayUnits(job, length, weight) {
    length = job.metric ? convertUnits.fromMToFt(length) : Math.round(length);
    weight = Math.floor(weight);
    return { length: length, weight: weight };
  }

  displayConductorsSummary(job) {
    let circuits = job.circuits;

    const renderConductorRows = circuits => {
      let results = this.groupSimilarConductors(circuits, circuit => {
        return [circuit.metal, circuit.color, circuit.insulation, circuit.size];
      });
      // rows array with header included
      let rows = [
        ['Metal', 'Insulation', 'Color', 'Size', 'Length (ft)', 'Weight (lb)']
      ];

      results.forEach(group => {
        let length = 0;
        let weight = 0;
        // simpull prefix
        let simpull = this.simpullPrefix(group[0].insulation);

        // generate hex
        let colorCode = group[0].color
          ? this.generateHex(group[0].color)
          : null;
        // sum length and weight;
        group.forEach(conductor => {
          length = conductor.length + length;
          weight = conductor.conductorWeight + weight;
        });
        const result = this.displayUnits(job, length, weight);

        // push new row
        rows.push([
          group[0].metal,
          { text: [...simpull, ' ', group[0].insulation] },
          {
            columns: [
              group[0].color,
              {
                canvas: [
                  {
                    type: 'rect',
                    x: 10,
                    y: 0,
                    w: 10,
                    h: 10,
                    r: 10,
                    lineColor: '#d3d5db',
                    color: colorCode,
                    lineWidth: 1
                  }
                ]
              }
            ]
          },
          group[0].size,
          result.length,
          result.weight
        ]);
      });

      return rows;
    };

    return [
      { text: 'CONDUCTORS SUMMARY (DETAILED)', style: 'grayText', bold: true },
      {
        width: 575,
        table: {
          widths: '*',
          heights: 30,
          body: renderConductorRows(circuits)
        },
        margin: [0, 10, 0, 12],
        layout: {
          fillColor: function(i, node) {
            return i === 0 ? '#e6e7e8' : null;
          },
          paddingLeft: function(i, node) {
            return 10;
          },
          paddingTop: function(i, node) {
            return 10;
          },
          hLineColor: function(i, node) {
            return '#929396';
          },
          vLineColor: function(i, node) {
            return '#929396';
          }
        }
      }
    ];
  }

  displayConductorsTotalSummary(job) {
    let circuits = job.circuits;

    const renderConductorRows = circuits => {
      let results = this.groupSimilarConductors(circuits, circuit => {
        return [circuit.metal, circuit.insulation, circuit.size];
      });
      // rows array with header included
      let rows = [
        ['Metal', 'Insulation', 'Size', 'Length (ft)', 'Weight (lb)']
      ];

      results.forEach(group => {
        let length = 0;
        let weight = 0;
        // simpull prefix
        let simpull = this.simpullPrefix(group[0].insulation);
        // sum length and weight;
        group.forEach(circuit => {
          length = circuit.length + length;
          weight = circuit.conductorWeight + weight;
        });
        const result = this.displayUnits(job, length, weight);
        // push new row
        rows.push([
          group[0].metal,
          { text: [...simpull, ' ', group[0].insulation] },
          group[0].size,
          result.length,
          result.weight
        ]);
      });
      return rows;
    };
    return [
      {
        text: 'CONDUCTORS SUMMARY (TOTALS ONLY)',
        style: 'grayText',
        bold: true
      },
      {
        width: 575,
        table: {
          widths: '*',
          heights: 30,
          body: renderConductorRows(circuits)
        },
        margin: [0, 10, 0, 0],
        layout: {
          fillColor: function(i, node) {
            return i === 0 ? '#e6e7e8' : null;
          },
          paddingLeft: function(i, node) {
            return 10;
          },
          paddingTop: function(i, node) {
            return 10;
          },
          hLineColor: function(i, node) {
            return '#929396';
          },
          vLineColor: function(i, node) {
            return '#929396';
          }
        }
      }
    ];
  }

  displayMetalsTotals(job) {
    let circuits = job.circuits;

    const renderConductorRows = circuits => {
      let results = this.groupSimilarConductors(circuits, circuit => {
        return [circuit.metal];
      });
      // rows array with header included
      let rows = [];

      results.forEach(group => {
        let length = 0;
        let weight = 0;

        // sum length and weight;
        group.forEach(circuit => {
          length = circuit.length + length;
          weight = circuit.conductorWeight + weight;
        });
        const result = this.displayUnits(job, length, weight);
        // push new row
        rows.push([group[0].metal, ' ', ' ', result.length, result.weight]);
      });
      return rows;
    };
    return [
      {
        width: 575,
        table: {
          widths: '*',
          heights: 30,
          body: renderConductorRows(circuits)
        },
        layout: {
          paddingLeft: function(i, node) {
            return 10;
          },
          paddingTop: function(i, node) {
            return 10;
          },
          hLineColor: function(i, node) {
            return '#929396';
          },
          vLineColor: function(i, node) {
            return '#929396';
          }
        }
      }
    ];
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType, passToNewRoute) => {
      dispatch(showToast(message, messageType, passToNewRoute));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    return [
      {
        collection: 'catalogs'
      }
    ];
  }),
  connect(
    ({ firebase: { auth, profile }, firestore: { data } }, props) => ({
      auth,
      profile,
      catalogs: data.catalogs
    }),
    mapDispatchToProps
  )
)(DownloadBOM);
