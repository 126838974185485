import React from 'react';
import { Link } from 'react-router-dom';

class Breadcrumbs extends React.Component {
  render() {
    return (
      <ul className={`breadcrumbs ${this.props.end ? ' terminated' : ''}`}>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        {this.props.children}
      </ul>
    );
  }
}

export default Breadcrumbs;
