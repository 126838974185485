import React from 'react';
import Button from '../patterns/Button';
import convertUnits from '../utils/convertUnits';
import { showToast } from '../actions/ToastActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const DEFAULT_API =
  'https://us-central1-southwire-configurator-dev.cloudfunctions.net/requestQuote';

/**
 * This is more like SubmitRFQ.
 * The final confirmation page when requesting a quote.
 *
 * Provide a list of email addresses that will be notified once
 * Southwire has the quote info.
 */
export class Viewers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailList: '',
      error: false,
      saving: false
    };
  }

  /**
   * Check if any of the provided emails are invalid (using a very simple regex expression).
   *
   * Regex is validating:
   *   - some characters before the @ sign
   *   - an @ sign is present
   *   - some characters after the @ sign
   *   - a period is present
   *   - some characters after the period
   *
   * @param  {String} emailList
   * @return {Boolean}
   */
  hasError = emailList => {
    const emails = emailList.split(',').filter(s => s);

    return emails.some(email => {
      return !/^.+@.+\..+$/.test(email);
    });
  };

  /**
   * onChange handler for the email list input field.
   *
   * @param {Object} event
   */
  onEmailListChange = event => {
    const emailList = event.target.value;
    const error = this.hasError(emailList);

    this.setState({
      error,
      emailList
    });
  };

  /**
   * Return a formatted quote object to send to Southwire.
   * Based on provided projectId/jobId.
   *
   * @return {Object}
   */
  formatQuote = () => {
    const { jobs, projects, profile, auth } = this.props;
    const { jobId, projectId } = this.props.match.params;

    const job = jobs[jobId];
    const project = projects[projectId];
    const { circuits } = job;

    const emailList = this.state.emailList.split(',').filter(s => s);

    const createdDate =
      new Date(job.dateCreated)
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '') + '000'; // could also do toLocaleString for local time

    const reels = job.reels.map((reel, index) => {
      return {
        number: index + 1,
        custom_name: reel.name,
        name: reel.reelProduct.name,
        circuits: reel.circuits.map((info, index) => {
          const circuit = circuits[info.id];

          return {
            number: `${reel.name} ${circuit.from}-${circuit.to}`,
            quantity: job.metric
              ? convertUnits.fromMToFt(circuit.length)
              : circuit.length,
            stack_position: index + 1,
            from_panel: circuit.from || 'X',
            to_panel: circuit.to || 'X',
            installHeads: circuit.simpull + '',
            wires: circuit.skus.map(stock_number => ({ stock_number }))
          };
        })
      };
    });

    return {
      circuitMeasure: 'US', //convert circuits to standard
      created: createdDate,
      creator: auth.email,
      market: job.country === 'us' ? 1 : 2,
      name: `${project.name} - ${job.name}`,
      owner: auth.email,
      quote_requested: true,
      reelMeasure: 'US', // reels to standard
      viewers: emailList,
      truck: false,
      order: { reels },
      user: {
        name: profile.name,
        email: auth.email,
        company: profile.company,
        phone_number: profile.phoneNumber,
        street_address: '',
        street_address_2: '',
        city: profile.city,
        region: profile.state,
        postal_code: profile.zip,
        country: profile.country === 'us' ? 'USA' : 'Canada'
      }
    };
  };

  /**
   * Format and submit the quote.
   */
  onSubmit = () => {
    const { jobId } = this.props.match.params;
    if (!jobId) {
      return;
    }

    const newQuoteObj = this.formatQuote();

    this.setState({ saving: true });

    const url = window.location.hostname === 'localhost' ? DEFAULT_API : '/rfq';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newQuoteObj),
      mode: 'cors'
    };

    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (!response.result) {
          throw new Error('RFQ error');
        }

        const updatedJob = {
          quoteRequested: new Date().getTime()
        };

        this.props.firestore
          .update(`jobs/${jobId}`, updatedJob)
          .then(() => {
            this.setState({ saving: false });
            this.props.showToast(
              'Your quote was sent. You will be contacted when pricing is available.',
              'success',
              true
            );
            this.props.history.goBack();
          })
          .catch(err => {
            this.setState({ saving: false });
          });
      })
      .catch(err => {
        this.setState({ saving: false });
        this.props.showToast('Error sending quote', 'error');
      });
  };

  render() {
    const { emailList, error, saving } = this.state;

    return (
      <div className="viewers">
        <div className="lb">
          <div className="viewers-container">
            <h2 className="page-title">Request a Quote</h2>
            <div className="instructions">
              Once submitted, your assigned Regional Southwire Account
              Management Team will receive the requested quote. You will be
              contacted shortly.
              <br />
              <br />
              To notify others that the quote has been requested, enter valid
              email addresses below, separated by commas.
            </div>

            <div className="email-area">
              <textarea
                value={emailList}
                onChange={this.onEmailListChange}
                placeholder="Enter Email Addresses (optional)"
                className={error ? 'error-border' : ''}
              />

              <div className="field-messages">
                <div
                  className={`field-alert delay-alert ${
                    error ? 'alerted' : ''
                  }`}
                >
                  Invalid email address
                </div>
              </div>

              <p className="textarea-label">
                All recipients will be visible in the notification email.
              </p>
            </div>
          </div>
        </div>

        <div className="button-container">
          <Button className="secondary" onClick={this.props.history.goBack}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="submit"
            id="submit-rfq-button"
            onClick={this.onSubmit}
            saving={saving}
            disabled={error || saving}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { projectId } = props.match.params;
  const { firebase, firestore } = state;
  const { auth, profile } = firebase;
  const { data } = firestore;

  return {
    auth,
    profile,
    projects: data.projects,
    jobs: data[`jobs-${projectId}`]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, messageType, passToNewRoute) => {
      dispatch(showToast(message, messageType, passToNewRoute));
    }
  };
};

export default compose(
  firestoreConnect((props, store) => {
    const { projectId } = props.match.params;
    const userId = store.firebase.auth().currentUser.uid;

    return [
      {
        collection: 'projects',
        where: ['userId', '==', userId]
      },
      {
        collection: 'jobs',
        storeAs: `jobs-${projectId}`,
        where: [['projectId', '==', projectId], ['userId', '==', userId]]
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Viewers);
