import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../patterns/Breadcrumbs';
import Button from '../../patterns/Button';
import JobSummaryHeader from '../../common/JobSummaryHeader';

import SimPull from '../../patterns/SimPull';
import DownloadBOM from './DownloadBOM';
import DownloadTemplate from './DownloadTemplate';
import UploadTemplate from './UploadTemplate';

export default class JobSummary extends React.Component {
  /**
   * Dispatch the delete job confirmation modal action.
   *
   * @param {Object} event
   */
  deleteJob = event => {
    const { projectId, jobId } = this.props.match.params;

    this.props.showDeleteJobModal({
      jobId,
      projectId,
      routeAfterDelete: () => {
        this.props.history.push(`/projects/${projectId}`, null);
      }
    });
  };

  /**
   * Determine if the user still has unassigned circuits in their job.
   *
   * @param  {Object} circuits
   * @param  {Array}  reels
   * @return {Boolean} - true if they DO have unassigned circuits
   */
  hasUnassignedCircuits(circuits, reels) {
    const numCircuitsOnReels = reels.reduce((result, reel) => {
      return result + (reel.circuits || []).length;
    }, 0);

    return Object.keys(circuits).length > numCircuitsOnReels;
  }

  /**
   * Validate that the job can be submitted. Display an error message if something isn't
   * configured correctly. Otherwise redirect to viewers for the final confirmation before RFQ.
   */
  submitJob = () => {
    const { profile } = this.props;
    const { projectId, jobId } = this.props.match.params;
    const job = this.props.jobs && jobId && this.props.jobs[jobId];
    const { reels, circuits } = job;

    const hasWrongRole = profile && profile.role === 'other';
    const hasNoReels = !(reels && reels.length);
    const hasSimpullReels = reels.some(r => r.simpull);
    const hasEmptyReels = reels.some(r => !r.circuits || !r.circuits.length);

    if (hasWrongRole) {
      this.props.showToast(
        'Your account role “other” is not authorized to request a quote.',
        'error-persist'
      );
      return;
    }

    if (hasNoReels) {
      this.props.showToast(
        'You don’t have any reels to submit a quote request.',
        'error-persist'
      );
      return;
    }

    if (this.hasUnassignedCircuits(circuits, reels)) {
      this.props.showToast(
        'Assign all circuits to a reel to submit a quote request.',
        'error-persist'
      );
      return;
    }

    if (hasSimpullReels) {
      this.props.showToast(
        [
          'Quotes with ',
          <SimPull key="simpull-err-1" />,
          ' reels must be submitted through your ',
          <SimPull key="simpull-err-2" />,
          ' Partner Distributor.'
        ],
        'error-persist'
      );
      return;
    }

    if (hasEmptyReels) {
      this.props.showToast(
        'Remove empty reels to request a quote.',
        'error-persist'
      );
      return;
    }

    this.props.history.push(`/projects/${projectId}/jobs/${jobId}/viewers`);
  };

  /**
   * Callback for when we have successfully parsed and formatted data from an uploaded template.
   *
   * @param {Object} circuits
   */
  onUploadSuccess = circuits => {
    const jobId = this.props.match.params.jobId || false;

    this.props.firestore
      .update(`jobs/${jobId}`, { circuits })
      .then(this.props.hideToast)
      .catch(error => {
        this.setState({ error: true });
        this.props.showToast(
          'The template could not be uploaded. Please contact Southwire.',
          'error-persist'
        );
      });
  };

  /**
   * Callback for when we have encountered an error during template upload/parsing/formatting.
   *
   * @param {String} message
   */
  onUploadError = message => {
    this.props.showToast(message, 'error-persist');
  };

  render() {
    const { projects, jobs, catalogs, location } = this.props;
    const { projectId, jobId } = this.props.match.params;

    const project = projects && projectId ? projects[projectId] : {};
    const job = jobs && jobId ? jobs[jobId] : {};
    const catalog = catalogs && catalogs[job.country];

    const showTemplate = job.circuits && Object.keys(job.circuits).length === 0;
    const showBOM = job.circuits && Object.keys(job.circuits).length > 0;
    const configurable = job && !job.quoteRequested;

    let displayQuoteDate;
    if (job.quoteRequested) {
      let a = new Date(job.quoteRequested);
      const month = a.getMonth() + 1;
      const date = a.getDate();
      const year = a
        .getFullYear()
        .toString()
        .slice(-2);
      displayQuoteDate = `${month}/${date}/${year}`;
    }

    // determine active tab
    const path = location && location.pathname;
    let tab;
    if (path.indexOf('feeder') > -1) {
      tab = 'feeder';
    } else if (path.indexOf('reels') > -1) {
      tab = 'reels';
    }

    return (
      <div className="project job-summary">
        <div className="action-heading">
          <Breadcrumbs type="projects">
            {project && (
              <li>
                <Link to={`/projects/${projectId}`}>{project.name}</Link>
              </li>
            )}
          </Breadcrumbs>
        </div>

        <JobSummaryHeader
          job={job}
          jobId={jobId}
          project={project}
          projectId={projectId}
          onDeleteJob={this.deleteJob}
          jobMetaActions={
            <div>
              {showBOM && <DownloadBOM job={job} project={project} />}
              {job.dateCreated && configurable && (
                <Button onClick={this.submitJob}>Request Quote</Button>
              )}
              {job.quoteRequested && (
                <div className="quote-submitted">
                  Quote Submitted {displayQuoteDate}
                </div>
              )}
            </div>
          }
        />

        <div className="section-toggle">
          <div className="container">
            <div className="toggle">
              <Link
                to={`/projects/${projectId}/jobs/${jobId}/summary/feeder`}
                className={tab === 'feeder' ? 'selected' : null}
              >
                <img src="/assets/img/icon-circuits.svg" alt="circuits icon" />
                <span>Feeder Schedule</span>
              </Link>
              <Link
                to={`/projects/${projectId}/jobs/${jobId}/summary/reels`}
                className={tab === 'reels' ? 'selected' : null}
              >
                <img src="/assets/img/icon-reels.svg" alt="reels icon" />
                <span>Reels</span>
              </Link>
            </div>
            <div className="section-links">
              {showTemplate && (
                <div className="template-links">
                  <DownloadTemplate country={job.country} />
                  <UploadTemplate
                    job={job}
                    catalog={catalog}
                    onSuccess={this.onUploadSuccess}
                    onError={this.onUploadError}
                  />
                </div>
              )}
              {job.dateCreated && configurable && (
                <div>
                  <Link
                    className="action"
                    to={`/projects/${projectId}/jobs/${jobId}/configure/circuit`}
                  >
                    Create Circuit
                  </Link>
                  <Link
                    className="action tertiary"
                    to={`/projects/${projectId}/jobs/${jobId}/configure`}
                  >
                    Configure Job
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
